<template>
    <my-block
            @close="$emit('close', true)"
            :top="70"
            :left="10"
            :width="1000"
            :height="400"
            title="ШУНО">

        <table class="table viewDevsTbl fixed-head">
            <thead>
            <tr>
                <td :style="`width: ${tblWidth.id}px`">ID</td>
                <td :style="`width: ${tblWidth.state}px`">Статус</td>
                <td :style="`width: ${tblWidth.errors}px`">Аварии</td>
                <td :style="`width: ${tblWidth.type}px`">Тип</td>
                <td :style="`width: ${tblWidth.fw}px`">Прошивка</td>
                <td :style="`width: ${tblWidth.coord}px`">Координаты</td>
                <td>Расписание</td>
            </tr>
            </thead>
        </table>
        <table class="table viewDevsTbl">
            <tbody>
            <template v-for="dev in devicesNodes">
                <list-item :tbl-width="tblWidth"
                           :dev="dev"
                           :is-node="true"></list-item>

                <list-item v-for="d in getParentDevs(dev.serial)"
                           :key="d.id"
                           :tbl-width="tblWidth"
                           :dev="d"
                           :is-node="false"></list-item>
            </template>
            </tbody>
        </table>
    </my-block>
</template>


<script>
import devicesApi from '../../../../../api/devices'
import {devTypes} from "../../../../../config";
import {filter, each} from "lodash";
import myBlock from '../../../../../components/responsiveBlock';
import listItem from './list-item'


export default {

    components: {
        myBlock,
        listItem,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            tblWidth: {
                id: 110,
                state: 40,
                errors: 50,
                dim: 95,
                angle: 40,
                temp: 80,
                fw: 80,
                type: 90,
                del: 40,
                coord: 150,
            },
            delDev: null,
            popupDel: false,
        }
    },

    computed: {
        devices() {
            return filter(this.$store.state.devices.all, {type: devTypes.shuno})
        },
        devicesNodes(){
            return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.shuno && !d.nodeSerial
            })
        }
    },

    methods: {
        getParentDevs(serial){
            let devs = [];
            each(this.devices, (dev)=>{
                if(dev.nodeSerial === serial) devs.push(dev)
            })
            return devs;
        },

        closeEvent() {
            this.$emit('close', true)
        },

        delDialog(dev) {
            this.delDev = dev;
            this.popupDel = true;
        },
        async delDevice() {
            if (this.delDev) {
                await devicesApi.delete(this.delDev.id)
                await this.$store.dispatch('devicesLoad', this.project.id)
                this.popupDel = false;
            }
        }
    }
}
</script>