<template>
    <my-block
            :width="widthResult"
            :height="400"
            :top="70"
            :left="10"
            title="Контроллеры"
            @close="$emit('close', true)"
    >

        <template slot="default">
            <div style="display: flex; height: 100%">

                <div style="overflow: auto">
                    <table class="table viewDevsTbl fixed-head">
                        <thead>
                        <tr>
                            <td :style="`width: ${tblWidth.id}px`">ID</td>
                            <td :style="`width: ${tblWidth.state}px`">Статус</td>
                            <td :style="`width: ${tblWidth.errors}px`">Аварии</td>
                            <td :style="`width: ${tblWidth.type}px`">Тип модуля</td>
                            <td>Координаты</td>
                            <td :style="`width: ${tblWidth.del}px`">&nbsp;</td>
                        </tr>
                        </thead>
                    </table>
                    <table class="table viewDevsTbl">
                        <tbody>
                        <template v-for="dev in devicesNodes">
                            <list-item :tbl-width="tblWidth"
                                       :dev="dev"
                                       @del-dialog="delDialog"
                                       :expanded="expanded[dev.id]"
                                       @expand="expanded[dev.id] = $event"
                                       :selected="selectedDev && selectedDev.id === dev.id"
                                       @select-dev="selectedDev = $event"
                            ></list-item>

                            <list-item v-for="d in dev.childNodes"
                                       :key="d.id"
                                       :tbl-width="tblWidth"
                                       :dev="d"
                                       :parent-node="dev"
                                       @del-dialog="delDialog"
                                       :selected="selectedDev && selectedDev.id === d.id"
                                       @select-dev="selectedDev = $event"
                            ></list-item>
                        </template>
                        </tbody>
                    </table>

                    <div class="popup-bg" v-if="popupDel">
                        <div class="block" style="width: 345px">
                            <div class="title">Вы точно хотите удалить устройство {{ delDev.serial }}?</div>
                            <div class="btns">
                                <button class="btn red" type="button" @click="delDevice()">Удалить</button>
                                <button class="btn green" @click="popupDel = false">Отмена</button>
                            </div>
                        </div>
                    </div>

                </div>


                <div v-if="selectedDev" style="overflow: auto; margin-left: 5px; border-left: 1px solid #525252; padding-left: 14px;">
                    <dev-data :dev="selectedDev" @close="selectedDev = null"></dev-data>
                </div>

            </div>
        </template>
    </my-block>
</template>

<script>
import myBlock from '../../../../../components/responsiveBlock.vue';
import devicesApi from '../../../../../api/devices'
import {filter, each} from "lodash";
import {devTypes} from "../../../../../config";
import listItem from './list-item.vue'
import devData from "./dev-data.vue"

export default {

    components: {
        myBlock,
        listItem,
        devData,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            width: 800,
            subDevWidth: 330,
            tblWidth: {
                id: 160,
                state: 40,
                errors: 50,
                dim: 95,
                angle: 40,
                temp: 80,
                fw: 80,
                type: 130,
                del: 30,
            },
            delDev: null,
            popupDel: false,
            expanded: {},
            selectedDev: null,
        }
    },

    computed: {
        devicesNodes(){
            return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.PLC && !d.nodeSerial
            })
        },

        widthResult(){
            return this.width + (this.selectedDev ? this.subDevWidth : 0);
        },
    },

    watch:{
        devicesNodes(v, o){
            this._setExpand();
        },
    },

    mounted() {
        this._setExpand();
    },

    methods: {
        delDialog(dev) {
            this.delDev = dev;
            this.popupDel = true;
        },

        async delDevice() {
            if (this.delDev) {
                await devicesApi.delete(this.delDev.id)
                await this.$store.dispatch('devicesLoad', this.project.id)
                this.popupDel = false;
            }
        },

        _setExpand(){
            each(this.devicesNodes, (d) =>{
                this.$set(this.expanded, d.id, true);
            })
        },

        resetWidth(e){
            this.width = e.width - (this.selectedDev ? this.subDevWidth : 0);
        },
    }
}
</script>