<template>
    <div style="position: absolute">

        <my-block class="schedules-block"
                  @close="$emit('close', true)"
                  :top="70"
                  :left="10"
                  :width="600"
                  :height="540"
                  title="Добавление расписания">

            <template slot="default">
                <div style="position: absolute; right: 16px;">
                    <img class="ico-btn-color"
                         @click="showCreateGeo = true"
                         src="/images/icons/tools-view-top-service.svg">
                </div>

                <div id="geo-mark" v-if="tabPeriod === 'geo'">ГЕО</div>

                <pe-input v-model="title"
                          :error="errors.title"
                          style="margin: 15px 0 20px 0"
                          placeholder="Введите название расписания"></pe-input>

                <add-analog-year
                        v-show="tabPeriod === 'year'"
                        :project="project"
                        :title="title"
                        @close="$emit('close', true)"
                        ref="addAnalogYear"></add-analog-year>

                <add-geo
                        v-show="tabPeriod === 'geo'"
                        :project="project"
                        :title="title"
                        @close="$emit('close', true)"
                        ref="addGeo"></add-geo>

            </template>

            <template slot="bottom-buttons">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <button  @click="closeEvent" class="btn red narrow" style=" width: 150px">Отмена</button>
                    <button  @click="addItem" class="btn green narrow" style="width: 150px">Сохранить</button>
                </div>
            </template>
        </my-block>

        <sunrise v-if="showCreateGeo"
                 @create-geo="createGeo"
                 @close="showCreateGeo = false"></sunrise>
    </div>
</template>

<style lang="scss">
@import "src/scss/vars";

#geo-mark{
  position: absolute;
  font-size: 13px;
  color: #c3c3c3;
}

.vs__dropdown-menu{
  height: 200px;
}

.tabs{
  display: flex;
  margin-top: -10px;
  .tab{
    text-align: center;
    padding: 10px 0;
    border-bottom: 2px solid #444444;
    flex-grow: 1;
    cursor: pointer;
    &:hover{
      color: $colorMain;
    }
    &.active{
      color: $colorMain;
      border-color: #B2C9E3;
    }
  }
}
</style>

<script>
    import addAnalogYear from './add/analog-year'
    import addGeo from './add/geo-year'
    import myBlock from '../../../../components/responsiveBlock';
    import peInput from "../../../../components/peInput";
    import Sunrise from "./sunrise";

    export default {

        props: {
            project: Object,
        },

        components:{
            Sunrise,
            addAnalogYear,
            addGeo,
            myBlock,
            peInput,
        },

        data(){
            return {
                tabPeriod: 'year', // 'year' | 'geo'
                title: '',

                errors:{
                    title: false,
                    days: false,
                },

                showCreateGeo: false,

            }
        },

        methods:{
            addItem(){
                if(!this.title){
                    this.errors.title = 'Заполните название расписания';
                    return;
                } else {
                    this.errors.title = false;
                }

                if(this.tabPeriod === 'year'){
                    this.$refs.addAnalogYear.addItem();
                } else {
                    this.$refs.addGeo.addItem();
                }
            },

            createGeo(data){
                this.tabPeriod = 'geo';
                this.$refs.addGeo.createGeo(data);
                this.showCreateGeo = false;
            },

            closeEvent(){
                this.$emit('close', true)
            },
        }
    }
</script>