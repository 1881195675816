<template>
    <div>
        <div class="common-info">
            <div class="info-row">
                <div class="label">Тип устройства:</div>
                <div class="val">{{dev.data.deviceType}}</div>
            </div>

            <div class="info-row">
                <div class="label">Серийный номер:</div>
                <div class="val">{{dev.data.serial}}</div>
            </div>

            <div class="info-row">
                <div class="label">Прошивка:</div>
                <div class="val">{{dev.data.firmwareVersion}} от {{fwDate}}</div>
            </div>
        </div>

        <div style="margin-right: 10px">
            <table class="table">
                <thead>
                <tr>
                    <td>Вход</td>
                    <td>Описание</td>
                    <td>Значение</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in 16">
                    <td>{{i}}</td>
                    <td>{{dev.data.analogEndpoints[i-1].state.description || '-'}}</td>
                    <td>
                        <span :title="getUpdateTime(i-1)">
                            {{dev.data.analogEndpoints[i-1].state.value / 1000}}В
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<style lang="scss">

.ctrl-rows{
  margin-top: 15px;
  .row{
    display: flex;
    align-items: center;
    margin: 10px 0;
    .label{
      width: 120px;
    }
  }
}

.no-border select {
  border: none;
}
</style>

<script>
import dayjs from 'dayjs';
import {each} from "lodash";

export default {
    props:{
        dev: Object,
    },

    data(){
        return {
            ctrlVals:{},
        }
    },

    methods:{

        getUpdateTime(i){
            if(this.dev.data && this.dev.data.analogEndpoints){
                return 'Значение получено в ' + dayjs(this.dev.data.analogEndpoints[i].state.timestamp).format('HH:mm:ss DD.MM.YYYY')
            }
            return '';
        },
    },

    computed:{
        fwDate(){
            if(this.dev.data && this.dev.data.firmwareDate){
                return dayjs(this.dev.data.firmwareDate).format('DD.MM.YYYY HH:mm:ss')
            }
            return '';
        },
    },
}
</script>