<template>
    <my-block
            :width="750"
            :height="400"
            :left="10"
            :top="70"
            title="Статус работы ШУНО"
            @close="closeEvent">

        <template v-slot:default>
            <ctrl-row v-for="d in devices" :dev="d" :key="d.id"></ctrl-row>
        </template>

    </my-block>
</template>

<script>
    import {each, filter, sortBy} from 'lodash'
    import myBlock from '../../../../components/responsiveBlock';
    import ctrlRow from './ctrl-row'
    import {devTypes, hwDevTypes} from "../../../../config";

    export default {

        props: {
            project: Object,
        },

        components:{
            myBlock,
            ctrlRow,
        },

        data(){
            return {

            }
        },

        computed:{
            devices(){
                return filter(this.$store.state.devices.all, {
                    type: devTypes.shuno,
                    data:{
                        deviceType: hwDevTypes.RO16,
                    }
                })
            },
        },

        methods:{
            closeEvent(){
                this.$emit('close', true)
            },
        }
    }
</script>