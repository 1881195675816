<template>
    <my-block
            @close="$emit('close', true)"
            :top="15"
            :right="20"
            :width="600"
            :height="300"
            title="Создание и редактирование расписаний"
            class="schedules-block">

        <template slot="default">
            <div style="margin-right: 10px">
                <schedule-row @show-edit="editEvent" v-for="s in schedules" :schedule="s" :key="s.id"></schedule-row>
            </div>
        </template>

        <template slot="header-icons">
            <img @click="addEvent" src="/images/icons/plus.svg" style="height: 24px; margin-right: 15px" class="ico-btn-color">
        </template>

    </my-block>
</template>

<script>
    import {mapState} from "vuex";
    import {sortBy, each} from 'lodash'
    import scheduleRow from "./schedule-row";
    import {weekDays} from "../../../../config";
    import myBlock from '../../../../components/responsiveBlock';


    export default {

        props: {
            project: Object,
        },

        components:{
            scheduleRow,
            myBlock,
        },

        data(){
            return {
                weekDays,
                expanded: {},
            }
        },

        computed: {
            // ...mapState({
            //     schedules: state => state.schedules.all,
            // }),
            schedules(){
                return sortBy(this.$store.state.schedules.all, 'title');
            },
        },

        methods:{
            closeEvent(){
                this.$emit('close', true)
            },

            editEvent(g){
                this.$emit('show-edit', g)
            },

            addEvent(){
                this.$emit('show-add')
            },
        }
    }
</script>