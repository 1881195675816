<template>
    <my-block
            :width="650"
            :height="600"
            :top="70"
            :left="10"
            @close="$emit('close', true)"
            title="Редактирование сценария">

        <template slot="default">
            <inner-form ref="scenarioEditInnerForm" :scenario-data="scenario"></inner-form>
        </template>

        <template slot="bottom-buttons">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <button  @click="$emit('close')" class="btn red narrow" style=" width: 150px">Отмена</button>
                <button  @click="updateItem" class="btn green narrow" style="width: 150px">Сохранить</button>
            </div>
        </template>

    </my-block>
</template>

<script>
import {each} from "lodash";
import myBlock from '../../../../components/responsiveBlock';
import api from '../../../../api/scenarios';
import innerForm from './inner-form';
import {percentToAnalog, dimToDali} from "../../../../tools";


export default {

    components: {
        myBlock,
        innerForm,
    },

    props: {
        scenario: Object,
        project: Object,
    },

    data() {
        return {
            errors:{
                name: null,
            },
        }
    },

    methods: {
        reformatMetricValue(m, v){
            if(m === 'dali/0/dim'){
                return  dimToDali(v);
            } else if (m === 'analog/dim'){
                return percentToAnalog(v);
            }
            return v;
        },

        async updateItem(){
            let scenarioData = this.$refs.scenarioEditInnerForm.getItem();
            let data = {
                id: this.scenario.id,
                name: scenarioData.name,
                description: scenarioData.description,
                actions: scenarioData.actions
            }
            each(data.actions, (action) => {
                action.duration = +action.duration;
                each(action.metrics, (m) => {
                    m.value = this.reformatMetricValue(m.name, m.value).toString()
                })
            })
            await api.update(this.scenario.id, data);
            await this.$store.dispatch('scenariosLoad', this.project.id);
            this.$emit('close');
        },

        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>