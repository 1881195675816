import Vue from 'vue'
import {
    SCENARIO_SET,
    SCENARIOS_SET,
    SCENARIO_UPDATE_PROP,
} from '../mutations'

import {each} from 'lodash'
import api from '../../api/scenarios'
import {metricOptions} from "../../config";

const state = {
    all: {},
}

// getters
const getters = {

}

// actions
const actions = {
    async scenariosLoad({commit, dispatch}, projectId){
        try {
            let data = await api.loadByProject(projectId);
            commit(SCENARIOS_SET, data);
        } catch (e) {
            console.error(e)
            if(e.response.status === 401){
                dispatch('logout')
            }
        }
    },
}

// mutations
const mutations = {
    [SCENARIOS_SET] (state, items) {
        state.all = {}
        each(items, s => {
            if(s.groups === undefined){
                s.groups = [];
            } else {
                let groups = [];
                each(s.groups, g=>{
                    groups.push(g.id)
                })
                s.groups = groups;
            }

            /*
            scenario: {
                name: '',
                description: '',
                actions: [
                    {
                        duration: 1,
                        position: 0,
                        metrics: [
                            {
                                position: 0,
                                name: "dali/0/dim",
                                value: "90",
                                devType: "DALI"
                            }
                        ]
                    },
                ],
            }
            */

            each(s.actions, (action) => {
                each(action.metrics, (metric) => {
                    let mo = metricOptions[metric.name];
                    switch (mo.type){
                        case 'bool': metric.value = metric.value === 'true'; break; //с сервака *всегда* приходят значения в строках
                        case 'number': metric.value = +metric.value; break;
                    }
                })
            })

            Vue.set(state.all, s.id, s)
        })
    },

    [SCENARIO_SET] (state, dev) {
        Vue.set(state.all, dev.id, dev)
    },

    [SCENARIO_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },
}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
