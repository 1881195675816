<template>
    <div>
        <div class="common-info">
            <div class="info-row">
                <div class="label">Тип устройства:</div>
                <div class="val">{{dev.data.deviceType}}</div>
            </div>

            <div class="info-row">
                <div class="label">Серийный номер:</div>
                <div class="val">{{dev.data.serial}}</div>
            </div>

            <div class="info-row">
                <div class="label">Прошивка:</div>
                <div class="val">{{dev.data.firmwareVersion}} от {{fwDate}}</div>
            </div>
        </div>

        <div class="ctrl-rows">
            <div class="row">
                <div class="label">
                    Скорость
                </div>
                <div class="ctrl-state">
                    {{dev.data.serialPort.baudrate.value}}
                </div>
            </div>

            <div class="row">
                <div class="label">
                    Биты данных
                </div>
                <div class="ctrl-state">
                    {{dev.data.serialPort.databits.value}}
                </div>
            </div>

            <div class="row">
                <div class="label">
                    Четность
                </div>
                <div class="ctrl-state">
                    {{dev.data.serialPort.parity.value}}
                </div>
            </div>

            <div class="row">
                <div class="label">
                    Стоп биты
                </div>
                <div class="ctrl-state">
                    {{dev.data.serialPort.stopbits.value}}
                </div>
            </div>


        </div>

    </div>
</template>

<style lang="scss">

.ctrl-rows{
  margin-top: 15px;
  .row{
    display: flex;
    align-items: center;
    margin: 10px 0;
    .label{
      width: 120px;
    }
  }
}

.no-border select {
  border: none;
}
</style>

<script>
import dayjs from 'dayjs';
import {each} from "lodash";

export default {
    props:{
        dev: Object,
    },

    data(){
        return {
            ctrlVals:{},
        }
    },

    methods:{

        getUpdateTime(i){
            if(this.dev.data && this.dev.data.analogEndpoints){
                return 'Значение получено в ' + dayjs(this.dev.data.analogEndpoints[i].state.timestamp).format('HH:mm:ss DD.MM.YYYY')
            }
            return '';
        },
    },

    computed:{
        fwDate(){
            if(this.dev.data && this.dev.data.firmwareDate){
                return dayjs(this.dev.data.firmwareDate).format('DD.MM.YYYY HH:mm:ss')
            }
            return '';
        },
    },
}
</script>