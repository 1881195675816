<template>
    <div class="center-content projects-list">
        <img id="plus-proj" @click="$to('projects-add')" src="/images/icons/plus.svg">
        <project-item v-for="p in projects" :project="p" :key="p.id"></project-item>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import projectItem from './components/project-item'
    import {each} from "lodash";

    export default {

        components:{
            projectItem
        },

        data(){
            return {

            }
        },

        computed: {
            ...mapState({
                projects: state => state.projects.all
            })
        },

        async mounted() {
            await this.$store.dispatch('projectsLoad');
        }
    }
</script>