import Vue from 'vue'
import {
    TIMEZONES_SET,
    PROJECTS_SET,
    PROJECT_UPDATE_PROP,
} from '../mutations'

import {each, forEach} from 'lodash'
import api from '../../api/projects'

const state = {
    all: {},
    timezones: {},
}

// getters
const getters = {

}

// actions
const actions = {
    async projectsLoad({commit, dispatch}){
        try {
            let data = await api.load();
            commit(PROJECTS_SET, data);
        } catch (e) {
            if(e.response.status === 401){
                dispatch('logout')
            }
        }
    },

    async timezonesLoad({commit}){
        let data = await api.loadTimezones();
        commit(TIMEZONES_SET, data);
    },

    needReloadProjectsStat({commit, state}){
        each(state.all, p => {
            commit(PROJECT_UPDATE_PROP, {id: p.id, prop: 'needReload', val: true});
        })
    }
}

// mutations
const mutations = {
    [PROJECTS_SET] (state, items) {
        state.all = {}
        forEach(items, d => {
            Vue.set(state.all, d.id, d)
        })
    },

    [TIMEZONES_SET] (state, items) {
        state.timezones = {}
        forEach(items, d => {
            // Vue.set(state.timezones, d.id, d.id + ' ' + d.name)
            state.timezones[d.id] = d.id + ' ' + d.name;
        })
    },

    [PROJECT_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
