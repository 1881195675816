import Vue from 'vue'
import {
    SCHEDULES_SET,
    SCHEDULES_USE_FROM_CRON,
    SCHEDULES_UPDATE_PROP,
    SCHEDULES_SET_ORIGINAL,
} from '../mutations'

import {each, filter, find} from 'lodash'
import api from '../../api/schedules'
import {scheduleTypes, schedulePeriodTypes} from "../../config";

const state = {
    useFrontCron: false,
    originalFormated: [],
    all: {
        1: {
            id: 1,
            title: 'Основные улицы. Будни.',
            periodType: 'weekly',
            days: [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            periods: [
                {
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    settings: {
                        dim: 15,
                        fade: 120,
                    }
                },
                {
                    startTime: {
                        hour: 14,
                        minute: 30,
                    },
                    settings: {
                        dim: 45,
                        fade: 30*60,
                    }
                },
                {
                    startTime: {
                        hour: 19,
                        minute: 45,
                    },
                    settings: {
                        dim: 90,
                        fade: 60,
                    }
                },
            ]
        },
    },
    timezones: {},
}

// getters
const getters = {

}

// actions
const actions = {
    async schedulesLoad({commit, dispatch}, projectId){
        try {
            let data = await api.loadByProject(projectId);
            commit(SCHEDULES_SET_ORIGINAL, data);
            let dataReady = [];
            each(data, (schedule)=>{
                /*
                "yearSettings": [{
                    "day": {
                      "day": 0,
                      "month": 0
                    },
                    "startTime": {
                      "hour": 0,
                      "minute": 0
                    },
                    "settings": [
                      {
                        "name": "string",
                        "value": 0
                      }
                    ]
                 }]*/
                if(schedule.yearSettings !== undefined){
                    let rd = {
                        id: schedule.id,
                        periodType: schedulePeriodTypes.year,
                        type: schedule.type,
                        title: schedule.title,
                        latitude: schedule.latitude,
                        longitude: schedule.longitude,
                        shift: schedule.shift,
                        periods: {},
                    };
                    each(schedule.yearSettings, (p) => {
                        if(rd.periods[p.day.month] === undefined) rd.periods[p.day.month] = {};
                        if(rd.periods[p.day.month][p.day.day] === undefined) rd.periods[p.day.month][p.day.day] = [];
                        /*let se = {};
                        each(p.settings, (ss) => {
                            se[ss.name] = ss.value;
                        })*/
                        rd.periods[p.day.month][p.day.day].push({
                            startTime: p.startTime,
                            // settings: se,
                            scenarioId: p.scenarioId,
                        })
                    })
                    dataReady.push(rd);
                }
                else {
                    let rd = {
                        id: schedule.id,
                        periodType: schedulePeriodTypes.week,
                        type: schedule.type,
                        title: schedule.title,
                        days: [],
                        periods: [],
                    };
                    each(schedule.settings, (s) =>{
                        if(!rd.days.includes(s.day)){
                            rd.days.push(s.day)
                        }
                    })
                    if(rd.days.length){
                        let day = rd.days[0]; // т.к. у нас все периоды одни и те же для всех дней
                        each(schedule.settings, (s) =>{
                            if(s.day === day){
                                let se = {};
                                each(s.settings, (ss) => {
                                    se[ss.name] = ss.value;
                                })
                                rd.periods.push({
                                    startTime: s.startTime,
                                    settings: se,
                                })
                            }
                        })
                    }
                    dataReady.push(rd);
                }
            })

            commit(SCHEDULES_SET, dataReady);
        } catch (e) {
            console.error(e)
            if(e.response.status === 401){
                dispatch('logout')
            }
        }
    },
}

// mutations
const mutations = {
    [SCHEDULES_SET] (state, items) {
        state.all = {}
        each(items, d => {
            Vue.set(state.all, d.id, d)
        })
    },

    [SCHEDULES_SET_ORIGINAL] (state, items) {
        state.originalFormated = items
    },

    [SCHEDULES_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

    [SCHEDULES_USE_FROM_CRON] (state, val) {
        state.useFrontCron = val;
    },

}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
