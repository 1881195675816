<template>
    <div class="center-content">

        <div class="block">
            <h3>У вас нет Инфраструктурных Проектов</h3>
            <div class="help-text">Вы можете их добавить нажав на кнопку&nbsp;&nbsp;“+”</div>
        </div>

        <img id="plus-proj" @click="$to('projects-add')" src="/images/icons/plus.svg">

    </div>
</template>

<style lang="scss" scoped>

    .block{
        width: 470px;
        margin: 80px auto;
    }


    h3{
        margin-bottom: 40px;
    }

    .help-text{
        font-size: 16px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.7);
        text-align: center;
    }

</style>

<script>
    export default {

    }
</script>