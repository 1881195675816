<template>
    <vue-resizable
            :width="width"
            :height="height"
            :max-height="6000"
            :min-height="200"
            :left="left"
            :right="right"
            :top="top"
            @resize:end="$emit('resize', $event)"
            :disable-drag="disableDrag"
            :disable-resize="disableResize"
            :dragSelector="`.header.uid${uid}`">
        <div class="block movable">
            <div :class="`header uid${uid}`">
                <div class="block-title">{{title}}</div>
                <slot name="header-icons"></slot>
                <img @click="$emit('close', true)"
                     v-if="!noClose"
                     src="/images/icons/close-a.svg" class="ico-btn-color close">
            </div>

            <div style="overflow: auto; flex-grow: 1; margin: 15px 0;">
                <slot name="default"></slot>
            </div>

            <slot name="bottom-buttons"></slot>

            <slot name="dialogs"></slot>
        </div>
    </vue-resizable>
</template>

<style lang="scss" scoped>
.block{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}
.resizable-component {
  position: absolute;
  z-index: 100;
}
</style>


<script>
// import VueResizable from 'vue-resizable'
import VueResizable from '../components/vue-resizable'

export default {

    components:{
        VueResizable,
    },

    props: {
        title: String,
        width: Number,
        height: Number,
        left: Number,
        right: Number,
        top: Number,
        noClose: Boolean,

        disableResize:{
            default: false,
            type: Boolean
        },
        disableDrag: {
            default: false,
            type: Boolean,
        },
    },

    data(){
        return {
            uid: 0,
        }
    },

    created() {
        this.uid = Math.round(Math.random()*1000000000);
    }
}
</script>