<template>
    <div class="schedule-row" @click="expanded = !expanded" :class="{expanded: expanded}">
        <div class="title">
            {{ schedule.title }}
        </div>

        <template v-if="schedule.periodType === 'week'">
            <div class="days">{{ getDays() }}</div>
            <div class="chart">
                <time-chart :periods="schedule.periods"></time-chart>
                <div v-if="expanded">
                    <div v-for="(p,i) in sortPeriods(schedule.periods)" class="time-dim">
                        <div style="width: 110px">
                            {{ formatNum(p.startTime.hour) }}:{{ formatNum(p.startTime.minute) }}
                            - {{ formatNum(nextPeriod(i).hour) }}:{{ formatNum(nextPeriod(i).minute) }}
                        </div>
                        <div>{{ p.settings.dim }}%</div>
                    </div>
                </div>
            </div>
            <div class="arrow"><img src="/images/icons/arrow-down.svg"></div>
        </template>

        <template v-else>
            <div class="days"> годовое </div>
            <div class="chart"> </div>
        </template>

        <img @click.stop="editEvent()" src="/images/icons/edit-a.svg" class="ico-btn-color">
    </div>
</template>


<script>
import {sortBy, each} from 'lodash'
import timeChart from './time-chart'
import {weekDays} from "../../../../config";


export default {

    props: {
        schedule: Object,
    },

    components: {
        timeChart,
    },

    data() {
        return {
            expanded: false,
        }
    },

    methods: {
        sortPeriods() {
            return sortBy(this.schedule.periods, p => p.startTime.hour * 60 + p.startTime.minute)
        },

        nextPeriod(pIndex) {
            let periods = this.sortPeriods()
            return pIndex < periods.length - 1 ? periods[pIndex + 1].startTime : {hour: 23, minute: 59};
        },

        formatNum(n) {
            return n < 10 ? '0' + n.toString() : n.toString()
        },

        getDays() {
            let arr = [];
            each(this.schedule.days, (d) => {
                arr.push(weekDays[d])
            })
            return arr.join(', ');
        },

        editEvent() {
            this.$emit('show-edit', this.schedule)
        },
    }
}
</script>