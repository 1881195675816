<template>
    <tr>
        <td :style="`width: ${tblWidth.id}px`">
            <span v-if="!isNode">----</span>
            {{ dev.serial }}
        </td>
        <td :style="`width: ${tblWidth.state}px`">
            <div :class="onlineClass"></div>
        </td>
        <td :style="`width: ${tblWidth.errors}px`">Нет</td>
        <td :style="`width: ${tblWidth.dim}px`">
            {{dim}}
        </td>
<!--        <td :style="`width: ${tblWidth.angle}px`">10°</td>-->
        <td :style="`width: ${tblWidth.lux}px`">
            <template v-if="isNode && dev.data && dev.data.sensors && dev.data.sensors.luminosity">
                {{dev.data.sensors.luminosity.value}}lx
            </template>
            <template v-else>&mdash;</template>
        </td>
        <td :style="`width: ${tblWidth.temp}px`">
            <template v-if="isNode && dev.data && dev.data.sensors && dev.data.sensors.temperature">
                {{dev.data.sensors.temperature.value}}°С
            </template>
            <template v-else>&mdash;</template>
        </td>
        <td :style="`width: ${tblWidth.fw}px`">
            {{ dev.data && dev.data.firmwareVersion }}
        </td>
        <td :style="`width: ${tblWidth.type}px`">
            <span v-if="isNode">Нода</span>
            <span v-else>
                {{dev.data && dev.data.deviceType}}
                <template v-if="!isNode && dev.data && dev.data.driverType">
                    - {{dev.data.driverType}}
                </template>
            </span>
        </td>
        <td :style="`width: ${tblWidth.coordinates}px`">
            <template v-if="isNode">
                {{ dev.latitude && dev.latitude.toFixed(5) }},
                {{ dev.longitude && dev.longitude.toFixed(5) }}
            </template>
        </td>
        <td>
            <template v-if="isNode">
                <div v-for="g in groups">{{g.name}}</div>
            </template>
        </td>
    </tr>
</template>

<script>
import {driverTypes} from "../../../../config";
import {daliToDim, analogToPercent} from "../../../../tools";
import { each } from 'lodash';

export default {
    props:{
        tblWidth: Object,
        dev: Object,
        isNode: Boolean,
    },

    computed: {
        onlineClass(){
            return this.dev.state.toLowerCase() === 'online' ? 'online' : 'offline';
        },

        dim(){
            if(this.isNode ||
                !this.dev.data ||
                !this.dev.data.driverType ||
                this.dev.data.driverType === driverTypes.unknown){
                return '—';
            } else {
                let v = 0;

                if(this.dev.data.load !== undefined && this.dev.data.load.value !== true){
                    return 'выкл';
                }

                if(this.dev.data.driverType === driverTypes.dali){
                    if(this.dev.data.daliDriver === undefined) return '—';
                    v = daliToDim(this.dev.data.daliDriver.dim.value);
                } else if(this.dev.data.driverType === driverTypes.analog) {
                    if(this.dev.data.analogDriver === undefined) return '—';
                    v = analogToPercent(this.dev.data.analogDriver.dim.value);
                }

                return v > 0 ? v + '%' : 'выкл';
            }
        },

        groups(){
            let arr = [];
            each(this.dev.groups, gId => {
                if(this.$store.state.groups.all[gId]){
                    arr.push(this.$store.state.groups.all[gId])
                }
            })
            return arr;
        }
    }
}
</script>