<template>
    <div class="group-row" :class="{disabled: isDisabled}">
        <div class="color" :style="`background: ${group.color}`"></div>
        <div class="title" @click="showDevs()">{{ group.name }}</div>

        <div class="unsync" v-if="groupState === 'unsync'" title="В группе различные значения управления у устройств">
            <img src="/images/icons/unsync.svg">
        </div>

        <div class="unsync" v-if="groupState === 'empty'" title="В группе нет устройств">
            <img src="/images/icons/unsync.svg">
        </div>

        <div class="unsync" v-if="groupState === 'offline'" title="В группе все устройства оффлайн">
            <img src="/images/icons/unsync.svg">
        </div>

        <div class="unsync" v-if="groupState === 'good'"></div>

        <div class="power-switch" :class="{disabled: isDisabled}">
            <div class="sw" :class="{active: !isLoadUnsync && !isLoadDisabled && getLoad}" @click="groupCtrlLoad( true)">Вкл</div>
            <div class="sw" :class="{active: !isLoadUnsync && !isLoadDisabled && !getLoad}" @click="groupCtrlLoad( false)">Выкл</div>
        </div>

        <div class="slider">
            <vue-slider v-model="dim"
                        v-bind="sliderOptions"
                        @drag-end="dimGroup(dim)"
                        :disabled="isDisabled || !getLoad"
            ></vue-slider>
        </div>

        <div style="margin: 0 15px; width: 40px;">
            {{ dim }}%
        </div>

        <div style="min-width: 180px">
            <pe-select :value="group.scheduleId"
                       @input="setSchedule($event, group)"
                       :options="schedules"
                       class="h32"
                       style="margin: 0"
                       :disabled="isDisabled"
                       placeholder="Расписание"></pe-select>
        </div>

        <template v-if="group.scheduleId">
            <div class="unsync" v-if="scheduleToggle === null" style="margin-left: 12px"
                 title="В группе различные значения настроек расписания у устройств">
                <img src="/images/icons/unsync.svg">
            </div>
            <div v-else style="width: 28px"></div>

            <div>
                <div class="power-switch" :class="{disabled: isDisabled}" style="margin-right: 0">
                    <div class="sw" :class="{active: scheduleToggle !== null && !isDisabled && scheduleToggle}" @click="toggleSchedule( true)">Вкл</div>
                    <div class="sw" :class="{active: scheduleToggle !== null && !isDisabled && !scheduleToggle}" @click="toggleSchedule( false)">Выкл</div>
                </div>
            </div>
        </template>
        <div v-else style="width: 148px"></div>

    </div>
</template>

<style lang="scss" scoped>
.group-row {
  margin-right: 10px;

  .slider {
    width: 80px;
    margin: 0 8px;
  }

  .unsync {
    width: 16px;
  }

  .power-switch.disabled .sw{

  }
}
</style>

<script>
import {drawDevicesColorGroup} from "../../../../other";
import {devStates, driverTypes} from "../../../../config";
import vueSlider from 'vue-slider-component'
import {each, filter, sortBy} from "lodash";
import peSelect from "../../../../components/peSelect";
import {GROUP_UPDATE_PROP} from "../../../../store/mutations";
import groupsApi from "../../../../api/groups";
import devicesApi from "../../../../api/devices";
import {mapActions} from 'vuex';
import {dimToDali, daliToDim, percentToAnalog, analogToPercent} from "../../../../tools";
import switcher from "../../../../components/switch.vue"

export default {

    props: {
        project: Object,
        groupType: String,
        group: Object,
        devices: Array,
        schedules: Object,
    },

    components: {
        switcher,
        vueSlider,
        peSelect,
    },

    data() {
        return {
            sliderOptions: {
                dotSize: 18,
                height: 6,
                min: 0,
                max: 100,
                step: 5,
                tooltip: 'none',
            },
            dim: 1,
            groupsReloadTimeout: false,
        }
    },

    mounted() {
        this.dim = this.getDim;
    },

    computed: {
        getDim() {
            let v;
            each(this.group.devices, (d) => {
                if (d.data && d.data.deviceType === 'NEMA') {
                    let dim = this._getDevDim(this.$store.state.devices.all[d.id]);
                    if(dim === null) return;
                    if (v === undefined) {
                        v = dim;
                    } else if (Math.abs(v - dim) > 2) { // если диммирования разные более чем на 2% у девайсов просто поставим 50%
                        v = 50;
                    }
                }
            })
            if(v === undefined) v = 50; // просто чтобы не было глюков
            v = Math.round(v);
            return v;
        },

        getLoad() {
            let v;
            each(this.group.devices, (d) => {
                if (d.data && d.data.deviceType === 'NEMA') {
                    let load = this.$store.state.devices.all[d.id].data.load.value;
                    if(load === null || load === undefined) return;
                    if (v === undefined) {
                        v = load;
                    }
                }
            })
            if(v === undefined) v = false; // просто чтобы не было глюков
            return v;
        },

        isUnsync(){
            let v = false;
            let dimVal = -1;
            each(this.group.devices, (d) => {
                if (d.data && d.data.deviceType === 'NEMA') {
                    let dim = this._getDevDim(this.$store.state.devices.all[d.id]);
                    if(dim === null) return;
                    if(dimVal === -1) dimVal = dim;
                    if(dimVal !== dim) v = true; // ибо хз!
                }
            })
            return v;
        },

        isLoadUnsync(){
            let v = false;
            let loadVal;
            each(this.group.devices, (d) => {
                if (d.data && d.data.deviceType === 'NEMA') {
                    let load = this.$store.state.devices.all[d.id].data.load.value;
                    if(load === null || load === undefined) return;
                    if(loadVal === undefined) loadVal = load;
                    if(loadVal !== load) v = true; // разные значения. неоптимально, но пока некритично.
                }
            })
            return v;
        },

        scheduleToggle() {
            let v = false;
            let state;
            each(this.group.devices, (d) => {
                d = this.$store.state.devices.all[d.id];
                if (d && d.data && d.data.scheduler && d.data.scheduler.state !== undefined) {
                    if (state === undefined) {
                        state = d.data.scheduler.state.value;
                    } else {
                        v = state !== d.data.scheduler.state.value;
                    }
                }
            })

            return v ? null : state === 'on';
        },

        hasDevices(){
            return this.group.devices && this.group.devices.length > 0;
        },

        hasOnlineDevices(){
            let ret = false;
            each(this.group.devices, (d) => {
                if (d.state === devStates.online) {
                    ret = true;
                    return false;
                }
            })
            return ret;
        },

        isLoadDisabled(){
            return !this.hasDevices || !this.hasOnlineDevices;
        },

        isDisabled(){
            return !this.hasDevices || !this.hasOnlineDevices;
        },

        groupState(){
            if(!this.hasDevices) return 'empty';
            if(!this.hasOnlineDevices) return 'offline';
            if(this.isUnsync || this.isLoadUnsync) return 'unsync';
            return 'good';
        },
    },

    watch: {
        getDim(v, o) {
            this.dim = v;
        }
    },

    methods: {
        ...mapActions([
            'deviceModuleSetDimVal',
            'deviceModuleSetLoadVal',
            'deviceNodeSetSchedulerState',
        ]),

        /*setSchedule(scheduleId) {
            scheduleId = parseInt(scheduleId)
            this.$store.commit(GROUP_UPDATE_PROP, {
                id: this.group.id,
                prop: 'scheduleId',
                val: scheduleId
            })

            if(scheduleId === 0) scheduleId = null;
            groupsApi.update(this.group.id, {
                id: this.group.id,
                scheduleId: scheduleId,
            })
        },*/

        setSchedule(scheduleId) {
            scheduleId = parseInt(scheduleId)
            this.$store.commit(GROUP_UPDATE_PROP, {
                id: this.group.id,
                prop: 'scheduleId',
                val: scheduleId
            })

            groupsApi.setSchedule(this.group.id, scheduleId);
        },

        toggleSchedule(v){
            groupsApi.toggleSchedule(this.group.id, v);
            this.group.devices.forEach((dev) => {
                this.deviceNodeSetSchedulerState({id: dev.id, val: v});
            })
            this.$forceUpdate();
        },

        _getDevDim(dev) {
            if(!dev || !dev.data) return null;
            if (dev.data.driverType === driverTypes.dali) {
                if(dev.data.daliDriver === undefined) return null;
                return daliToDim(dev.data.daliDriver.dim.value);
            } else { // считаем, что все остальные 0-10
                if(dev.data.analogDriver === undefined) return null;
                // return dev.data.analogDriver.dim.value;
                return analogToPercent(dev.data.analogDriver.dim.value);
            }
        },

        _getDevDimMetric(dev) {
            if (dev.data.driverType === driverTypes.dali) {
                return 'dali/0/dim';
            } else { // считаем, что все остальные 0-10
                return 'analog/dim';
            }
        },

        showDevs() {
            let ids = []
            each(this.group.devices, (d) => {
                ids.push(d.id)
            })
            drawDevicesColorGroup(this.$map, this.devices, this.group.color, ids, true)
        },

        _groupCtrl(dim) {
            this.group.devices.forEach((dev) => {
                if (dev.data.deviceType === 'NEMA') {
                    let metrics = [];
                    let dimVal = dim;
                    if(dev.data.driverType === driverTypes.dali){
                        metrics.push({
                            metric: 'dali/0/fade/time',
                            val: 0,
                        })
                        dimVal = dimToDali(dim);
                    }
                    metrics.push({
                        metric: this._getDevDimMetric(dev),
                        val: dimVal,
                    });
                    devicesApi.ctrlMulti(dev.id, metrics);
                    this.deviceModuleSetDimVal({id: dev.id, val: dimVal});
                }
            })
            this.$forceUpdate();
            this.$emit('ctrl-done');
        },

        async _groupCtrlReal(dim) {
            let metrics = [
                {
                    metric: 'dali/0/fade/time',
                    val: 0,
                },
                {
                    metric: 'dali/0/dim',
                    val: dimToDali(dim),
                },
                {
                    metric: 'analog/dim',
                    val: percentToAnalog(dim),
                },
                /*{
                    metric: 'load',
                    val: dim > 0,
                },*/
            ];

            if(dim > 0){
                metrics.push({
                    metric: 'load',
                    val: dim > 0,
                })
            }

            await groupsApi.ctrlMulti(this.group.id, metrics);

            this.group.devices.forEach((dev) => {
                if (dev.data.deviceType === 'NEMA') {
                    let dimVal = dim;
                    if (dev.data.driverType === driverTypes.dali) {
                        dimVal = dimToDali(dim);
                    }
                    else if (dev.data.driverType === driverTypes.analog) {
                        dimVal = percentToAnalog(dim);
                    }
                    this.deviceModuleSetDimVal({id: dev.id, val: dimVal});
                }
            })

            this.$forceUpdate();
            this.$emit('ctrl-done');
        },

        async groupCtrlLoad(onOff) {
            let metrics = [
                {
                    metric: 'load',
                    val: !!onOff,
                },
            ];
            await groupsApi.ctrlMulti(this.group.id, metrics);
            this.group.devices.forEach((dev) => {
                if (dev.data.deviceType === 'NEMA') {
                    this.deviceModuleSetLoadVal({id: dev.id, val: onOff});
                }
            })
            this.$forceUpdate();
            this.$emit('ctrl-done');
        },

        /*powerToggle(onOff) {
            if(this.isDisabled) return;
            this.dim = onOff ? 100 : 0;
            // this._groupCtrl(onOff ? 100 : 0);
            this._groupCtrlReal(onOff ? 100 : 0);
        },*/

        dimGroup(val) {
            if (val > 100) val = 100;
            if (val < 1) val = 1;
            // this._groupCtrl(val);
            this._groupCtrlReal(val);
        },

        closeEvent() {
            this.$emit('close', true)
        },

        editGroupEvent(g) {
            this.$emit('show-edit', g)
        },

        addGroupEvent() {
            this.$emit('show-add')
        },


    }
}
</script>