<template>
    <div :style="`position: relative; width: ${size + 8}px; margin: auto;`">
        <div class="subs" :style="`width: ${totalSize}px; height: ${totalSize}px;`">
            <div class="count">{{devCount}}</div>
            <div class="descr">{{ devCountLang }}</div>
        </div>
        <canvas :width="totalSize" :height="totalSize"></canvas>
    </div>
</template>

<style lang="scss" scoped>
    .subs {
        text-align: center;
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .count {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #E8E8E8;
        }

        .descr {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
        }
    }
</style>

<script>
import {plural} from "../../other";

    export default {
        props: {
            size: Number,
            devDataOnline: Number,
            devDataOffline: Number,
            devDataError: Number,
        },

        data() {
            return {
                lineWidth: 4,
            }
        },

        watch: {
            devDataOnline() {
                this.redraw()
            },
            devDataOffline() {
                this.redraw()
            },
            devDataError() {
                this.redraw()
            },
        },

        methods: {
            redraw() {
                let ctx = this.$el.querySelector('canvas').getContext('2d')
                let lw = this.lineWidth
                let r = this.size / 2
                ctx.lineWidth = lw
                let colors = ['#A1E7B3', '#444444', '#E58080']
                let p = 0;
                let s = this.devCount
                ctx.clearRect(0, 0, this.totalSize, this.totalSize)
                this.devData.forEach((v, k) => {
                    let a = v / s + p;
                    let d = Math.PI * 90 / 180
                    ctx.beginPath();
                    ctx.arc(r + lw, r + lw, r, p * Math.PI * 2 - d, a * Math.PI * 2 - d, false);
                    ctx.strokeStyle = colors[k];
                    ctx.stroke();
                    p = a;
                })
            },
        },

        computed: {
            totalSize() {
                return this.lineWidth * 2 + (+this.size || 60)
            },
            devData() {
                return [
                    this.devDataOnline,
                    this.devDataOffline,
                    this.devDataError,
                ]
            },
            devCount() {
                // console.log(this.devData)
                return this.devData.reduce((sum, v) => sum + v)
            },
            devCountLang(){
                return plural(this.devCount, 'Устройство', 'Устройства', 'Устройств')
            }
        },

        mounted() {
            this.redraw()
        }
    }
</script>