const useVisualDim = true;
const maxDim = 254;

export function dimToDali(d, round = true) {
    if(d < 1) return 0;
    let v = 1 + 253.0 / 3.0 * (Math.log(d) / Math.log(10) + 1);
    return round ? Math.round(v) : v;
}

export function daliToDim(d, round = true) {
    let v = Math.pow(10, (d - 1) / (253.0 / 3.0) - 1);
    return round ? Math.round(v) : v;
}

function dimToPercent(v) {
    let d = Math.round(v / 254 * 100);
    return d < 1 && v > 0 ? 1 : d; //чтоб не было 0% при 1 значении, чтобы не выглядело тупо
}

function percentToDim(d) {
    return Math.round(d / 100 * maxDim);
}

export function analogToPercent(v){
    return Math.round(v *.1);
}
export function percentToAnalog(v){
    let a = Math.round(v * 10);
    if(a < 0) a = 0;
    if(a > 999) a = 999;
    return a;
}

//из значения диммирования в дали единицы. визуально адекватное диммирование - приходит 1..255, уходит 1..255
const offsetDim = 14;

function visualDimToDali(v) {
    v = parseInt(v) || 0;
    if (v > maxDim) v = maxDim;
    if (useVisualDim) {
        let offset = offsetDim;
        if (v < percentToDim(offset)) { // если менее значения, то линейное
            v = dimToDali(dimToPers(v));
        } else {
            let minDim = dimToDali(offset);
            offset = percentToDim(offset);
            v = maxDim * (v - offset) / (maxDim - offset);
            v = minDim + (maxDim - minDim) * v / maxDim;
        }
    }
    return Math.round(v);
}

//из дали единиц в диммирование. обратное преобразование от dimToVisual. Приходит дали единицы 1..255, уходит 1..255
function daliDimToVisual(v, convertToPercent = false) {
    v = parseInt(v) || 0;
    if (v > maxDim) v = maxDim;
    if (useVisualDim) {
        let offset = offsetDim;
        if (v < dimToDali(offset, 1)) {
            v = daliToDim(v, 1);
            v = percentToDim(v);
            if (v <= 0) v = 1;
        } else {
            let minDim = dimToDali(offset, 1);
            offset = percentToDim(offset);
            v = (v - minDim) * maxDim / (maxDim - minDim);
            v = v * (maxDim - offset) / maxDim + offset;
        }
    }
    if (convertToPercent) v = dimToPercent(v);
    return Math.round(v);
}
