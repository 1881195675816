<template>
    <div class="pe-input pe-select" :class="{'error': error, 'success': success}">
        <div v-if="value === ''" class="placeholder">{{placeholder}}</div>
        <select :value="value" @input="change($event)" :tabindex="tabIndex" :disabled="disabled">
            <template v-if="Array.isArray(options)">
                <option v-for="v in options" :value="v">{{v}}</option>
            </template>
            <template v-else>
                <option v-for="(v, k) in options" :value="k">{{v}}</option>
            </template>
        </select>
        <div class="ico-success"><img src="/images/icons/ok.svg"></div>
        <div class="ico-error"><img src="/images/icons/cross.svg"></div>
        <div class="err-hint" v-if="error">{{error}}</div>
    </div>
</template>


<script>
    export default {
        props: ['value', 'options', 'placeholder', 'error', 'success', 'tabIndex', 'disabled'],

        data() {
            return {
                showPwd: false,
            }
        },

        computed:{
            optionList(){
                let obj = {}
                if(Array.isArray(this.options)){
                    this.options.forEach((el) => {
                        obj[el] = el
                    })
                } else {
                    let obj = {}
                    for(let i in this.options){
                        if(this.options.hasOwnProperty(i)){
                            obj[i] = this.options[i]
                        }
                    }
                }
                return obj
            }
        },

        methods: {
            change(e) {
                this.$emit('input', e.target.value)
            }
        }
    }
</script>
