import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'scenarios'

api.loadByProject = async (projectId, pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}scenarios/project/` + projectId,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}

api.addToProject = async (projectId, data) => {
    let response = await axios.post(`${api.baseUrl}scenarios/` + projectId, data)
    return response.data;
}

api.addGroup = async (scenarioId, groupId) => {
    let response = await axios.post(`${api.baseUrl}scenarios/${scenarioId}/${groupId}`)
    return response.data;
}

api.removeGroup = async (scenarioId, groupId) => {
    let response = await axios.delete(`${api.baseUrl}scenarios/${scenarioId}/${groupId}`)
    return response.data;
}


export default api