import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'schedules'

api.loadByProject = async (projectId, pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}${api.resource}/project/` + projectId,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}

api.addToProject = async (projectId, data) => {
    let response = await axios.post(`${api.baseUrl}${api.resource}/` + projectId, data)
    return response.data;
}

export default api