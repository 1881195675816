<template>
    <div class="center-content">

        <div class="block">
            <h3>Регистрация</h3>
            <div class="help-text">
                После верификации учетной записи Администратором, на указанный при регистрации
                электронный почтовый ящик, будет отправлена ссылка для активации регистрируемой
                учетной записи в Платформе "Пелена".
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>

    .block{
        width: 450px;
    }

    h3{
        margin-bottom: 40px;
    }

    .help-text{
        font-size: 16px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.7);
    }

</style>

<script>
    export default {

    }
</script>