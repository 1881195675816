<template>
    <my-block
            :width="900"
            :height="400"
            :top="70"
            :left="10"
            title="Модули"
            @close="$emit('close', true)"
    >

        <template slot="default">

            <table class="table viewDevsTbl fixed-head">
                <thead>
                <tr>
                    <td :style="`width: ${tblWidth.id}px`">ID</td>
                    <td :style="`width: ${tblWidth.state}px`">Статус</td>
                    <td :style="`width: ${tblWidth.errors}px`">Аварии</td>
                    <td :style="`width: ${tblWidth.type}px`">Тип модуля</td>
                    <td :style="`width: ${tblWidth.coordinates}px`">Координаты</td>
                    <td>Группы</td>
                    <td :style="`width: ${tblWidth.del}px`">&nbsp;</td>
                </tr>
                </thead>
            </table>
            <table class="table viewDevsTbl">
                <tbody>
                <template v-for="dev in devicesNodes">
                    <list-item :tbl-width="tblWidth"
                               :dev="dev"
                               @del-dialog="delDialog"
                    ></list-item>

                    <list-item v-for="d in dev.childNodes"
                               :key="d.id"
                               :tbl-width="tblWidth"
                               :dev="d"
                               :parent-node="dev"
                               @del-dialog="delDialog"
                    ></list-item>
                </template>
                </tbody>
            </table>

            <div class="popup-bg" v-if="popupDel">
                <div class="block" style="width: 345px">
                    <div class="title">Вы точно хотите удалить устройство {{ delDev.serial }}?</div>
                    <div class="btns">
                        <button class="btn red" type="button" @click="delDevice()">Удалить</button>
                        <button class="btn green" @click="popupDel = false">Отмена</button>
                    </div>
                </div>
            </div>
        </template>
    </my-block>
</template>

<script>
import myBlock from '../../../../components/responsiveBlock';
import devicesApi from '../../../../api/devices'
import {filter, each} from "lodash";
import {devTypes} from "../../../../config";
import listItem from './list-edit-item'

export default {

    components: {
        myBlock,
        listItem,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            tblWidth: {
                id: 160,
                state: 40,
                errors: 50,
                dim: 95,
                angle: 40,
                temp: 80,
                fw: 80,
                type: 145,
                coordinates: 175,
                del: 30,
            },
            delDev: null,
            popupDel: false,
        }
    },

    computed: {
        devices() {
            return filter(this.$store.state.devices.all, {type: devTypes.module})
        },
        devicesNodes(){
            return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.module && !d.nodeSerial
            })
        },
    },

   /* mounted() {
        each(this.devices, (d)=>{
            if(d.nodeSerial === undefined && d.state.toLowerCase() === 'online'){
                devicesApi.ctrl(d.id, 'sensors/update', true);
            }
        })
    },*/

    methods: {
        getParentDevs(serial){
            return filter(this.devices, {nodeSerial: serial});
        },
        delDialog(dev) {
            this.delDev = dev;
            this.popupDel = true;
        },
        async delDevice() {
            if (this.delDev) {
                await devicesApi.delete(this.delDev.id)
                await this.$store.dispatch('devicesLoad', this.project.id)
                this.popupDel = false;
            }
        }
    }
}
</script>