<template>
    <my-block
            :width="1120"
            :height="640"
            :top="70"
            :left="10"
            :disable-resize="true"
            @close="$emit('close', true)"
            title="Принципиальная схема вентиляции и кондиционирования воздуха">

        <template slot="default">
            <div style="text-align: center; padding-top: 15px">
                <div style="position: absolute; top: 175px; right: 30px; font-size: 22px; color: rgb(255, 177, 72);">
                    <img src="/images/icons/vent/temp.svg" style="margin: 0 3px -6px 0">
                    <span style="font-weight: 600;">{{ $store.state.devices.carelData.temperature }}°C</span>
                </div>
                <img src="/images/other/vent.svg" alt="">

                <div style="margin-top: 15px">
                    <a href="/images/cod-demo/cod-1.png" target="_blank">ЦОД-1</a>
                    <a href="/images/cod-demo/cod-2.png" target="_blank">ЦОД-2</a>
                    <a href="/images/cod-demo/cod-3.png" target="_blank">ЦОД-3</a>
                    <a href="/images/cod-demo/cod-4.png" target="_blank">ЦОД-4</a>
                </div>
            </div>
        </template>

    </my-block>
</template>


<script>
import myBlock from '../../../../components/responsiveBlock';
import axios from "axios";
import {DEVICE_CAREL_SET} from "../../../../store/mutations";

// const devId = 650;
const devId = 811;

export default {

    components:{
        myBlock
    },

    props: {
        project: Object,
    },

    data(){
        return {
            temp: 22,
            timer: null,
        }
    },

    mounted() {
        this.refreshTemp();
        this.timer = setInterval(()=>{
            this.refreshTemp();
        }, 9000);
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },

    methods:{
        async refreshTemp(){
            let response = await axios.get('/api/v1/carel/' + devId);
            // this.temp = response.data.value;
            this.$store.commit(DEVICE_CAREL_SET, response.data.value);
        },

        closeEvent(){
            this.$emit('close', true)
        },
    }
}
</script>