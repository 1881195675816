<template>
    <div>

        <div class="geo-info">
            <div>Широта: <span>{{schedule.latitude}}</span></div>
            <div>Долгота: <span>{{schedule.longitude}}</span></div>
            <div>Сдвиг: <span>{{schedule.shift}}</span></div>
        </div>

        <div style="margin-top: 15px">
            <div class="month-day-selector">
                <div class="month-select" style="width: 160px">
                    <v-select v-model="selectedMonth" class="no-border"
                              :options="months"
                              :clearable="false"
                              :searchable="false"
                              placeholder="Выберите месяц"
                    ></v-select>
                </div>
            </div>
        </div>

        <table class="table periods">
            <thead>
            <tr>
                <td style="width: 60px">День</td>
                <td style="width: 60px">Время</td>
                <td>Сценарий</td>
            </tr>
            </thead>
            <tbody>
                <template v-for="(items, day) in scheduleLocal.periods[selectedMonth.month]">
                    <tr v-for="item in items">
                        <td>{{day}}</td>
                        <td style="text-align: right">
                            {{item.startTime.hour}} : {{item.startTime.minute < 10 ? '0' + item.startTime.minute : item.startTime.minute}}
                        </td>
                        <td>
                            {{$store.state.scenarios.all[item.scenarioId] && $store.state.scenarios.all[item.scenarioId].name}}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.geo-info{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  div{
    margin-right: 20px;
    span{
      color: #bbbbbb;
    }
  }
}
</style>

<script>
    import {each, sortBy} from 'lodash'
    import api from "../../../../../api/schedules";
    import {months, scheduleTypes} from "../../../../../config";
    import vueSlider from 'vue-slider-component'
    import peInput from "../../../../../components/peInput";
    import dayjs from "dayjs";
    import dayjsObjectSupport from "dayjs/plugin/objectSupport";
    import dayjsUTC from "dayjs/plugin/utc";
    dayjs.extend(dayjsObjectSupport);
    dayjs.extend(dayjsUTC);

    let dt = new Date();

    export default {

        props: {
            project: Object,
            scheduleItem: Object,
            title: String,
        },

        components:{
            peInput,
            vueSlider,
        },

        data(){
            return {
                months,
                selectedMonth: months[dt.getMonth()],
                selectedDay: dt.getDate(),
                sliderOptions:{
                    dotSize: 12,
                    height: 4,
                    min: 0,
                    max: 100,
                    tooltip: 'none',
                },

                schedule:{ },
                scheduleLocal:{ periods: {} },

                errors:{
                    title: false,
                    days: false,
                }
            }
        },

        mounted() {
            this._updateData(this.scheduleItem);
        },

        created() {
            this._updateData(this.scheduleItem);
        },

        watch:{
            scheduleItem(newVal){
                if(newVal) this._updateData(newVal)
            }
        },

        methods:{
            _updateData(schedule){
                this.schedule = JSON.parse(JSON.stringify(schedule));

                this.scheduleLocal = { periods: {} };
                let y = new Date().getFullYear();
                let scheduleTsData = [];
                each(this.schedule.periods, (period, m)=>{
                    each(period, (items, d)=>{
                        each(items, (item) => {
                            let date = dayjs.utc({
                                year: y,
                                month: m - 1,
                                day: d,
                                hour: item.startTime.hour,
                                minute: item.startTime.minute,
                            });
                            scheduleTsData.push({
                                date: date.local(),
                                scenarioId: item.scenarioId,
                            })
                        })
                    })
                })

                scheduleTsData.forEach((item) => {
                    let m = item.date.month() + 1;
                    let d = item.date.date();

                    if(this.scheduleLocal.periods[m] === undefined){
                        this.$set(this.scheduleLocal.periods, m, {});
                    }
                    if(this.scheduleLocal.periods[m][d] === undefined){
                        this.scheduleLocal.periods[m][d] = [];
                    }
                    this.scheduleLocal.periods[m][d].push({
                        scenarioId: item.scenarioId,
                        startTime: {
                            hour: item.date.hour(),
                            minute: item.date.minute()
                        },
                    })
                })
            },

            createGeo(data){
                this.schedule = {
                    id: this.schedule.id,
                    latitude: data.geoData.lat,
                    longitude: data.geoData.lng,
                    shift: data.geoData.delta,
                    periods: {},
                };

                let periods = data.periods;
                months.forEach((m)=>{
                    this.$set(this.schedule.periods, m.month, {});
                    for(let d = 1; d <= m.days; d++){
                        this.$set(this.schedule.periods[m.month], d, []);
                        if(periods[m.month] !== undefined && periods[m.month][d] !== undefined){
                            this.schedule.periods[m.month][d] = periods[m.month][d];
                        }
                    }
                })

                this._updateData(this.schedule);

                this.$emit('create-geo');
            },

            async updateItem(){
                let hasError = false;

                if(!this.title){
                    this.errors.title = 'Заполните название расписания';
                    hasError = true;
                } else {
                    this.errors.title = false;
                }

                if(hasError) return;

                /*{
                    "id": 0,
                    "title": "string",
                    "projectId": 0,
                    "settings": [
                    {
                        "day": "Sunday",
                        "startTime": {},
                        "settings": [
                            {
                                "name": "string",
                                "value": 0
                            }
                        ]
                    }
                ]
                }*/
                let data = {
                    id: this.schedule.id,
                    title: this.title,
                    projectId: this.project.id,
                    type: scheduleTypes.geo,
                    latitude: this.schedule.latitude,
                    longitude: this.schedule.longitude,
                    shift: this.schedule.shift,
                    yearSettings: [ ],
                };
                each(this.schedule.periods, (monthData, month) => {
                    each(monthData, (dayData, day) => {
                        each(dayData, (p) => {

                            data.yearSettings.push({
                                day: {
                                    month: +month,
                                    day: +day,
                                },
                                startTime: p.startTime,
                                scenarioId: p.scenarioId,
                            })
                        })
                    })
                })
                await api.update(this.schedule.id, data);
                this.$emit('close', true);
            },

            closeEvent(){
                this.$emit('close', true)
            },
        },

        computed:{
            scenarios(){
                let arr = [];
                each(this.$store.state.scenarios.all, (s) =>{
                    arr.push({
                        id: s.id,
                        label: s.name,
                    })
                })
                return sortBy(arr, 'label');
            },
        },
    }
</script>