<template>
    <div class="block movable" style="display: flex; align-items: stretch; max-height: 330px">
        <div :style="`width: ${modulesTab ? 55 : 100}%`">
            <div class="header">
                <div class="block-title">Добавление группы</div>
                <img @click="closeEvent" src="/images/icons/close-a.svg" class="ico-btn-color close">
            </div>

            <div style="display: flex">
                <pe-input v-model="title" :error="errors.title" placeholder="Введите название группы"></pe-input>

                <img src="/images/icons/modules.svg"
                     v-if="groupType === devTypes.module"
                     class="ico-btn-color"
                     style="padding-top: 25px;"
                     @click="modulesTab = !modulesTab"
                     :class="{active: modulesTab}">
            </div>

            <div>Выберете цвет для группы</div>
            <div class="col-selector" :class="`group-type-${groupType}`">
                <div class="color" v-for="col in colors"
                     @click="selectedColor = col" :class="{selected: selectedColor === col}">
                    <div class="inner" :class="{selected: selectedColor === col}"
                         :style="`background: ${col}; box-shadow: 0 0 10px ${col}`"></div>
                </div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 25px;">
                <button  @click="closeEvent" class="btn red narrow" style=" width: 150px">Отмена</button>
                <button  @click="addGroup" class="btn green narrow" style=" width: 150px">Сохранить</button>
            </div>
        </div>

        <div v-if="modulesTab" style="margin: -15px 15px; width: 2px; background: #444444"></div>

        <div v-if="modulesTab" style="width: calc(45% - 3px); display: flex; flex-direction: column; align-items: stretch">
            <div class="header" style="flex-grow: 0">
                <div class="block-title">Устройства</div>
                <img @click="modulesTab = false" src="/images/icons/close-a.svg" class="ico-btn-color close">
            </div>
            <div class="devs-block">
                <div class="dev-row" v-for="dev in devices" :key="dev.id">
                    <div class="name">ID {{dev.serial}}</div>
                    <img class="ico-btn-color" src="/images/icons/minus-r-g.svg" @click="removeDev(dev.id)">
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
    .pe-input{
        flex-grow: 1;
        margin-top: 10px;
        margin-right: 15px;
        input{
            padding-bottom: 5px;
        }
    }
</style>

<script>
    import peInput from "../../../../components/peInput";
    import groupsApi from "../../../../api/groups";
    import {drawDevicesColorGroup, drawDevicesNoGroup, makeDraggable} from "../../../../other";
    import {MAP_SET_MODE_ADD_GROUP} from "../../../../store/mutations";
    import {each, filter} from 'lodash'
    import {devTypes} from "../../../../config";

    export default {

        props: {
            project: Object,
            groupType: String,
        },

        components:{
            peInput
        },

        data(){
            return {
                mode: 'add',
                devTypes,
                title:'',
                modulesTab: false,
                colors: [
                    '#FFB904',
                    '#05d903',
                    '#7381FF',
                    '#e03b94',
                    '#2f9651',
                    '#D2FF73',
                    '#3d6eff',
                    '#ff1a51',
                ],
                selectedColor: '#FFB904',
                errors:{
                    title: false,
                },
                devices: {},
            }
        },

        watch:{
            modulesTab(v){
                this.$store.commit(MAP_SET_MODE_ADD_GROUP, v)
                this.$afterSelectModules.setCallback((bounds) => {
                    each(this.devicesAll, (dev)=>{
                        if(dev.type !== this.groupType || dev.nodeSerial !== undefined) return;

                        if(dev.longitude === undefined || dev.latitude === undefined) return;

                        if(bounds.contains([dev.longitude, dev.latitude])){
                            this.$set(this.devices, dev.id, dev)
                        }
                    })
                    this.redrawDevices();
                })
            },
            selectedColor(v){
                this.redrawDevices()
            }
        },

        beforeDestroy() {
            this.$store.commit(MAP_SET_MODE_ADD_GROUP, false);
            this.$afterSelectModules.clearCallback();
        },

        computed:{
            devicesAll(){
                return filter(this.$store.state.devices.all,{ type: this.groupType })
            },
        },

        mounted() {
            makeDraggable(this.$el)
        },

        methods:{
            async addGroup(){
                if(this.title){
                    this.errors.title = false;
                    let group = await groupsApi.addToProject(this.project.id, {
                        id: 0,
                        projectId: this.project.id,
                        name: this.title,
                        color: this.selectedColor,
                        groupType: this.groupType,
                    })
                    try{
                        let devs = [];
                        each(this.devices, (d) => {
                            devs.push(d.id)
                            d.childNodes.forEach((subDev)=>{
                                devs.push(subDev.id)
                            })
                        })
                        await groupsApi.addDevices(group.id, devs)
                    } catch (e) {
                        if(e && e.response){
                            // ?
                        }
                    }
                    this.$store.dispatch('groupsLoad', this.project.id);
                    drawDevicesNoGroup(this.$map, this.devicesAll, false);
                    this.$emit('close', true);
                } else {
                    this.errors.title = 'Заполние название группы'
                }
            },

            redrawDevices(){
                let ids = []
                each(this.devices, (d) => {
                    ids.push(d.id)
                })
                drawDevicesColorGroup(this.$map, this.devicesAll, this.selectedColor, ids)
            },

            removeDev(devId){
                this.$delete(this.devices, devId)
                this.redrawDevices()
            },

            closeEvent(){
                this.$emit('close', true)
            },

        }
    }
</script>