import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'infra/projects'

api.loadTimezones = async (pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}infra/timezones`,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}

api.update = async function (id, itemData, onError) {
    try {
        return await axios.put(`${this.baseUrl}${this.resource}`, itemData)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
};

export default api