<template>
    <div>
        <div id="selectModulesDiv"></div>

        <img id="all-projs" @click="$to('projects')" src="/images/icons/projs-list.svg">

        <fast-project-selector 
                style="position: absolute; top: 10px; left: 70px;"
                @change-to="resetState"
        ></fast-project-selector>

<!-------------- MU TOP MENU ------------------>
        <div class="t-tools block" v-if="state.view === devTypes.module && state.mode === 'edit'">

            <div class="ico-block"
                 :class="{active: state.topEditMode & editModes.groups}"
                 @click="state.topEditMode ^= editModes.groups">
                <img src="/images/icons/tools-edit-top-groups.svg">
                <div class="help-text">Группы</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & editModes.modules}"
                 @click="state.topEditMode ^= editModes.modules">
                <img src="/images/icons/tools-edit-top-modules.svg">
                <div class="help-text">Список</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & editModes.schedules}"
                 @click="state.topEditMode ^= editModes.schedules">
                <img src="/images/icons/tools-edit-top-schedules.svg">
                <div class="help-text">Расписания</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & editModes.scenarios}"
                 @click="state.topEditMode ^= editModes.scenarios">
                <img src="/images/icons/tools-view-top-ctrl.svg">
                <div class="help-text">Сценарии</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & editModes.addDevs}"
                 @click="state.topEditMode ^= editModes.addDevs">
                <img src="/images/icons/tools-edit-top-addDev.svg">
                <div class="help-text">Добавить</div>
            </div>

        </div>


        <div class="t-tools block" v-if="state.view === devTypes.module && state.mode === 'view'">

            <div class="ico-block"
                 :class="{active: state.topViewMode & viewModes.ctrl}"
                 @click="state.topViewMode ^= viewModes.ctrl">
                <img src="/images/icons/tools-view-top-ctrl.svg">
                <div class="help-text">Управление</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topViewMode & viewModes.modules}"
                 @click="state.topViewMode ^= viewModes.modules">
                <img src="/images/icons/tools-view-top-modules.svg">
                <div class="help-text">Модули</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topViewMode & viewModes.angles}"
                 @click="state.topViewMode ^= viewModes.angles">
                <img src="/images/icons/tools-view-top-angle.svg">
                <div class="help-text">Наклон</div>
            </div>

            <div class="ico-block" style="display: none"
                 :class="{active: state.topViewMode & viewModes.service}"
                 @click="state.topViewMode ^= viewModes.service">
                <img src="/images/icons/tools-view-top-service.svg">
                <div class="help-text">Сервис</div>
            </div>

        </div>
<!-------------- MU TOP MENU end ------------->

<!-------------- SHUNO TOP MENU ------------------>
        <div class="t-tools block" v-if="state.view === devTypes.shuno && state.mode === 'edit'">

            <div class="ico-block"
                 :class="{active: state.topEditMode & shunoEditModes.groups}"
                 @click="state.topEditMode ^= shunoEditModes.groups">
                <img src="/images/icons/tools-top-shuno-edit-groups.svg">
                <div class="help-text">Группы</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & shunoEditModes.list}"
                 @click="state.topEditMode ^= shunoEditModes.list">
                <img src="/images/icons/tools-edit-top-modules.svg">
                <div class="help-text">Список</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & shunoEditModes.scenarios}"
                 @click="state.topEditMode ^= shunoEditModes.scenarios">
                <img src="/images/icons/tools-view-top-ctrl.svg">
                <div class="help-text">Сценарии</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & shunoEditModes.schedules}"
                 @click="state.topEditMode ^= shunoEditModes.schedules">
                <img src="/images/icons/tools-edit-top-schedules.svg">
                <div class="help-text">Расписания</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & shunoEditModes.add}"
                 @click="state.topEditMode ^= shunoEditModes.add">
                <img src="/images/icons/tools-top-shuno-edit-add.svg">
                <div class="help-text">Добавить</div>
            </div>
        </div>


        <div class="t-tools block" v-if="state.view === devTypes.shuno && state.mode === 'view'">

            <div class="ico-block"
                 :class="{active: state.topViewMode & shunoViewModes.ctrl}"
                 @click="state.topViewMode ^= shunoViewModes.ctrl">
                <img src="/images/icons/tools-view-top-ctrl.svg">
                <div class="help-text">Управление</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topViewMode & shunoViewModes.list}"
                 @click="state.topViewMode ^= shunoViewModes.list">
                <img src="/images/icons/tools-view-top-modules.svg">
                <div class="help-text">Список</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topViewMode & shunoViewModes.energy}"
                 @click="state.topViewMode ^= shunoViewModes.energy">
                <img src="/images/icons/tools-top-shuno-view-energy.svg">
                <div class="help-text">Энергия</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topViewMode & shunoViewModes.charts}"
                 @click="state.topViewMode ^= shunoViewModes.charts">
                <img src="/images/icons/tools-top-shuno-view-charts.svg">
                <div class="help-text">Графики</div>
            </div>

        </div>
<!------------ SHUNO TOP MENU end ---------------->


<!-------------- BS TOP MENU ------------------>
        <div class="t-tools block" v-if="0 && state.view === devTypes.baseStation && state.mode === 'edit'">

            <div class="ico-block"
                 :class="{active: state.topEditMode & bsEditModes.list}"
                 @click="state.topEditMode ^= bsEditModes.list">
                <img src="/images/icons/tools-edit-top-modules.svg">
                <div class="help-text">Группы</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & bsEditModes.add}"
                 @click="state.topEditMode ^= bsEditModes.add">
                <img src="/images/icons/tools-top-shuno-edit-add.svg">
                <div class="help-text">Добавить</div>
            </div>

        </div>


        <div class="t-tools block" v-if="0 && state.view === devTypes.baseStation && state.mode === 'view'">
            <div class="ico-block"
                 title="Список базовых станций"
                 :class="{active: state.topViewMode & bsViewModes.list}"
                 @click="state.topViewMode ^= bsViewModes.list">
                <img src="/images/icons/tools-edit-top-modules.svg">
                <div class="help-text">Список БС</div>
            </div>
        </div>
<!------------ BS TOP MENU end ---------------->


<!-------------- CONTROLLER TOP MENU ------------------>
        <div class="t-tools block" v-if="state.view === devTypes.PLC && state.mode === 'edit'">
            <div class="ico-block"
                 :class="{active: state.topEditMode & plcEditModes.list}"
                 @click="state.topEditMode ^= plcEditModes.list">
                <img src="/images/icons/top-menu/controller/list.svg">
                <div class="help-text">Список</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & plcEditModes.scenarios}"
                 @click="state.topEditMode ^= plcEditModes.scenarios">
                <img src="/images/icons/tools-view-top-ctrl.svg">
                <div class="help-text">Сценарии</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & plcEditModes.schedules}"
                 @click="state.topEditMode ^= plcEditModes.schedules">
                <img src="/images/icons/top-menu/controller/schedules.svg">
                <div class="help-text">Расписания</div>
            </div>

            <div class="ico-block"
                 :class="{active: state.topEditMode & plcEditModes.add}"
                 @click="state.topEditMode ^= plcEditModes.add">
                <img src="/images/icons/top-menu/controller/add.svg">
                <div class="help-text">Добавить</div>
            </div>
        </div>


        <div class="t-tools block" v-if="state.view === devTypes.PLC && state.mode === 'view'">

            <div class="ico-block"
                 :class="{active: state.topViewMode & plcViewModes.list}"
                 @click="state.topViewMode ^= plcViewModes.list">
                <img src="/images/icons/top-menu/controller/list.svg">
                <div class="help-text">Список</div>
            </div>

        </div>
<!------------ CONTROLLER TOP MENU end ---------------->


<!--------------- MU COMPONENTS --------------------------->
        <template v-if="project && state.view === devTypes.module">

            <template v-if="state.mode === 'edit'">
                <add-devices
                        v-if="state.topEditMode & editModes.addDevs"
                        :project="project"
                        :dev-type="devTypes.module"
                        @close="toggleEditMode(editModes.addDevs)"
                ></add-devices>

                <list-devices-edit
                        v-if="state.topEditMode & editModes.modules"
                        :project="project"
                        @close="toggleEditMode(editModes.modules)"
                ></list-devices-edit>

                <schedule-list
                        v-if="state.topEditMode & editModes.schedules"
                        :project="project"
                        @show-add="showAddSchedule = true; showEditSchedule = false"
                        @show-edit="editSchedule"
                        @close="toggleEditMode(editModes.schedules)"
                ></schedule-list>

                <groups-list-edit
                        v-if="state.topEditMode & editModes.groups"
                        :project="project"
                        :group-type="devTypes.module"
                        @show-add="showAddGroup = true; showEditGroup = false"
                        @show-edit="editGroup"
                        @close="toggleEditMode(editModes.groups)"
                ></groups-list-edit>

                <scenarios-list
                        v-if="state.topEditMode & editModes.scenarios"
                        :project="project"
                        @show-add="showAddScenario = true; showEditScenario = false"
                        @close="toggleEditMode(editModes.scenarios)"
                        @show-edit="editScenario"
                ></scenarios-list>

                <schedule-add
                        v-if="project && showAddSchedule"
                        :project="project"
                        @close="showAddSchedule = false"
                ></schedule-add>
            </template>

            <template v-if="state.mode === 'view'">
                <list-devices
                        v-if="state.topViewMode & viewModes.modules"
                        @close="toggleViewMode(viewModes.modules)"
                ></list-devices>

                <module-angles
                        v-if="state.topViewMode & viewModes.angles"
                        @close="toggleViewMode(viewModes.angles)"
                ></module-angles>

                <groups-list-ctrl
                        v-if="state.topViewMode & viewModes.ctrl"
                        :project="project"
                        :group-type="devTypes.module"
                        @close="toggleViewMode(viewModes.ctrl)"
                ></groups-list-ctrl>
            </template>

            <schedule-edit
                    id="schedule-edit" class="layer-top"
                    v-if="project && showEditSchedule"
                    :project="project"
                    :schedule="scheduleForEdit"
                    @close="showEditSchedule = false"
            ></schedule-edit>

            <group-add
                    id="group-add" class="layer-top"
                    v-if="project && showAddGroup"
                    :project="project"
                    :group-type="devTypes.module"
                    @close="showAddGroup = false"
            ></group-add>

            <group-edit
                    id="group-edit" class="layer-top"
                    v-if="project && showEditGroup"
                    :project="project"
                    :group="groupForEdit"
                    :group-type="devTypes.module"
                    @close="showEditGroup = false"
            ></group-edit>

        </template>
<!--------------- MU COMPONENTS end --------------------------->

<!--------------- SHUNO COMPONENTS --------------------------->
        <template v-if="project && state.view === devTypes.shuno">

            <template v-if="state.mode === 'edit'">

                <groups-list-edit
                        v-if="state.topEditMode & shunoEditModes.groups"
                        :project="project"
                        :group-type="devTypes.shuno"
                        @show-add="showAddGroup = true; showEditGroup = false"
                        @show-edit="editGroup"
                        @close="state.topEditMode ^= shunoEditModes.groups"
                ></groups-list-edit>

                <list-shuno-edit
                        v-if="state.topEditMode & shunoEditModes.list"
                        :project="project"
                        @close="state.topEditMode ^= shunoEditModes.list"
                ></list-shuno-edit>

                <scenarios-list
                        v-if="state.topEditMode & shunoEditModes.scenarios"
                        :project="project"
                        @show-add="showAddScenario = true; showEditScenario = false"
                        @close="toggleEditMode(shunoEditModes.scenarios)"
                        @show-edit="editScenario"
                ></scenarios-list>

                <add-devices
                        v-if="state.topEditMode & shunoEditModes.add"
                        :project="project"
                        :dev-type="devTypes.shuno"
                        @close="state.topEditMode ^= shunoEditModes.add"
                ></add-devices>

                <schedule-list
                        v-if="state.topEditMode & shunoEditModes.schedules"
                        :project="project"
                        @show-add="showAddSchedule = true; showEditSchedule = false"
                        @show-edit="editSchedule"
                        @close="state.topEditMode ^= shunoEditModes.schedules"
                ></schedule-list>

            </template>


            <template v-if="state.mode === 'view'">
                <shuno-ctrl
                        id="shuno-ctrl" class="layer-top"
                        v-if="state.topViewMode & shunoViewModes.ctrl"
                        :project="project"
                        @close="state.topViewMode ^= shunoViewModes.ctrl"
                ></shuno-ctrl>

                <shuno-ctrl-groups
                        v-if="state.topViewMode & shunoViewModes.ctrl"
                        :project="project"
                        @close="state.topViewMode ^= shunoViewModes.ctrl"
                ></shuno-ctrl-groups>

                <shuno-list-view
                        v-if="state.topViewMode & shunoViewModes.list"
                        :project="project"
                        @close="state.topViewMode ^= shunoViewModes.list"
                ></shuno-list-view>

                <shuno-common-state
                        v-if="state.topViewMode & shunoViewModes.energy"
                        :project="project"
                        @close="state.topViewMode ^= shunoViewModes.energy"
                ></shuno-common-state>

                <shuno-charts
                        v-if="state.topViewMode & shunoViewModes.charts"
                        :project="project"
                        @close="state.topViewMode ^= shunoViewModes.charts"
                ></shuno-charts>
            </template>

            <group-add
                    id="group-add" class="layer-top"
                    v-if="project && showAddGroup"
                    :project="project"
                    :group-type="devTypes.shuno"
                    @close="showAddGroup = false"
            ></group-add>

            <group-edit
                    id="group-edit" class="layer-top"
                    v-if="project && showEditGroup"
                    :project="project"
                    :group="groupForEdit"
                    :group-type="devTypes.shuno"
                    @close="showEditGroup = false"
            ></group-edit>

            <schedule-add
                    v-if="project && showAddSchedule"
                    :project="project"
                    @close="showAddSchedule = false"
            ></schedule-add>

            <schedule-edit
                    id="shuno-schedule-edit" class="layer-top"
                    v-if="project && showEditSchedule"
                    :project="project"
                    :schedule="scheduleForEdit"
                    @close="showEditSchedule = false"
            ></schedule-edit>

        </template>
<!--------------- SHUNO COMPONENTS end --------------------------->


<!--------------- rights COMPONENTS --------------------------->
        <template v-if="project && state.view === 'rights'">

            <template v-if="state.mode === 'edit'">

                <access-list class="layer-top"></access-list>

            </template>

        </template>
<!--------------- rights COMPONENTS end --------------------------->


<!--------------- vent COMPONENTS --------------------------->
        <template v-if="project && state.view === 'vent'">

            <vent-demo @close="state.view = 'none'"></vent-demo>

        </template>
<!--------------- vent COMPONENTS end --------------------------->


<!--------------- BaseStation COMPONENTS --------------------------->
        <template v-if="project && state.view === 'BaseStation'">

            <base-station-list
                    :project="project"
                    @close="state.view = 'none'"
            ></base-station-list>

        </template>
<!--------------- BaseStation COMPONENTS end --------------------------->


<!--------------- PLC COMPONENTS --------------------------->
        <template v-if="project && state.view === devTypes.PLC">

            <template v-if="state.mode === 'edit'">

                <plc-list-edit
                        :project="project"
                        v-if="state.topEditMode & plcEditModes.list"
                        @close="state.topEditMode ^= plcEditModes.list"
                ></plc-list-edit>

                <add-devices
                        v-if="state.topEditMode & plcEditModes.add"
                        @close="state.topEditMode ^= plcEditModes.add"
                        :project="project"
                        :dev-type="devTypes.PLC"
                ></add-devices>

                <schedule-list
                        v-if="state.topEditMode & plcEditModes.schedules"
                        :project="project"
                        @show-add="showAddSchedule = true; showEditSchedule = false"
                        @show-edit="editSchedule"
                        @close="state.topEditMode ^= plcEditModes.schedules"
                ></schedule-list>

                <scenarios-list
                        v-if="state.topEditMode & plcEditModes.scenarios"
                        :project="project"
                        @show-add="showAddScenario = true; showEditScenario = false"
                        @close="toggleEditMode(plcEditModes.scenarios)"
                        @show-edit="editScenario"
                ></scenarios-list>

                <schedule-add
                        v-if="project && showAddSchedule"
                        :project="project"
                        @close="showAddSchedule = false"
                ></schedule-add>
            </template>

            <template v-if="state.mode === 'view'">
                <plc-list
                        :project="project"
                        v-if="state.topViewMode & plcViewModes.list"
                        @close="state.topViewMode ^= plcViewModes.list"
                ></plc-list>
            </template>


        </template>
<!--------------- PLC COMPONENTS end --------------------------->


<!--------------- Logs COMPONENTS --------------------------->
        <template v-if="project && state.view === 'logs'">

            <template v-if="state.mode === 'view'">
                <logs-viewer
                        :project="project"
                        @close="state.view = 'none'"
                ></logs-viewer>
            </template>

        </template>
<!--------------- Logs COMPONENTS end --------------------------->

        <template v-if="state.mode === 'edit' && (state.view === devTypes.module || state.view === devTypes.shuno || state.view === devTypes.PLC)">
            <scenarios-add
                    v-if="project && showAddScenario"
                    :project="project"
                    @close="showAddScenario = false"
            ></scenarios-add>

            <scenarios-edit
                    v-if="project && showEditScenario"
                    :scenario="scenarioForEdit"
                    :project="project"
                    @close="showEditScenario = false"
            ></scenarios-edit>
        </template>

        <div id="b-tools-new" style="z-index: 500">
            <div class="item edit"
                 v-if="showEdit"
                 @click="setMode('edit')"
                 :class="{active: state.mode === 'edit'}">
                <img src="/images/icons/edit-a.svg">
            </div>
            <div class="t-group">
                <div class="item" :class="{active: state.view === devTypes.module}"
                     v-if="hasDevType(devTypes.module) || state.mode === 'edit'"
                     @click="setStateView(devTypes.module)">
                    <img src="/images/icons/project/b-ico-mu.svg">
                </div>

                <div class="item" :class="{active: state.view === devTypes.baseStation}"
                     v-if="hasDevType(devTypes.baseStation) || state.mode === 'edit'"
                     @click="setStateView(devTypes.baseStation)">
                    <img src="/images/icons/project/b-ico-bs.svg">
                </div>

                <div class="item" :class="{active: state.view === devTypes.shuno}"
                     v-if="hasDevType(devTypes.shuno) || state.mode === 'edit'"
                     @click="setStateView(devTypes.shuno)">
                    <img src="/images/icons/project/b-ico-shuno.svg">
                </div>

                <div class="item" :class="{active: state.view === devTypes.PLC}"
                     v-if="hasDevType(devTypes.PLC) || state.mode === 'edit'"
                     @click="setStateView(devTypes.PLC)">
                    <img src="/images/icons/project/b-ico-controller.svg">
                </div>

                <div class="item" :class="{active: state.view === devTypes.vent}"
                     v-if="0"
                     @click="setStateView(devTypes.vent)">
                    <img src="/images/icons/project/b-ico-vent.svg">
                </div>

                <div v-if="state.mode === 'view'"
                     class="item"
                     :class="{active: state.view === 'logs'}"
                     @click="setStateView('logs')">
                    <img src="/images/icons/reports.svg">
                </div>
            </div>
            <div class="item eye" @click="setMode('view')" :class="{active: state.mode === 'view'}">
                <img src="/images/icons/eye.svg" style="width: 20px">
            </div>
        </div>

        <div id="logs" :class="{expanded: log.expanded}">
            <div class="btns">
                <button v-if="log.expanded" class="btn" @click="log.expanded = false" style="margin-right: 15px">свернуть</button>
                <button v-else class="btn" @click="log.expanded = true" style="margin-right: 15px">развернуть</button>

                <button v-if="log.enabled" class="btn" @click="logToggle(false)">выкл</button>
                <button v-else class="btn" @click="logToggle(true)">вкл</button>

                <template v-if="log.enabled">
                    <button class="btn" :class="{selected: log.topic === 'spBv1.0/#'}" @click="logSubscribe('spBv1.0/#')">все</button>
                    <button class="btn" :class="{selected: log.topic === 'spBv1.0/lc/#'}"  @click="logSubscribe('spBv1.0/lc/#')">LC</button>
                    <button class="btn" :class="{selected: log.topic === 'spBv1.0/plc/#'}"  @click="logSubscribe('spBv1.0/plc/#')">PLC</button>
                    <button class="btn" :class="{selected: log.topic === 'spBv1.0/sta/#'}"  @click="logSubscribe('spBv1.0/sta/#')">STA</button>
                </template>

                <button class="btn" @click="logClear()" style="margin-left: 15px">очистить</button>
            </div>
            <div id="logs-items"></div>
        </div>

        <div id="dev-serial-tt"></div>
    </div>
</template>

<script>
    import moment from 'moment'
    import switcher from '../../components/switch'
    import vueSlider from 'vue-slider-component'
    import addDevices from './components/add-devices'
    import listDevices from './components/mu/list'
    import listDevicesEdit from './components/mu/list-edit'
    import listShunoEdit from './components/shuno/list-edit'
    import shunoListView from './components/shuno/view/list'
    import groupsListEdit from './components/group/list-edit'
    import groupsListCtrl from './components/group/list-ctrl'
    import scheduleList from './components/schedule/list'
    import scheduleAdd from './components/schedule/add'
    import scheduleEdit from './components/schedule/edit'
    import groupAdd from './components/group/add'
    import {filter, find, each} from 'lodash'
    import fastProjectSelector from './components/fast-projects-selector'
    import ventDemo from './components/vent/demo'

    import ShunoCtrl from './components/shuno/ctrl'
    import ShunoCtrlGroups from './components/shuno/group-ctrl'
    import ShunoCommonState from './components/shuno/view/commonState'
    import ShunoCharts from './components/shuno/view/chart'

    import PlcList from './components/plc/view/list'
    import PlcListEdit from './components/plc/edit/list'

    import ModuleAngles from './components/mu/angle/list'

    import baseStationList from './components/bs/list'

    import scenariosList from './components/scenario/list'
    import scenariosAdd from './components/scenario/add'
    import scenariosEdit from './components/scenario/edit'

    import logsViewer from './components/logs/logs.vue'

    import {drawDevicesNoGroup} from "../../other";
    import groupEdit from "./components/group/edit";
    import {devTypes, AccessRoles} from "../../config";

    import {PROJECT_MODE_SET} from "../../store/mutations";

    import protobuf from 'protobufjs';

    const editModes = {
        groups:    1,
        modules:   1<<1,
        schedules: 1<<2,
        addDevs:   1<<3,
        scenarios: 1<<4,
    }
    const viewModes = {
        ctrl:    1,
        modules: 1<<1,
        angles:  1<<2,
        service: 1<<3,
    }

    const shunoViewModes = {
        list:   1,
        energy: 1<<1,
        charts: 1<<2,
        ctrl:   1<<3,
    }
    const shunoEditModes = {
        groups:     1,
        schedules:  1<<1,
        list:       1<<2,
        add:        1<<3,
        scenarios:  1<<4,
    }

    const bsViewModes = {
        list:   1,
    }
    const bsEditModes = {
        list:   1,
        add:    1<<1,
    }

    const plcViewModes = {
        list:   1,
    }
    const plcEditModes = {
        list:      1,
        add:       1<<1,
        schedules: 1<<2,
        scenarios: 1<<3,
    }

    let redrawDevTimer;
    let redrawDevTimerMust;

    export default {
        components:{
            groupEdit,
            switcher,
            vueSlider,
            addDevices,
            listDevices,
            listDevicesEdit,
            groupsListEdit,
            groupsListCtrl,
            groupAdd,
            scheduleList,
            scheduleAdd,
            scheduleEdit,

            ShunoCtrl,
            ShunoCtrlGroups,
            listShunoEdit,
            shunoListView,

            ShunoCommonState,
            ShunoCharts,

            fastProjectSelector,

            ventDemo,

            baseStationList,

            ModuleAngles,

            scenariosList,
            scenariosAdd,
            scenariosEdit,

            PlcList,
            PlcListEdit,

            logsViewer,
        },

        data(){
            return {
                editModes,
                viewModes,

                shunoViewModes,
                shunoEditModes,

                bsViewModes,
                bsEditModes,

                plcViewModes,
                plcEditModes,

                devTypes,

                state: {
                    mode: 'view', // edit | view
                    view: devTypes.module,
                    topViewMode: 0,
                    topEditMode: 0,
                },
                showAddGroup: false,
                showEditGroup: false,
                showAddSchedule: false,
                showEditSchedule: false,
                groupForEdit: null,
                scheduleForEdit: null,

                showAddScenario: false,
                showEditScenario: false,
                scenarioForEdit: null,


                sliderOptions:{
                    dotSize: 18,
                    height: 6,
                    min: 1,
                    max: 100,
                },
                module:{
                    id: 'B55B932C',
                    power: true,
                    dim: 100,
                },
                clientId: '12345',

                log:{
                    enabled: false,
                    expanded: false,
                    topic: 'spBv1.0/#',
                }
            }
        },

        computed:{
            project(){
                return  this.$store.state.projects.all[this.$route.params.projectId];
            },

            devices(){
                if(this.state.view === 'all'){
                    return this.$store.state.devices.all;
                } else {
                    return filter(this.$store.state.devices.all, { type: this.state.view })
                }
            },

            showEdit(){
                if(this.showAdmin){
                    return true;
                }

                let user = this.$store.state.user;
                if(!user || !this.project){
                    return false;
                }

                if(user.email === this.project.owner){
                    return true;
                }

                for(let el of user.projects){
                    if(el.projectId == this.project.id && el.access === AccessRoles.edit){
                        return true;
                    }
                }

                return false;
            },

            showAdmin(){
                let user = this.$store.state.user;
                if(user && user.role === 'Administrator'){
                    return true;
                }
            },
        },

        methods:{
            setMode(v){
                this.state.mode = v;
                this.$store.commit(PROJECT_MODE_SET, v)
            },
            toggleEditMode(v){
                this.state.topEditMode ^= v;
            },
            toggleViewMode(v){
                this.state.topViewMode ^= v;
            },
            setStateView(v){
                this.state.view = v;
                this.state.topViewMode = 0;
                this.state.topEditMode = 0;
            },

            async loadProjectData(){
                await this.$store.dispatch('groupsLoad', this.project.id)
                await this.$store.dispatch('schedulesLoad', this.project.id)
                await this.$store.dispatch('devicesLoad', this.project.id)
                await this.$store.dispatch('scenariosLoad', this.project.id)
            },

            drawDevices(devs){
                clearTimeout(redrawDevTimer);
                clearTimeout(redrawDevTimerMust);
                redrawDevTimerMust = 0;
                redrawDevTimer = 0;
                // console.log('redraw !');
                drawDevicesNoGroup(this.$map, devs, false, this.state.view);

            },

            editGroup(group){
                this.showEditGroup = true;
                this.showAddGroup = false;
                this.groupForEdit = group;
            },

            editSchedule(s){
                this.showEditSchedule = true;
                this.showAddSchedule = false;
                this.scheduleForEdit = s;
            },

            editScenario(scenario){
                this.showEditScenario = true;
                this.showAddScenario = false;
                this.scenarioForEdit = scenario;
            },

            logClear(){
                document.getElementById('logs-items').innerHTML = '';
            },
            logSubscribe(topic){
                this.$mqtt.unsubscribe(this.log.topic);
                this.$mqtt.subscribe(topic);
                this.log.topic = topic;
            },
            logToggle(onOff){
                if(onOff){
                    this.$mqtt.subscribe(this.log.topic);
                }
                else{
                    this.$mqtt.unsubscribe(this.log.topic);
                }
                this.log.enabled = onOff;
            },

            resetState(){
                this.state.mode = 'view';
                this.state.view = devTypes.module;
                this.state.topViewMode = 0;
                this.state.topEditMode = 0;
            },

            hasDevType(devType){
                for(let devId in this.$store.state.devices.all){
                    let dev = this.$store.state.devices.all[devId];
                    if(dev.type === devType) return true;
                }
                return false;
            }
        },

        created() {

        },

        watch:{
            async project(v) {
                if (v) {
                    await this.loadProjectData();
                    if(this.devices) {
                        this.drawDevices(this.devices);
                    }
                    this.$map.flyTo({
                        center: [v.longitude, v.latitude],
                        zoom: 14,
                    })
                }
                /* this.$map.on('wheel', (e)=>{
                     console.log(this.$map.getZoom())
                 })*/
            },

            devices(devs){
                if(devs){
                    if(this.$map.loaded()){
                        if(!redrawDevTimerMust){
                            redrawDevTimerMust = setTimeout(()=>{
                                this.drawDevices(devs)
                            }, 15000)
                        }

                        if(redrawDevTimer){
                            clearTimeout(redrawDevTimer)
                        }
                        redrawDevTimer = setTimeout(()=>{
                            this.drawDevices(devs)
                        }, 5000)
                    } else {
                        this.$map.on('load', ()=>{
                            this.drawDevices(devs)
                        })
                    }
                }
            }
        },

        async mounted() {
            // console.log(this.project)
            if(this.project){
                this.$map.flyTo({
                    center: [this.project.longitude, this.project.latitude],
                    zoom: 14,
                })
            }

            const root = protobuf.Root.fromJSON(require('../../sparkplug.json'));
            const msgFactory = root.lookupType('org.eclipse.tahu.protobuf.Payload');

            let log = document.getElementById('logs-items');
            this.$mqtt.addListener('all', 'spBv1\\.0/.+', (params, msg)=>{
                if(log){
                    let ts = moment().format('HH:mm:ss.SSS DD.MM.YYYY');
                    let message = msgFactory.decode(msg);
                    let messageText = JSON.stringify(message, null, 4);
                    let topic = params[0];
                    let el = `<div class="log-item">
                    <div class="ts">${ts}</div>
                    <div class="text"><span class="topic">${topic}</span>:<br><span class="msg">${messageText}</span></div>
                </div>`;
                    log.innerHTML = el + log.innerHTML;
                }
                // log.scrollTop = log.scrollHeight;
            })
            // setTimeout(()=>{ this.$mqtt.subscribe(this.log.topic) }, 1000)


            if(this.project){
                await this.loadProjectData();
                this.drawDevices(this.devices);
            }

            /*setTimeout(()=>{
                let layerId = 'points'; // layer and source id
                this.$map.addSource(layerId, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [37.215518, 55.984677]
                                },
                            },
                        ]
                    }
                })

                this.$map.addLayer({
                    id: layerId,
                    type: 'circle',
                    source: layerId,
                    paint: {
                        'circle-radius': 9,
                        'circle-color': '#b8a700',
                        'circle-blur': 0.5,
                        // 'circle-stroke-width': 2,
                        // 'circle-stroke-color': '#009926',
                    }
                })

                this.$map.on('mouseenter', layerId, () => {
                    this.$map.getCanvas().style.cursor = 'pointer'
                })
                this.$map.on('mouseleave', layerId, () => {
                    this.$map.getCanvas().style.cursor = ''
                })
            }, 300)*/

        }
    }
</script>