<template>
    <my-block
            :width="widthResult"
            @resize="resetWidth"
            :height="400"
            :top="70"
            :left="10"
            @close="$emit('close', true)"
            title="Статусы контроллеров">

        <template slot="default">
            <div style="display: flex; height: 100%">
                <div style="overflow: auto">
                    <table class="table viewDevsTbl" style="position: sticky; top: 0;">
                        <thead>
                        <tr>
                            <td :style="`width: ${tblWidth.id}px`">ID</td>
                            <td :style="`width: ${tblWidth.state}px`">Статус</td>
                            <td :style="`width: ${tblWidth.errors}px`">Аварии</td>
                            <td :style="`width: ${tblWidth.temp}px`">Температура</td>
                            <td :style="`width: ${tblWidth.fw}px`">Прошивка</td>
                            <td :style="`width: ${tblWidth.type}px`">Тип модуля</td>
                            <td>Координаты</td>
                        </tr>
                        </thead>
                    </table>
                    <table class="table viewDevsTbl">
                        <tbody>
                        <template v-for="dev in devicesNodes">
                            <list-item :tbl-width="tblWidth"
                                       :dev="dev"
                                       :key="dev.id"
                                       :expanded="expanded[dev.id]"
                                       @expand="expanded[dev.id] = $event"
                                       :is-node="true"></list-item>

                            <template v-if="expanded[dev.id]">
                                <list-item v-for="dId in dev.childNodesId"
                                           :key="dId"
                                           :tbl-width="tblWidth"
                                           :dev="$store.state.devices.all[dId]"
                                           :is-node="false"
                                           :selected="selectedDevId === dId"
                                           @select-dev="selectedDevId = $event"
                                ></list-item>
                            </template>

                        </template>

                        </tbody>
                    </table>
                </div>

                <div v-if="selectedDevId" style="overflow: auto; margin-left: 5px; border-left: 1px solid #525252; padding-left: 14px;">
                    <dev-data :dev="$store.state.devices.all[selectedDevId]" @close="selectedDevId = null"></dev-data>
                </div>

            </div>

        </template>

    </my-block>

</template>


<script>
import {each, filter} from "lodash";
import {devTypes} from "../../../../../config";
import myBlock from '../../../../../components/responsiveBlock';
import listItem from './list-item'
import devData from "./dev-data.vue"

export default {

    components: {
        myBlock,
        listItem,
        devData,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            width: 780,
            subDevWidth: 350,
            tblWidth: {
                id: 120,
                state: 40,
                errors: 50,
                temp: 80,
                fw: 80,
                type: 115,
            },
            expanded: {},
            selectedDevId: null,
        }
    },

    computed: {
        devicesNodes() {
            let devs = [];
            each(this.$store.state.devices.all, (d) => {
                if(d.type === devTypes.PLC && !d.nodeSerial){
                    devs.push(d)
                }
            })
            return devs;
            /*return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.PLC && !d.nodeSerial
            })*/
        },

        widthResult(){
            return this.width + (this.selectedDevId ? this.subDevWidth : 0);
        }
    },

    watch:{
        devicesNodes(v, o){
            this._setExpand();
        },
    },

    mounted() {
        this._setExpand();
    },

    methods: {
        closeEvent() {
            this.$emit('close', true)
        },

        _setExpand(){
            each(this.devicesNodes, (d) =>{
                this.$set(this.expanded, d.id, true);
            })
        },

        resetWidth(e){
            this.width = e.width - (this.selectedDevId ? this.subDevWidth : 0);
        }
    },
}
</script>