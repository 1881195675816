<template>
    <div>
        <my-block
                :width="750"
                :height="400"
                :top="70"
                :left="10"
                @close="$emit('close', true)"
                title="Thread данные">

            <template slot="default">
                <table class="table viewDevsTbl" style="position: sticky; top: 0;">
                    <thead>
                    <tr>
                        <td :style="`width: ${tblWidth.status}px`">&nbsp;</td>
                        <td :style="`width: ${tblWidth.serial}px`">Серийный номер</td>
                        <td>Name</td>
                        <td :style="`width: ${tblWidth.pan}px`">PanId</td>
                        <td :style="`width: ${tblWidth.rloc}px`">rLoc16</td>
                        <td :style="`width: ${tblWidth.role}px`">Role</td>
                    </tr>
                    </thead>
                </table>
                <table class="table viewDevsTbl">
                    <tbody>
                    <tr v-for="dev in devices">
                        <td :style="`width: ${tblWidth.status}px`">
                            <div :class="dev.online ? 'online' : 'offline'"></div>
                        </td>
                        <td :style="`width: ${tblWidth.serial}px`">
                            <span class="dev-selector"
                                  @click="selectedDevice = dev"
                                  :class="{selected: selectedDevice && dev.id === selectedDevice.id}">
                                {{ dev.serial }}
                            </span>
                        </td>
                        <td>{{dev.thread.name}}</td>
                        <td :style="`width: ${tblWidth.pan}px`">{{dev.thread.panId}}</td>
                        <td :style="`width: ${tblWidth.rloc}px`">{{dev.thread.rLoc16}}</td>
                        <td :style="`width: ${tblWidth.role}px`">{{dev.thread.role}}</td>
                    </tr>
                    </tbody>
                </table>
            </template>

        </my-block>

        <more-info v-if="selectedDevice"
                   @close="selectedDevice = null"
                   :bs="selectedDevice"
                   :devices="selectedDevice.thread.neighbors"
        ></more-info>
    </div>
</template>

<style lang="scss">
@import "src/scss/vars";
.dev-selector{
  cursor: pointer;
  color: $colorAcent;
  &.selected{
    filter: saturate(0);
    cursor: default;
  }
}
</style>

<script>
import myBlock from '../../../../components/responsiveBlock';
import moreInfo from './more'
import devicesApi from "../../../../api/devices";
import {each} from 'lodash';

export default {

    components: {
        myBlock,
        moreInfo,
    },

    props: {
        project: Object,
        editList: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            popupDel: false,
            delDev: null,
            tblWidth: {
                status: 20,
                serial: 125,
                pan: 90,
                rloc: 90,
                role: 100,
            },
            selectedDevice: null,
        }
    },

    computed: {
        devices() {
            let devs = [];

            each(this.$store.state.devices.all, (dev) =>{
                if(dev.data && dev.data.connection && dev.data.connection.thread){
                    let th = dev.data.connection.thread;
                    devs.push({
                        id: dev.id,
                        serial: dev.serial,
                        online: dev.state.toLowerCase() === 'online',
                        thread:{
                            name: th.name.value,
                            panId: th.panId.value,
                            rLoc16: th.rLoc16.value,
                            role: th.role.value,
                            neighbors: th.neighbors.value,
                        }
                    })
                }
            })

            return devs;
        },
    },

    methods: {

        delDialog(dev) {
            this.delDev = dev;
            this.popupDel = true;
        },

        async delDevice() {
            if (this.delDev) {
                await devicesApi.delete(this.delDev.id)
                await this.$store.dispatch('devicesLoad', this.project.id)
                this.popupDel = false;
            }
        }
    }
}
</script>