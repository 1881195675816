<template>
    <div>
        <div class="common-info">
            <div class="info-row">
                <div class="label">Тип устройства:</div>
                <div class="val">{{dev.data.deviceType}}</div>
            </div>

            <div class="info-row">
                <div class="label">Серийный номер:</div>
                <div class="val">{{dev.data.serial}}</div>
            </div>

            <div class="info-row">
                <div class="label">Прошивка:</div>
                <div class="val">{{dev.data.firmwareVersion}} от {{fwDate}}</div>
            </div>
        </div>

        <div style="margin: 15px 0; display: flex; align-items: center;">
            <div style="width: 120px;">Расписание</div>
            <div style="width: 200px">
                <pe-select :value="dev.scheduleId"
                           @input="setSchedule" class="h32 no-border"
                           :options="schedules"
                           style="margin: 0"
                           placeholder="Выбор расписания"></pe-select>
            </div>
        </div>

        <div style="margin: 15px 0; display: flex; align-items: center;">
            <div style="width: 120px;">Название</div>
            <div style="width: 200px" class="label-input">
                <pe-input v-model="title"></pe-input>
            </div>
        </div>

        <div style="margin-right: 10px">
            <table class="table">
                <thead>
                <tr>
                    <td>Выход</td>
                    <td>Описание</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in 16">
                    <td>№{{i}}</td>
                    <td class="label-input">
                        <pe-input v-model="descriptions['out/' + (i-1)]"></pe-input>
                    </td>
                </tr>
                </tbody>
            </table>

            <div style="text-align: right; margin-top: 15px">
                <button  @click="saveDescriptions()" class="btn green narrow" style="width: 150px">Сохранить</button>
            </div>
        </div>

    </div>
</template>

<style lang="scss">
.label-input {
  .pe-input{
    margin: 0;
    input{
      padding: 5px 0;
    }
  }
}

.ctrl-rows{
  margin-top: 15px;
  .row{
    display: flex;
    align-items: center;
    margin: 10px 0;
    .label{
      width: 120px;
    }
  }
}

.no-border select {
  border: none;
}
</style>

<script>
import dayjs from 'dayjs';
import switcher from '../../../../../../components/switch'
import devicesApi from "../../../../../../api/devices";
import {each} from "lodash";
import peSelect from "../../../../../../components/peSelect";
import peInput from "../../../../../../components/peInput";
import {DEVICE_UPDATE_PROP} from "../../../../../../store/mutations";
import devsApi from "../../../../../../api/devices";

export default {
    props:{
        devId: Number,
    },

    components:{
        switcher,
        peSelect,
        peInput,
    },

    data(){
        return {
            descriptions:{},
            title: '',
        }
    },

    methods:{
        _rebindDescriptions(){
            for(let i=0; i < 16; i++){
                let k = 'out/'+i;
                this.$set(this.descriptions, k, this.dev.data.digitalEndpoints[i].state.description || '')
            }
            this.title = this.dev.title;
        },

        toggleOut(outNum){
            devicesApi.ctrl(this.dev.id, 'out/' + outNum, this.ctrlVals[outNum]);
        },

        setSchedule(scheduleId){
            scheduleId = parseInt(scheduleId)
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: scheduleId,
                val: scheduleId
            })
            devsApi.setSchedule(this.dev.id, scheduleId);
        },

        getUpdateTime(i){
            if(this.dev.data && this.dev.data.digitalEndpoints){
                return 'Значение получено в ' + dayjs(this.dev.data.digitalEndpoints[i].state.timestamp).format('HH:mm:ss DD.MM.YYYY')
            }
            return '';
        },

        async saveDescriptions(){
            let data = [];
            each(this.descriptions, (v, k)=>{
                data.push({
                    name: k,
                    value: v,
                })
            })

            await devicesApi.update(this.dev.id, {
                id: this.dev.id,
                title: this.title,
                descriptions: data,
            });

            this.$store.dispatch('deviceReload', this.dev.id);
        },
    },

    watch:{
        dev(){
            this._rebindDescriptions()
        }
    },

    beforeMount() {
        this._rebindDescriptions()
    },

    computed:{
        fwDate(){
            if(this.dev.data && this.dev.data.firmwareDate){
                return dayjs(this.dev.data.firmwareDate).format('DD.MM.YYYY HH:mm:ss')
            }
            return '';
        },

        schedules() {
            let options = {
                0: 'Без расписания'
            };
            each(this.$store.state.schedules.all, (s) => {
                options[s.id] = s.title;
            })
            return options;
        },

        dev(){
            return this.$store.state.devices.all[this.devId];
        }
    },
}
</script>