<template>
    <my-block
            :width="560"
            :height="400"
            :left="10"
            :top="70"
            title="Общие данные"
            @close="closeEvent">

        <template v-slot:default>

            <div id="shuno-selector">
                <v-select v-model="selectedShuno"
                          :options="devices"
                          :getOptionLabel="option => option.nodeSerial + ' - ' + option.serial"
                          :clearable="false"
                          placeholder="Выберите ШУНО">
                </v-select>
            </div>

            <div v-if="selectedShuno" style="margin-right: 10px">

                <div class="tbl-title">Напряжение</div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td>Фаза</td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущее</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, f) in power.u">
                        <td>U<span class="sub-char">{{f}}</span></td>
                        <td>{{numFormat(v.max)}}</td>
                        <td>{{numFormat(v.min)}}</td>
                        <td>{{numFormat(v.avg)}}</td>
                        <td>{{numFormat(v.current)}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="tbl-title">Сила тока</div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td>Фаза</td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущая</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, f) in power.i">
                        <td>I<span class="sub-char">{{f}}</span></td>
                        <td>{{numFormat(v.max)}}</td>
                        <td>{{numFormat(v.min)}}</td>
                        <td>{{numFormat(v.avg)}}</td>
                        <td>{{numFormat(v.current)}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="tbl-title">
                    <div class="title">Активная мощность</div>
<!--                    <div class="p-type" :class="{active: !showActiveReverse}" @click="showActiveReverse = false">Прямая</div>-->
<!--                    <div class="p-type" :class="{active: showActiveReverse}" @click="showActiveReverse = true">Обратная</div>-->
                </div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td>Фаза</td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущая</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, f) in (showActiveReverse ? power.pr : power.p)">
                        <td>P<span class="sub-char">{{f}}</span></td>
                        <td>{{numFormat(v.max)}}</td>
                        <td>{{numFormat(v.min)}}</td>
                        <td>{{numFormat(v.avg)}}</td>
                        <td>{{numFormat(v.current)}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="tbl-title">
                    <div class="title">Реактивная мощность</div>
<!--                    <div class="p-type" :class="{active: !showReactiveReverse}" @click="showReactiveReverse = false">Прямая</div>-->
<!--                    <div class="p-type" :class="{active: showReactiveReverse}" @click="showReactiveReverse = true">Обратная</div>-->
                </div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td>Фаза</td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущая</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, f) in (showReactiveReverse ? power.qr : power.q)">
                        <td>Q<span class="sub-char">{{f}}</span></td>
                        <td>{{numFormat(v.max)}}</td>
                        <td>{{numFormat(v.min)}}</td>
                        <td>{{numFormat(v.avg)}}</td>
                        <td>{{numFormat(v.current)}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="tbl-title">Cos φ</div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td>Фаза</td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущий</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, f) in power.cos">
                        <td>Cos φ<span class="sub-char">{{f}}</span></td>
                        <td>{{numFormat(v.max)}}</td>
                        <td>{{numFormat(v.min)}}</td>
                        <td>{{numFormat(v.avg)}}</td>
                        <td>{{numFormat(v.current)}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="tbl-title">Угол межфазного напряжения</div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td>Фаза</td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущий</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v, f) in power.angle">
                        <td>Угол<span class="sub-char">{{f}}</span></td>
                        <td>{{numFormat(v.max)}}</td>
                        <td>{{numFormat(v.min)}}</td>
                        <td>{{numFormat(v.avg)}}</td>
                        <td>{{numFormat(v.current)}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="tbl-title">Частота сети</div>
                <table class="table viewShunoCommon">
                    <thead>
                    <tr>
                        <td></td>
                        <td>Макс</td>
                        <td>Мин</td>
                        <td>Среднее</td>
                        <td>Текущая</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Частота</td>
                        <td>{{numFormat(power.freq.max)}}</td>
                        <td>{{numFormat(power.freq.min)}}</td>
                        <td>{{numFormat(power.freq.avg)}}</td>
                        <td>{{numFormat(power.freq.current)}}</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </template>

    </my-block>
</template>

<style lang="scss">
@import "src/scss/vars";

.tbl-title {
  color: #dcdcdc;
  padding-bottom: 10px;
  border-bottom: 2px solid #444444;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .title{
    flex-grow: 1;
  }

  .p-type {
    font-weight: 400;
    color: #888888;
    font-size: 15px;
    margin-left: 10px;
    cursor: pointer;

    &.active {
      color: #E8E8E8;
    }
  }
}

.viewShunoCommon {
  margin-bottom: 20px;
  td{
    width: 20%;
  }
}

.sub-char {
  font-size: 80%;
  transform: translateY(4px);
  display: inline-block;
  text-transform: uppercase;
}

#shuno-selector{
  margin: 10px 10px 20px 0;
  position: sticky;
  top: 0;
  background: $colorBack;
  z-index: 2;
}
</style>

<script>
import {each, filter, sortBy} from 'lodash'
import myBlock from '../../../../../components/responsiveBlock';
import peSelect from '../../../../../components/peSelect';
import {devTypes, hwDevTypes} from "../../../../../config";
import axios from 'axios';

export default {

    props: {
        project: Object,
    },

    components: {
        myBlock,
        peSelect,
    },

    data() {
        return {
            selectedShuno: null,
            showActiveReverse: false,
            showReactiveReverse: false,
            _resyncInterval: null,
            power: {
                u: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                },
                i: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                },
                p: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                    // sum: {min: -1, max: -1, avg: -1, current: -1}
                },
                q: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                    // sum: {min: -1, max: -1, avg: -1, current: -1}
                },
                cos: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                },
                angle: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                },
                freq: {min: -1, max: -1, avg: -1, current: -1},


                pr: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                    // sum: {min: -1, max: -1, avg: -1, current: -1}
                },
                qr: {
                    a: {min: -1, max: -1, avg: -1, current: -1},
                    b: {min: -1, max: -1, avg: -1, current: -1},
                    c: {min: -1, max: -1, avg: -1, current: -1},
                    // sum: {min: -1, max: -1, avg: -1, current: -1}
                },
            }
        }
    },

    computed: {
        devices() {
            return filter(this.$store.state.devices.all, {
                type: devTypes.shuno,
                data:{
                    deviceType: hwDevTypes.Interfaces,
                }
            })
        }
    },

    watch:{
        selectedShuno(val, oldVal){
            if(val){
                this.refreshData();
            }
        }
    },

    methods: {
        closeEvent() {
            this.$emit('close', true)
        },

        numFormat(v){
            return v !== -1 ? v.toFixed(2) : '—';
        },

        async refreshData(){
            if(!this.selectedShuno) return;
            try{
                let response = await axios.get('/api/v1/mercury/' + this.selectedShuno.id);
                /* {
                  "name": "string",
                  "phases": [
                    {
                      "phase": "A",
                      "min": 0,
                      "max": 0,
                      "avg": 0,
                      "current": 0
                    }
                  ]
                } */
                response.data.forEach((el)=>{
                    let p = el.name.toLowerCase();
                    if(p === 'freq'){
                        el.phases.forEach((ph)=>{
                            let phase = ph.phase.toLowerCase();
                            if(phase === 'all'){
                                this.power[p].min = ph.min;
                                this.power[p].max = ph.max;
                                this.power[p].avg = ph.avg;
                                this.power[p].current = ph.current;
                            }
                        });
                    }else if(p === 'q' || p === 'p'){
                        // this.power[p].sum = {min: 0, max: 0, avg: 0, current: 0};
                        el.phases.forEach((ph)=>{
                            let phase = ph.phase.toLowerCase();
                            if(!['a', 'b', 'c'].includes(phase)) return;
                            this.power[p][phase].min = ph.min;
                            this.power[p][phase].max = ph.max;
                            this.power[p][phase].avg = ph.avg;
                            this.power[p][phase].current = ph.current;

                            // this.power[p].sum.min += this.power[p][phase].min;
                            // this.power[p].sum.max += this.power[p][phase].max;
                            // this.power[p].sum.avg += this.power[p][phase].avg;
                            // this.power[p].sum.current += this.power[p][phase].current;
                        });
                    } else  {
                        el.phases.forEach((ph)=>{
                            let phase = ph.phase.toLowerCase();
                            if(!['a', 'b', 'c'].includes(phase)) return;
                            this.power[p][phase].min = ph.min;
                            this.power[p][phase].max = ph.max;
                            this.power[p][phase].avg = ph.avg;
                            this.power[p][phase].current = ph.current;
                        });
                    }

                })
            } catch (e){
                console.error(e)
            }
        }
    },

    mounted() {
        this._resyncInterval = setInterval(()=>{
            this.refreshData()
        }, 10000)
    },

    beforeDestroy() {
        clearInterval(this._resyncInterval);
    }
}
</script>