import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'groups'

api.loadByProject = async (projectId, pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}groups/project/` + projectId,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}

api.addToProject = async (projectId, data) => {
    let response = await axios.post(`${api.baseUrl}groups/` + projectId, data)
    return response.data;
}

api.addDevices = async (groupId, data) => {
    let response = await axios.post(`${api.baseUrl}groups/${groupId}/devices`, data)
    return response.data;
}

api.removeDevices = async (groupId, data) => {
    let response = await axios.delete(`${api.baseUrl}groups/${groupId}/devices`, data)
    return response.data;
}

api.replaceDevices = async (groupId, data) => {
    let response = await axios.post(`${api.baseUrl}groups/${groupId}/devices/replace`, data)
    return response.data;
}

api.setSchedule = async (groupId, scheduleId) => {
    if(scheduleId){
        let data = {
            id: groupId,
            scheduleId,
        };
        let response = await axios.put(`${api.baseUrl}groups/${groupId}/schedule`, data)
        return response.data;
    } else {
        await axios.delete(`${api.baseUrl}groups/${groupId}/schedule`);
        return true;
    }
}


api.ctrl = (groupId, metric, val) => {
    let cmd = {
        metricName: metric,
        // boolValue: false,
        // uintValue: 0
    }
    if(typeof val === 'boolean'){
        cmd.boolValue = val
    } else {
        cmd.uintValue = parseInt(val)
    }
    axios.post('/api/command/group/' + groupId, {
        groupId: groupId,
        commands: [ cmd ]
    })
}

api.toggleSchedule = (groupId, val) => {
    let cmd = {
        metricName: 'schedule',
        stringValue: val ? 'on' : 'off',
    }
    axios.post('/api/command/group/' + groupId, {
        groupId: groupId,
        commands: [ cmd ]
    })
}

/*
metrics: [{
    metric: 'out/1',
    val: false,
}, ...]
 */
api.ctrlMulti = (groupId, metrics) => {
    let commands = [];
    metrics.forEach((m) => {
        let cmd = {
            metricName: m.metric,
            // boolValue: false,
            // uintValue: 0
        }
        if(typeof m.val === 'boolean'){
            cmd.boolValue = m.val
        } else {
            cmd.uintValue = parseInt(m.val)
        }
        commands.push(cmd);
    })

    axios.post('/api/command/group/' + groupId, {
        groupId: groupId,
        commands: commands
    })
}


export default api