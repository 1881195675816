<template>
    <div class="s-block">
        <div style="min-width: 250px">
            <v-select v-model="selectedProject"
                      :options="projects"
                      :clearable="false"
                      placeholder="Выберите проект"
                      @input="toProject()"
            ></v-select>
        </div>
    </div>
</template>

<style lang="scss">
@import "src/scss/vars";
.s-block{
  background: $colorBack;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 20px;
  border-radius: 5px;

  .vs__dropdown-toggle {
    border-bottom: none;
  }
}
</style>

<script>
import {each} from 'lodash'

export default {

    data(){
        return {
            selectedProject: 0,
        }
    },

    methods:{
        toProject(){
            this.$router.push({ name: 'projects-view', params: { projectId: this.selectedProject.id } });
            this.$emit('change-to', this.selectedProject.id);
        }
    },

    watch:{
        selectedProject(val){

        }
    },

    computed:{
        projects() {
            let id = +this.$route.params.projectId;
            let a = [];
            each(this.$store.state.projects.all, (p) =>{
                let o = {
                    label: p.name,
                    id: p.id,
                };
                a.push(o)
                if(id === p.id && this.selectedProject === 0){
                    this.selectedProject = o;
                }
            })
            return a;
        }
    }
}
</script>