<template>
    <div>
        <pe-input v-model="scenario.name" :error="errors.name"
                  type="text" placeholder="Название" autocomplete="off"></pe-input>

        <pe-input v-model="scenario.description"
                  type="text" placeholder="Описание" autocomplete="off"></pe-input>


        <div class="cmd-group" v-for="(gr, grI) in sortedActions">
            <div class="cmd-group-head">
                <div class="updown">
                    <img @click="orderAction(gr, true)"
                         v-if="gr.position > 0"
                         class="up"
                         src="/images/icons/s/arrow.svg">

                    <img @click="orderAction(gr, false)"
                         v-if="gr.position < sortedActions.length - 1"
                         class="down"
                         src="/images/icons/s/arrow.svg">
                </div>

                <div class="cmd-group_title">Группа команд {{grI + 1}}</div>

                <img @click="addAction()"
                     src="/images/icons/plus.svg"
                     class="ico-btn-color s20"
                     title="Добавить">

                <img @click="delAction(gr)"
                     v-if="scenario.actions.length > 1"
                     src="/images/icons/close-a.svg"
                     class="ico-btn-color s16"
                     style="margin-left: 20px"
                     title="Удалить">
            </div>

            <div class="cmd-group-row">
                <div class="updown smaller"></div>

                <div class="dev-type">
                    Задержка
                </div>

                <div class="metric-val">
                    <pe-input class="no-margin" v-model="gr.duration"></pe-input>
                </div>

                <div class="metric-val-descr">мс</div>
            </div>

            <div class="cmd-group-row" v-for="m in sortedMetrics(gr.metrics)">
                <div class="updown smaller">
                    <img @click="orderMetric(m, true, sortedMetrics(gr.metrics))"
                         v-if="m.position > 0"
                         class="up"
                         src="/images/icons/s/arrow.svg">

                    <img @click="orderMetric(m, false, sortedMetrics(gr.metrics))"
                         v-if="m.position < gr.metrics.length - 1"
                         class="down"
                         src="/images/icons/s/arrow.svg">
                </div>

                <div class="dev-type">
                    <v-select v-model="m.devTypeTag"
                              :options="devTypesOptions"
                              :reduce="option => option.devTypeTag"
                              @input="wipeBadMetric(m)"
                              :clearable="false"
                              placeholder="Тип устройства"
                    ></v-select>
                </div>

                <div class="dev-metric">
                    <v-select v-model="m.name" v-if="devTypes[m.devTypeTag]"
                              :options="devTypes[m.devTypeTag].metrics"
                              :reduce="option => option.metric"
                              label="name"
                              :clearable="false"
                              placeholder="Метрика"
                    ></v-select>
                </div>

                <div class="metric-val" v-if="getMetricParams(m) !== undefined">
                    <template v-if="getMetricParams(m).type === 'bool'">
                        <switcher v-model="m.value"></switcher>
                    </template>
                    <template v-else>
                        <pe-input v-model="m.value" class="no-margin"
                                  :min="getMetricParams(m).min"
                                  :max="getMetricParams(m).max"
                                  type="number"
                                  placeholder="Значение"
                        ></pe-input>
                    </template>
                </div>

                <div class="metric-val-descr" v-if="getMetricParams(m) !== undefined && getMetricParams(m).prefix !== undefined">
                    {{getMetricParams(m).prefix}}
                </div>

                <div class="spacer"></div>

                <img @click="delMetric(m, gr.metrics)"
                     v-if="gr.metrics.length > 1"
                     src="/images/icons/close-a.svg"
                     class="ico-btn-color s16"
                     title="Удалить">
            </div>

            <div class="cmd-group-row">
                <img @click="addMetric(gr)"
                     src="/images/icons/plus.svg"
                     class="ico-btn-color s20"
                     style="margin-left: 25px;"
                     title="Добавить метрику">
            </div>


        </div>


    </div>
</template>

<style lang="scss">
.ico-btn-color.s20{
  height: 20px;
}
.ico-btn-color.s16{
  height: 16px;
}

.cmd-group{
  color: #c0c0c0;
  margin-bottom: 35px;
}

.cmd-group-row{
  margin: 10px 16px;
  display: flex;
  align-items: center;

  .dev-type{
    width: 120px;
    margin-left: 10px;
  }

  .dev-metric{
    width: 240px;
    margin: 0 20px;
  }

  .metric-val{
    width: 70px;
    margin: 0 10px;
  }
}

.updown{
  width: 16px;
  height: 32px;

  img{
    display: block;
    cursor: pointer;
    filter: grayscale(1);
    transition: filter 300ms;
    width: 16px;
    &:hover{
      filter: grayscale(0);
    }
    &.down{
      transform: rotate(180deg);
    }
  }
  &.smaller{
    width: 14px;
    height: 28px;
    img{
      width: 14px;
    }
  }
}

.cmd-group_title{
  width: 160px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.cmd-group-head{
  display: flex;
  align-items: center;
}
</style>

<script>
import {each, filter, sortBy} from "lodash";
import peInput from "../../../../components/peInput";
import switcher from "../../../../components/switch";
import myBlock from '../../../../components/responsiveBlock';
import {devTypesMetrics, metricOptions} from '../../../../config';
import {analogToPercent, daliToDim} from "../../../../tools";


export default {

    components: {
        myBlock,
        peInput,
        switcher,
    },

    props: {
        scenarioData: Object,
    },

    mounted() {
        this.scenario = JSON.parse(JSON.stringify(this.scenarioData))
        each(this.scenario.actions, (action) => {
            action.duration = +action.duration;
            each(action.metrics, (m) => {
                m.value = this.reformatMetricValueInverse(m.name, m.value)
            })
        })
    },

    data() {
        return {
            devTypes: devTypesMetrics,
            metricOptions,
            errors:{
                name: null,
            },
            scenario: {
                name: '',
                description: '',
                actions: [
                    {
                        duration: 1,
                        position: 0,
                        metrics: [
                        ]
                    },
                ],
            }
        }
    },

    computed:{
        devTypesOptions(){
            let opts = [];
            each(devTypesMetrics, (d) => {
                opts.push({
                    devTypeTag: d.devTypeTag,
                    label: d.devName,
                })
            })
            return opts;
        },

        sortedActions(){
            return sortBy(this.scenario.actions, 'position')
        }
    },

    methods: {
        reformatMetricValueInverse(m, v){
            if(m === 'dali/0/dim'){
                return  daliToDim(v);
            } else if (m === 'analog/dim'){
                return analogToPercent(v);
            }
            return v;
        },

        getItem() {
            return JSON.parse(JSON.stringify(this.scenario));
        },

        sortedMetrics(metrics){
            return sortBy(metrics, 'position')
        },

        addAction(){
            this.scenario.actions.push({
                duration: 1,
                position: this.scenario.actions.length,
                metrics: [
                    {
                        position: 0,
                        name: "dali/0/dim",
                        value: "100",
                        devTypeTag: "DALI"
                    },
                ]
            })
        },
        
        delAction(action){
            let actions = this.scenario.actions;
            actions.splice(actions.indexOf(action), 1);
            actions = sortBy(actions, 'position');
            for (let i=0; i < actions.length; i++){
                actions[i].position = i;
            }
        },

        addMetric(cmdGroup){
            cmdGroup.metrics.push({
                position: cmdGroup.metrics.length,
                name: "dali/0/dim",
                value: "100",
                devTypeTag: "DALI"
            })
        },

        delMetric(metric, metrics){
            metrics.splice(metrics.indexOf(metric), 1);
            metrics = sortBy(metrics, 'position');
            for (let i=0; i < metrics.length; i++){
                metrics[i].position = i;
            }
        },

        getMetricParams(m){
            let t = filter(devTypesMetrics[m.devTypeTag].metrics, {metric: m.name});
            return t[0];
        },

        wipeBadMetric(m){
            m.name = devTypesMetrics[m.devTypeTag].metrics[0].metric;
            if(this.getMetricParams(m).type === 'bool'){
                m.value = !!m.value;
            }
            else if(this.getMetricParams(m).type === 'number'){
                m.value = isNaN(m.value) ? 1 : +m.value;
            }
        },

        orderMetric(metric, up, metrics){
            let i = metrics.indexOf(metric);
            if(up){
                if(i < 1) return;
                metrics[i-1].position++;
                metrics[i].position--;
            } else {
                if(i > metrics.length - 1) return;
                metrics[i].position++;
                metrics[i+1].position--;
            }
        },
        
        orderAction(action, up){
            let actions = this.scenario.actions;
            let i = actions.indexOf(action);
            if(up){
                if(i < 1) return;
                actions[i-1].position++;
                actions[i].position--;
            } else {
                if(i > actions.length - 1) return;
                actions[i].position++;
                actions[i+1].position--;
            }
        },
    }
}
</script>