import conf from './config'
import _ from 'lodash'
import mqtt from 'mqtt'

export class MqttClient {
    constructor() {
        this._username = '';
        this._password = '';
        this._topics = [];
        this._listeners = {};
    }

    connect(username, password, onConnect){
        this._username = username;
        this._password = password;

        let host = conf.mqtt.host
        // let wsConnType = window.location.protocol === 'https:' ? 'wss' : 'ws'
        let wsConnType = 'wss'
        if (host === 'same') host = window.location.host
        // let port = window.location.protocol === 'https:' ? conf.mqtt.port.wss : conf.mqtt.port.ws;
        let port = conf.mqtt.port.ws;
        console.log(`mqtt connecting to ${wsConnType}://${host}:${port}/mqtt`);
        this.client  = mqtt.connect(`${wsConnType}://${host}:${port}/mqtt`,{
            username: this._username,
            password: this._password,
            clientId: this._username+'_'+Math.round(Math.random()*1000000),
        })

        this.client.on('connect', ()=>{
            if(onConnect){
                onConnect(true)
            }
            console.log('mqtt connected');
            if(this._topics.length){
                this._topics.forEach((t)=>{
                    this.client.subscribe(t, function (err) {
                        if (err) console.error(err);
                    })
                })
            }
        })

        this.client.on('close', ()=>{
            if(onConnect){
                onConnect(false)
            }
        })

        this.client.on('message', (topic, message) => {
            _.forEach(this._listeners, (listener, id)=>{
                if(!listener.topic.test(topic)) return;
                if(listener.params && listener.params.json){
                    message = JSON.parse(message.toString())
                }
                listener.onMessage(listener.topic.exec(topic), message);
                if(listener.params && listener.params.once){
                   delete this._listeners[id]
                }
            })
        })
    }

    subscribe(topic){
        this._topics.push(topic);
        this._topics = _.uniq(this._topics);
        this.client.subscribe(topic);
        console.log('subs to '+topic)
    }
    unsubscribe(topic){
        this._topics = _.remove(this._topics, t => t !== topic);
        this.client.unsubscribe(topic);
    }

    publish(topic, msg){
        this.client.publish(topic, msg)
    }

    /**
     *
     * @param id
     * @param topicRegex
     * @param callback function Принимает 2 параметра - сообщение и топик
     * @param params object {
     * json: true | false,
     * once: true | false - то, что должно отработать только 1 раз. Далее этот listener самоудаляется
     * }
     */
    addListener(id, topicRegex, callback, params){
        this._listeners[id] = {
            topic: new RegExp(topicRegex),
            onMessage: callback,
            params,
        }
    }

    removeListener(id){
        delete this._listeners[id];
    }
}
