<template>
    <div>
        <div class="common-info">
            <div class="info-row">
                <div class="label">Тип устройства:</div>
                <div class="val">{{dev.data.deviceType}}</div>
            </div>

            <div class="info-row">
                <div class="label">Серийный номер:</div>
                <div class="val">{{dev.data.serial}}</div>
            </div>

            <div class="info-row">
                <div class="label">Прошивка:</div>
                <div class="val">{{dev.data.firmwareVersion}} от {{fwDate}}</div>
            </div>
        </div>

        <div style="margin: 15px 0; display: flex; align-items: center;">
            <div style="width: 120px;">Название</div>
            <div style="width: 200px" class="label-input">
                <pe-input v-model="title"></pe-input>
            </div>
        </div>

        <div style="margin-right: 10px">
            <table class="table">
                <thead>
                <tr>
                    <td>Вход</td>
                    <td>Описание</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in 16">
                    <td>№{{i}}</td>
                    <td class="label-input">
                        <pe-input v-model="descriptions['in/' + (i-1)]"></pe-input>
                    </td>
                </tr>
                </tbody>
            </table>

            <div style="text-align: right; margin-top: 15px">
                <button  @click="saveDescriptions()" class="btn green narrow" style="width: 150px">Сохранить</button>
            </div>
        </div>


    </div>
</template>

<style lang="scss">

.ctrl-rows{
  margin-top: 15px;
  .row{
    display: flex;
    align-items: center;
    margin: 10px 0;
    .label{
      width: 120px;
    }
  }
}

.no-border select {
  border: none;
}
</style>

<script>
import dayjs from 'dayjs';
import {each} from "lodash";
import peInput from "../../../../../../components/peInput";
import devicesApi from "../../../../../../api/devices";

export default {
    props:{
        devId: Number,
    },

    components:{
        peInput,
    },

    data(){
        return {
            ctrlVals:{},
            descriptions:{},
        }
    },

    methods:{

        getUpdateTime(i){
            if(this.dev.data && this.dev.data.analogEndpoints){
                return 'Значение получено в ' + dayjs(this.dev.data.analogEndpoints[i].state.timestamp).format('HH:mm:ss DD.MM.YYYY')
            }
            return '';
        },

        _rebindDescriptions(){
            for(let i=0; i < 16; i++){
                let k = 'in/'+i;
                this.$set(this.descriptions, k, this.dev.data.analogEndpoints[i].state.description || '')
            }
            this.title = this.dev.title;
        },

        async saveDescriptions(){
            let data = [];
            each(this.descriptions, (v, k)=>{
                data.push({
                    name: k,
                    value: v,
                })
            })

            await devicesApi.update(this.dev.id, {
                id: this.dev.id,
                title: this.title,
                descriptions: data,
            });

            this.$store.dispatch('deviceReload', this.dev.id);
        },
    },

    watch:{
        dev(){
            this._rebindDescriptions();
        }
    },

    beforeMount() {
        this._rebindDescriptions();
    },

    computed:{
        fwDate(){
            if(this.dev.data && this.dev.data.firmwareDate){
                return dayjs(this.dev.data.firmwareDate).format('DD.MM.YYYY HH:mm:ss')
            }
            return '';
        },

        dev(){
            return this.$store.state.devices.all[this.devId];
        }
    },
}
</script>