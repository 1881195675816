<template>
    <my-block class=""
              @close="$emit('close', true)"
              :top="70"
              :left="10"
              :width="500"
              :height="600"
              title="Параметры астрономического расписания">

        <template slot="default">

            <div class="row-form-inline">
                <div class="title">
                    Широта
                </div>

                <div class="form-input">
                    <pe-input v-model="settings.lat"
                              :error="errors.lat"
                              placeholder="Значение от -90 до 90"></pe-input>
                </div>
                
                <div class="row-form-inline__help">
                    <img src="/images/icons/help.svg" style="height: 24px">
                    <div class="form-input_tt">Значение вида: 37.45464</div>
                </div>
            </div>

            <div class="row-form-inline">
                <div class="title">
                    Долгота
                </div>

                <div class="form-input">
                    <pe-input v-model="settings.lng"
                              :error="errors.lng"
                              placeholder="Значение от -180 до 180"></pe-input>
                </div>

                <div class="row-form-inline__help">
                    <img src="/images/icons/help.svg" style="height: 24px">
                    <div class="form-input_tt">Значение вида: 55.76834</div>
                </div>
            </div>

            <div class="row-form-inline">
                <div class="title">
                    Сдвиг
                </div>

                <div class="form-input">
                    <pe-input v-model="settings.delta"
                              :error="errors.delta"
                              placeholder="Сдвиг в минутах"></pe-input>
                </div>

                <div class="row-form-inline__help">
                    <img src="/images/icons/help.svg" style="height: 24px">
                    <div class="form-input_tt">Значение в <b>минутах</b>. На это значение будет позже включаться и раньше выключаться.</div>
                </div>
            </div>

            <div class="row-form-inline">
                <div class="title">
                    Сценарий для заката
                </div>

                <div class="form-input">
                    <v-select class="no-border"
                              v-model="settings.scenarioOn"
                              :options="scenarios"
                              :reduce="option => option.id"
                              :clearable="false"
                              placeholder="Сценарий для включения"
                    ></v-select>
                </div>

                <div class="row-form-inline__help">
                    <img src="/images/icons/help.svg" style="height: 24px">
                    <div class="form-input_tt">Выбранный сценарий будет применен однократно при закате.</div>
                </div>
            </div>

            <div class="row-form-inline">
                <div class="title">
                    Сценарий для восхода
                </div>

                <div class="form-input">
                    <v-select class="no-border"
                              v-model="settings.scenarioOff"
                              :options="scenarios"
                              :reduce="option => option.id"
                              :clearable="false"
                              placeholder="Сценарий для выключения"
                    ></v-select>
                </div>

                <div class="row-form-inline__help">
                    <img src="/images/icons/help.svg" style="height: 24px">
                    <div class="form-input_tt">Выбранный сценарий будет применен однократно при восходе.</div>
                </div>
            </div>

        </template>

        <template slot="bottom-buttons">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <button  @click="closeEvent" class="btn red narrow" style=" width: 150px">Отмена</button>
                <button  @click="sendCreate" class="btn green narrow" style="width: 150px" :disabled="!formFilled">Создать</button>
            </div>
        </template>
    </my-block>
</template>

<style lang="scss">
@import "src/scss/vars";

.row-form-inline {
    display: flex;
    align-items: center;
    margin: 15px 0;

    .title {
        width: 115px;
        color: #e7e7e7;
    }

    .form-input {
        margin-left: 15px;
        flex-grow: 1;

        .pe-input {
            margin: 0;
        }

        .v-select {
            padding: 5px 0;
        }
    }

    .row-form-inline__help {
        position: relative;
        margin-left: 12px;

        img{
            height: 24px;
            cursor: help;
        }

        .form-input_tt{
            position: absolute;
            right: 0;
            top: 30px;
            width: 220px;
            padding: 7px 12px;
            background: $colorBack;
            border: 1px solid $colorPlaceholder;
            color: #e7e7e7;
            display: none;
            z-index: 999;
        }

        &:hover{
            img{
                opacity: 0.7;
            }
            .form-input_tt{
                display: block;
            }
        }
    }
}
</style>

<script>
import myBlock from '../../../../components/responsiveBlock';
import peInput from "../../../../components/peInput";
import {each, sortBy} from "lodash";
import sunrise from "../../../../sunrise";
import dayjs from "dayjs";
import dayjsUTC from "dayjs/plugin/utc";
import {months} from "../../../../config";

dayjs.extend(dayjsUTC);

export default {

    props: {
        project: Object,
        geoDataLat: Number,
        geoDataLng: Number,
        geoDataDelta: Number,
    },

    components:{
        myBlock,
        peInput,
    },

    data(){
        return {
            settings: {
                lat: null,
                lng: null,
                delta: 20,
                scenarioOn: null,
                scenarioOff: null,
            },

            errors:{
                lat: false,
                lng: false,
                delta: false,
                scenarioOn: false,
                scenarioOff: false,
            }
        }
    },

    mounted() {
        if(this.geoDataLat) this.settings.lat = this.geoDataLat;
        if(this.geoDataLng) this.settings.lng = this.geoDataLng;
        if(this.geoDataDelta) this.settings.delta = this.geoDataDelta;
    },

    computed:{
        scenarios(){
            let arr = [];
            each(this.$store.state.scenarios.all, (s) =>{
                arr.push({
                    id: s.id,
                    label: s.name,
                })
            })
            return sortBy(arr, 'label');
        },

        formFilled(){
            let lat = parseFloat(this.settings.lat);
            let lng = parseFloat(this.settings.lng);
            let delta = parseInt(this.settings.delta);

            if(lat < -90 || lat > 90 || lng < -180 || lng > 180){
                return false;
            }

            if(delta < -120 || delta > 120){
                return false;
            }

            if(!this.settings.scenarioOn || !this.settings.scenarioOff){
                return false;
            }

            return true;
        },
    },

    methods:{
        _check(){
            this.settings.lat = parseFloat(this.settings.lat);
            this.settings.lng = parseFloat(this.settings.lng);
            this.settings.delta = parseInt(this.settings.delta);

            if(this.settings.lat < -90 || this.settings.lat > 90){
                this.errors.lat = 'Значение некорректно';
                return false;
            } else {
                this.errors.lat = false;
            }

            if(this.settings.lng < -180 || this.settings.lng > 180){
                this.errors.lng = 'Значение некорректно';
                return false;
            } else {
                this.errors.lng = false;
            }

            if(this.settings.delta < -120 || this.settings.delta > 120){
                this.errors.delta = 'Значение некорректно';
                return false;
            } else {
                this.errors.delta = false;
            }

            if(!this.settings.scenarioOn){
                this.errors.scenarioOn = 'Выберите сценарий';
                return false;
            } else {
                this.errors.scenarioOn = false;
            }

            if(!this.settings.scenarioOff){
                this.errors.scenarioOff = 'Выберите сценарий';
                return false;
            } else {
                this.errors.scenarioOff = false;
            }

            return true;
        },

        sendCreate(){
            if(!this._check()){
                return;
            }

            /*let date = new Date();
            let res = sunrise.getTimes(date, this.settings.lat, this.settings.lng);
            console.log(res)
            return;*/

            /*
            this.schedule.periods[m.month][d].push({
                        startTime: {
                            hour: 0,
                            minute: 0,
                        },
                        scenarioId: null,
                    })
             */

            let periods = [];

            let checkPeriod = (dateDJ) =>{
                let m = dateDJ.month() + 1;
                let d = dateDJ.date();
                if(periods[m] === undefined){
                    periods[m] = {};
                }
                if(periods[m][d] === undefined){
                    periods[m][d] = [];
                }
            };

            let Y = new Date().getFullYear();
            each(months, (m) => {
                for (let d = 0; d < m.days; d++){
                    let date = new Date(Y, m.month - 1, d + 1);
                    let res = sunrise.getTimes(date, this.settings.lat, this.settings.lng);

                    /*periods[riseM][riseD].push({
                        startTime: {
                            hour: 0,
                            minute: 0,
                        },
                        scenarioId: this.settings.scenarioOn,
                    });*/

                    if(res.sunrise.toString() !== 'Invalid Date'){
                        let sunriseDate = dayjs.utc(res.sunrise).add(this.settings.delta, 'm');
                        checkPeriod(sunriseDate);
                        let riseM = sunriseDate.month() + 1;
                        let riseD = sunriseDate.date();

                        periods[riseM][riseD].push({
                            startTime: {
                                hour: sunriseDate.hour(),
                                minute: sunriseDate.minute(),
                            },
                            scenarioId: this.settings.scenarioOff,
                        });
                    }

                    if(res.sunset.toString() !== 'Invalid Date'){
                        let sundownDate = dayjs.utc(res.sunset).add(-this.settings.delta, 'm');
                        checkPeriod(sundownDate);
                        let downM = sundownDate.month() + 1;
                        let downD = sundownDate.date();

                        periods[downM][downD].push({
                            startTime: {
                                hour: sundownDate.hour(),
                                minute: sundownDate.minute(),
                            },
                            scenarioId: this.settings.scenarioOn,
                        });
                    }
                }
            })

            this.$emit('create-geo', {
                periods,
                geoData: {
                    lat: this.settings.lat,
                    lng: this.settings.lng,
                    delta: this.settings.delta,
                }
            });
        },

        closeEvent(){
            this.$emit('close', true)
        },
    }
}
</script>