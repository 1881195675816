<template>
    <tr>
        <td>
            {{ dev.serial }}
        </td>

        <td :style="`width: ${tblWidth.ax}px`">
            {{ angles.x }}
            <span v-if="angles.x !== '—'">°</span>
        </td>

        <td :style="`width: ${tblWidth.ay}px`">
            {{ angles.y }}
            <span v-if="angles.y !== '—'">°</span>
        </td>

        <td :style="`width: ${tblWidth.az}px`">
            {{ angles.z }}
            <span v-if="angles.z !== '—'">°</span>
        </td>
    </tr>
</template>

<script>
function radToGradus(v){
    return Math.round( v / Math.PI * 1800 ) / 10;
}

export default {
    props: {
        tblWidth: Object,
        dev: Object,
    },

    computed:{
        angles(){
            if(this.hasNoData()){
                return {
                    x: '—',
                    y: '—',
                    z: '—',
                }
            }

            let x = +this.dev.data.sensors.accelerometer.x.value;
            let y = +this.dev.data.sensors.accelerometer.y.value;
            let z = +this.dev.data.sensors.accelerometer.z.value;

            return {
                x: radToGradus(Math.atan(x / (Math.sqrt(y*y + z*z)))),
                y: radToGradus(Math.atan(y / (Math.sqrt(x*x + z*z)))),
                // z: radToGradus(Math.atan((Math.sqrt(x*x + y*y)) / z)),
                z: radToGradus(Math.atan(z / (Math.sqrt(x*x + y*y)))),
            }
        },

        acc(){
            if(this.hasNoData()){
                return {
                    x: '—',
                    y: '—',
                    z: '—',
                }
            }

            let x = +this.dev.data.sensors.accelerometer.x.value;
            let y = +this.dev.data.sensors.accelerometer.y.value;
            let z = +this.dev.data.sensors.accelerometer.z.value;

            return {x, y, z}
        },
    },

    methods: {
        badData(){
            let d = this.dev.data.sensors.accelerometer;
            return !d.y.value && !d.z.value ||  !d.x.value && !d.z.value || !d.x.value && !d.y.value;
        },
        hasNoData() {
            return !this.dev.data ||
                !this.dev.data.sensors ||
                !this.dev.data.sensors.accelerometer ||
                this.dev.data.sensors.accelerometer.x === undefined ||
                this.dev.data.sensors.accelerometer.y === undefined ||
                this.dev.data.sensors.accelerometer.z === undefined ||
                this.badData();
        },

        /*angle(a) {
            if (!this._hasNoData(a)) {
                return '—';
            }

            let v = +this.dev.data.sensors.accelerometer[a].value;
            return Math.round(v);
        }*/
    }
}
</script>