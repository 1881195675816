<template>
    <div class="center-content">

        <div class="block">
            <h3>Регистрация</h3>
            <div class="help-text">Ваш email подтвержден. <br> Можете <router-link to="/login">авторизоваться</router-link> используя его.</div>
        </div>

    </div>
</template>

<style lang="scss" scoped>

    .block{
        width: 450px;
    }

    h3{
        margin-bottom: 40px;
    }

    .help-text{
        font-size: 16px;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.7);
    }

</style>

<script>
    export default {

    }
</script>