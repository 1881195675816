<template>
    <tr>
        <td :style="`width: ${tblWidth.id}px`">
            <template v-if="!isNode">
                <span>----</span>
                <template v-if="selected">
                    <span>{{dev.serial}}</span>
                </template>
                <template v-else>
                    <span class="link-like" @click="$emit('select-dev', dev.id)">{{dev.serial}}</span>
                </template>
            </template>
            <template v-else>
                <div @click.prevent="expandToggle()" class="node-title">
                    <div>{{ dev.serial }}</div>
                    <img class="expanded-ico" :class="{expanded: expanded}" src="/images/icons/arrow-down.svg" alt="">
                </div>
            </template>
        </td>

        <td :style="`width: ${tblWidth.state}px`">
            <div :class="onlineClass"></div>
        </td>

        <td :style="`width: ${tblWidth.errors}px`">Нет</td>

        <td :style="`width: ${tblWidth.temp}px`">
            <template v-if="isNode && dev.data && dev.data.sensors && dev.data.sensors.temperature">
                {{dev.data.sensors.temperature.value}}°С
            </template>
            <template v-else>&mdash;</template>
        </td>

        <td :style="`width: ${tblWidth.fw}px`">
            {{ dev.data && dev.data.firmwareVersion }}
        </td>

        <td :style="`width: ${tblWidth.type}px`">
            <span v-if="isNode">Нода</span>
            <span v-else>
                {{dev.data && dev.data.deviceType}}
                <template v-if="!isNode && dev.data && dev.data.driverType && dev.data.driverType !== 'Unknown'">
                    - {{dev.data.driverType}}
                </template>
            </span>
        </td>

        <td>
            <template v-if="isNode">
                {{ dev.latitude && dev.latitude.toFixed(5) }},
                {{ dev.longitude && dev.longitude.toFixed(5) }}
            </template>
        </td>
    </tr>
</template>

<style lang="scss">
.expanded-ico{
  width: 12px;
  margin-left: 10px;
  &.expanded{
    transform: rotate(180deg);
  }
}

.node-title{
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover{
    color: #a7bdcd;
    transition: color 300ms;
  }
}
</style>

<script>
import {driverTypes} from "../../../../../config";

export default {
    props:{
        tblWidth: Object,
        dev: Object,
        isNode: Boolean,
        expanded: {
            type: Boolean,
            default: true,
        },
        selected: Boolean,
    },

    computed: {
        onlineClass(){
            return this.dev.state.toLowerCase() === 'online' ? 'online' : 'offline';
        }
    },

    methods:{
        expandToggle(){
            this.$emit('expand', !this.expanded);
        }
    }
}
</script>