import Vue from 'vue'
import {
    DEVICE_SET,
    DEVICES_SET,
    DEVICE_UPDATE_PROP,
    DEVICE_SHUNO_RO_SET,
    DEVICE_DIM_SET,
    DEVICE_CAREL_SET, DEVICE_UPDATE_STATUS, DEVICE_SCHEDULE_STATE_SET, DEVICE_LOAD_SET,
} from '../mutations'

import {each} from 'lodash'
import api from '../../api/devices'
import {driverTypes, devTypes, hwDevTypes} from "../../config";

const state = {
    all: {},
    timezones: {},
    carelData:{
        //{"deviceId":514,"eventTime":"2020-12-20T19:23:15.2751661+00:00","parameter":"Temperature","value":20.9}
        temperature: 0,
    }
}

// getters
const getters = {

}

function prepareNode(node, allNodes){
    if(node.nodeSerial === undefined){
        node.childNodes = [];
        node.childNodesId = [];
        each(allNodes, (dev) => {
            if(dev.nodeSerial === node.serial){
                node.childNodes.push(dev);
                node.childNodesId.push(dev.id);
            }
        })
    }

    if(node.data === undefined){
        node.data = {};
    }

    if(!node.scheduleId){
        node.scheduleId = 0;
    }

    if(node.type === devTypes.shuno && node.data.deviceType === hwDevTypes.RO16){
        if(node.releGroupSettings === undefined) node.releGroupSettings = {};
        for(let o = 0; o < 16; o++){
            let p = 'group_id_out_' + o;
            if(node.releGroupSettings[p] === undefined){
                node.releGroupSettings[p] = 0;
            }
        }
    }
}

// actions
const actions = {
    async devicesLoad({commit, dispatch}, projectId){
        try {
            let data = await api.loadByProject(projectId);
            each(data, (node) => {
                prepareNode(node, data)
            })
            commit(DEVICES_SET, data);
        } catch (e) {
            console.error(e)
            if(e.response.status === 401){
                dispatch('logout')
            }
        }
    },

    deviceShunoReleStatusSet({commit}, data){
        commit(DEVICE_SHUNO_RO_SET, data)
    },

    deviceModuleSetDimVal({commit}, data){
        commit(DEVICE_DIM_SET, data)
    },

    deviceModuleSetLoadVal({commit}, data){
        commit(DEVICE_LOAD_SET, data)
    },

    deviceNodeSetSchedulerState({commit}, data){
        commit(DEVICE_SCHEDULE_STATE_SET, data)
    },

    async deviceReload({commit}, devId) {
        let dev = await api.loadItem(devId);
        commit(DEVICE_SET, dev);
    }
}

// mutations
const mutations = {
    [DEVICES_SET] (state, items) {
        state.all = {}
        each(items, d => {
            if(d.descriptions === undefined){
                d.descriptions = {};
            }
            Vue.set(state.all, d.id, d)
        })
    },

    [DEVICE_SET] (state, dev) {
        prepareNode(dev, state.all)
        let prevDev = state.all[dev.id];

        if(prevDev){
            if(prevDev.releGroupSettings){
                dev.releGroupSettings = JSON.parse(JSON.stringify(prevDev.releGroupSettings))
            }
            if(prevDev.groups){
                dev.groups = prevDev.groups;
            }
            if(prevDev.scheduleId){
                dev.scheduleId = prevDev.scheduleId;
            }
        }

        if(dev.descriptions === undefined){
            dev.descriptions = {};
        }

        Vue.set(state.all, dev.id, dev)
    },

    [DEVICE_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

    [DEVICE_UPDATE_STATUS] (state, {serial, status}) {
        each(state.all, d => {
            if(d.serial === serial || d.nodeSerial === serial){
                Vue.set(d, 'state', status)
            }
        })
    },

    [DEVICE_SHUNO_RO_SET] (state, {id, outNum, val}) {
        let dev = state.all[id];
        if (dev && dev.data && dev.data.digitalEndpoints) {
            dev.data.digitalEndpoints[outNum].state.value = !!val;
        }
    },

    [DEVICE_DIM_SET] (state, {id, val}) {
        let dev = state.all[id];
        if (dev && dev.data && dev.data.deviceType === 'NEMA') {
            if(dev.data.driverType === driverTypes.dali && dev.data.daliDriver){
                dev.data.daliDriver.dim.value = +val;
            }
            else if(dev.data.driverType === driverTypes.analog && dev.data.analogDriver){
                dev.data.analogDriver.dim.value = +val;
            }
        }
    },

    [DEVICE_LOAD_SET] (state, {id, val}) {
        let dev = state.all[id];
        if (dev && dev.data && dev.data.deviceType === 'NEMA' && dev.data.load !== undefined) {
            dev.data.load.value = !!val;
        }
    },

    [DEVICE_SCHEDULE_STATE_SET] (state, {id, val}) {
        let dev = state.all[id];
        if (dev && dev.data && dev.data.scheduler && dev.data.scheduler.state !== undefined) {
            dev.data.scheduler.state.value = val ? 'on' : 'off';
        }
    },

    [DEVICE_CAREL_SET] (state, val) {
        state.carelData.temperature = val;
    },

}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
