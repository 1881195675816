import axios from 'axios'

export default {

    logout(){
        return axios.post('/api/account/logout')
    },

    getCurrentUser(onDone, onError){
        return axios.get('/api/account/profile').then((req)=>{
            onDone(req.data)
        }).catch((req)=>{
            onError(req);
        })
    }
}
