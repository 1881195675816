<template>
    <tr>
        <td :style="`width: ${tblWidth.id}px`">
            <span v-if="parentNode">----</span>
            {{ dev.serial }}
        </td>

        <td :style="`width: ${tblWidth.state}px`">
            <div :class="onlineClass"></div>
        </td>

        <td :style="`width: ${tblWidth.errors}px`">Нет</td>

        <td :style="`width: ${tblWidth.type}px`">
            <span v-if="!parentNode">Нода</span>
            <span v-else>
                {{dev.data && dev.data.deviceType}}
                <template v-if="dev.data && dev.data.driverType">
                    - {{dev.data.driverType}}
                </template>

                <span @click="devSetDriverType('analog')"
                      class="set-drv-btn"
                      title="Set Analog driver">A</span>

                <span @click="devSetDriverType('dali')"
                      class="set-drv-btn"
                      title="Set DALI driver">D</span>
            </span>

            <template v-if="!parentNode">
                <img @click="devReboot()" style="margin-left: 10px;"
                     title="Reboot"
                     src="/images/icons/resync.svg"
                     class="ico-btn">

                <img @click="devRebirth()" style="margin-left: 5px; filter: hue-rotate(120deg);"
                     title="Rebirth"
                     src="/images/icons/resync.svg"
                     class="ico-btn">
            </template>
        </td>

        <td :style="`width: ${tblWidth.coordinates}px`">
            <template v-if="!parentNode">
                {{ dev.latitude && dev.latitude.toFixed(5) }},
                {{ dev.longitude && dev.longitude.toFixed(5) }}
                <img @click="resetCoords()"
                     title="Сбросить координаты"
                     src="/images/icons/resync.svg"
                     class="ico-btn">
            </template>
        </td>

        <td>
            <template v-if="!parentNode">
                <div v-for="g in groups">{{g.name}}</div>
            </template>
        </td>

        <td :style="`width: ${tblWidth.del}px`">
            <img @click="$emit('del-dialog', dev)"
                 v-if="deletable"
                 src="/images/icons/close-a.svg" class="ico-btn-color">
        </td>
    </tr>
</template>

<style scoped lang="scss">
td .ico-btn{
    vertical-align: middle;
}
.set-drv-btn{
    margin-left: 5px;
    cursor: pointer;
    color: #98cdff;
    &:hover{
        color: #6685a3;
    }
}
</style>

<script>
import devApi from "../../../../api/devices";
import {DEVICE_UPDATE_PROP} from "../../../../store/mutations";
import {drawDevicesNoGroup} from "../../../../other";
import {devTypes} from "../../../../config";
import {each, filter} from "lodash";

export default {
    props:{
        tblWidth: Object,
        dev: Object,
        parentNode: {
            type: Object,
            optional: true,
        }
    },

    methods:{
        async resetCoords(){
            let loc = this.dev.data.location;
            let resp = await devApi.updateCoords(this.dev.id, loc.latitude.value, loc.longitude.value);

            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: 'latitude',
                val: resp.data.latitude,
            })
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: 'longitude',
                val: resp.data.longitude,
            })
            let devs = filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.module && !d.nodeSerial
            });
            drawDevicesNoGroup(this.$map, devs, false, devTypes.module)
        },

        devReboot(){
            devApi.ctrl(this.dev.id, 'control/reboot', true);
        },

        devSetDriverType(v){
            devApi.ctrlString(this.dev.id, 'driver', v);
        },

        devRebirth(){
            devApi.ctrl(this.dev.id, 'control/rebirth', true);
        },

    },

    computed: {
        onlineClass(){
            return this.dev.state.toLowerCase() === 'online' ? 'online' : 'offline';
        },
        deletable(){
            return !this.parentNode ||
                this.parentNode &&
                this.parentNode.state.toLowerCase() === 'online' &&
                this.dev.state.toLowerCase() !== 'online';
        },

        groups(){
            let arr = [];
            each(this.dev.groups, gId => {
                if(this.$store.state.groups.all[gId]){
                    arr.push(this.$store.state.groups.all[gId])
                }
            })
            return arr;
        },
    }
}
</script>