<template>
    <div>
        <my-block class="schedules-block"
                  @close="$emit('close', true)"
                  :top="70"
                  :left="10"
                  :width="600"
                  :height="540"
                  title="Редактирование расписания">

            <template slot="default">
                <div style="position: absolute; right: 16px;" v-if="schedule.type === scheduleTypes.geo">
                    <img class="ico-btn-color"
                         @click="showCreateGeo = true"
                         src="/images/icons/tools-view-top-service.svg">
                </div>

                <pe-input v-model="title" :error="errors.title"
                          style="margin: 15px 0 20px 0"
                          placeholder="Введите название расписания"></pe-input>

                <edit-analog-year
                        v-if="schedule.type === scheduleTypes.normal"
                        :project="project"
                        :schedule-item="schedule"
                        :title="title"
                        @close="$emit('close', true)"
                        ref="editAnalogYear"></edit-analog-year>

                <edit-geo
                        v-if="schedule.type === scheduleTypes.geo"
                        :project="project"
                        :schedule-item="schedule"
                        :title="title"
                        @close="$emit('close', true)"
                        ref="editGeo"></edit-geo>

                <div class="popup-bg" v-if="popupDel" style="z-index: 9999">
                    <div class="block" style="width: 345px">
                        <div class="title">Вы точно хотите удалить это расписание?</div>
                        <div class="btns">
                            <button class="btn red" type="button" @click="delItem()">Удалить</button>
                            <button class="btn green" @click="popupDel = false">Отмена</button>
                        </div>
                    </div>
                </div>
            </template>

            <template slot="bottom-buttons">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <button @click="popupDel = true" class="btn red narrow" style="width: 150px">Удалить</button>
                    <button @click="updateItem" class="btn green narrow" style=" width: 150px">Сохранить</button>
                </div>
            </template>
        </my-block>

        <sunrise v-if="showCreateGeo"
                 :geo-data-lat="schedule.latitude"
                 :geo-data-lng="schedule.longitude"
                 :geo-data-delta="schedule.shift"
                 @create-geo="createGeo"
                 @close="showCreateGeo = false"></sunrise>
    </div>

</template>

<script>
import editAnalogYear from './edit/analog-year'
import editGeo from './edit/geo-year'
import myBlock from '../../../../components/responsiveBlock';
import peInput from "../../../../components/peInput";
import {each, sortBy} from 'lodash'
import api from "../../../../api/schedules";
import {scheduleTypes, schedulePeriodTypes} from "../../../../config";
import vueSlider from 'vue-slider-component'
import Sunrise from "./sunrise";


export default {

    props: {
        project: Object,
        schedule: Object,
    },

    components: {
        peInput,
        vueSlider,
        myBlock,
        editAnalogYear,
        editGeo,
        Sunrise,
    },

    data() {
        return {
            scheduleTypes,
            schedulePeriodTypes,
            popupDel: false,
            id: 0,
            title: '',
            errors: {
                title: false,
            },

            showCreateGeo: false,
        }
    },

    mounted() {
        this._updateData(this.schedule);
    },

    watch: {
        schedule(newVal) {
            if (newVal) this._updateData(newVal)
        }
    },

    methods: {
        _updateData(schedule) {
            this.id = schedule.id;
            this.title = schedule.title;
        },

        async updateItem() {
            if (!this.title) {
                this.errors.title = 'Заполните название расписания';
                return;
            } else {
                this.errors.title = false;
            }

            if(this.schedule.type === scheduleTypes.geo){
                await this.$refs.editGeo.updateItem();
            } else {
                await this.$refs.editAnalogYear.updateItem();
            }

            await this.$store.dispatch('schedulesLoad', this.project.id);
            this.$emit('close', true);
        },

        async delItem() {
            await api.delete(this.id)
            this.$emit('close', true);
            this.$store.dispatch('schedulesLoad', this.project.id);
        },

        createGeo(data){
            console.log(this.$refs)
            this.tabPeriod = 'geo';
            this.$refs.editGeo.createGeo(data);
            this.showCreateGeo = false;
        },

        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>