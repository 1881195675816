<template>
    <my-block
            :width="650"
            :height="600"
            :top="70"
            :left="10"
            @close="$emit('close', true)"
            title="Создание сценария">

        <template slot="default">
            <inner-form :scenario-data="scenario" ref="scenarioAddInnerForm"></inner-form>
        </template>

        <template slot="bottom-buttons">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <button  @click="$emit('close')" class="btn red narrow" style=" width: 150px">Отмена</button>
                <button  @click="saveItem" class="btn green narrow" style="width: 150px">Сохранить</button>
            </div>
        </template>

    </my-block>

</template>


<script>
import {each, filter} from "lodash";
import myBlock from '../../../../components/responsiveBlock';
import api from '../../../../api/scenarios';
import innerForm from './inner-form';
import {dimToDali, percentToAnalog, analogToPercent, daliToDim} from "../../../../tools";
import {metricDataTypes} from '../../../../config';


export default {

    components: {
        myBlock,
        innerForm,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            scenario: {
                name: '',
                description: '',
                actions: [
                    {
                        duration: 500,
                        position: 0,
                        metrics: [
                            {
                                position: 0,
                                name: "dali/0/dim",
                                value: "90",
                                devTypeTag: "DALI"
                            }
                        ]
                    },
                ],
            }
        }
    },

    methods: {

        reformatMetricValue(m, v){
            if(m === 'dali/0/dim'){
                return  dimToDali(v);
            } else if (m === 'analog/dim'){
                return percentToAnalog(v);
            }
            return v;
        },

        async saveItem(){
            let scenarioData = this.$refs.scenarioAddInnerForm.getItem();
            let data = {
                projectId: this.project.id,
                name: scenarioData.name,
                description: scenarioData.description,
                actions: scenarioData.actions
            }
            each(data.actions, (action) => {
                action.duration = +action.duration;
                each(action.metrics, (m) => {
                    m.dataType = metricDataTypes[m.name];
                    m.value = this.reformatMetricValue(m.name, m.value).toString()
                })
            })
            await api.addToProject(this.project.id, data);
            await this.$store.dispatch('scenariosLoad', this.project.id);
            this.$emit('close');
        },

        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>