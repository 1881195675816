<template>
    <my-block
            :width="400"
            :height="400"
            :top="70"
            :right="10"
            @close="$emit('close', true)"
            title="Статусы углов наклона мачты">

        <template slot="default">
            <table class="table viewDevsTbl" style="position: sticky; top: 0;">
                <thead>
                <tr>
                    <td>ID</td>
                    <td :style="`width: ${tblWidth.ax}px`">Ось X</td>
                    <td :style="`width: ${tblWidth.ay}px`">Ось Y</td>
                    <td :style="`width: ${tblWidth.az}px`">Ось Z</td>
                </tr>
                </thead>
            </table>
            <table class="table viewDevsTbl">
                <tbody>
                <list-item v-for="dev in devices"
                           :tbl-width="tblWidth"
                           :dev="dev"
                           :key="dev.id"></list-item>
                </tbody>
            </table>
        </template>

    </my-block>

</template>


<script>
import {each, filter} from "lodash";
import {devTypes} from "../../../../../config";
import myBlock from '../../../../../components/responsiveBlock';
import listItem from './list-item'

export default {

    components: {
        myBlock,
        listItem,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            tblWidth: {
                id: 210,
                ax: 70,
                ay: 70,
                az: 70,
            }
        }
    },

    computed: {
        devices() {
            return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.module && d.nodeSerial === undefined;
            })
        },
    },

    methods: {
        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>