export const settings = {
    mapboxApiKey: 'pk.eyJ1IjoiaWdvcmQiLCJhIjoiY2s2YXhjdnh1MHN4YTNlcGtueWtjdGIyaCJ9.-cDS0dHthMT1qBdcvtqxOg',
    dadataApiKey: 'd77838b33c884e896a714982a24f81bbcbcc8c4f',
    defaultTimezone: 'Europe/Moscow',
}

export const devTypes = {
    unknown: 'Unknown',
    module: 'Module',
    shuno: 'Shuno',
    baseStation: 'BaseStation',
    PLC: 'PLC',
    vent: 'vent',
}

export const hwDevTypes = {
    Interfaces: 'Interfaces',
    RO16: 'RO16',
    DO16: 'DO16',
    DI16: 'DI16',
    AO16: 'AO16',
    AI16: 'AI16',
}

export const devTypesLang = {
    Unknown: '—',
    Module: 'МУ',
    Shuno: 'ШУНО',
    BaseStation: 'БС',
    PLC: 'ПЛК'
}

export const devStates = {
    offline: 'Offline',
    online: 'Online',
    upgrading: 'Upgrading',
    unknown: 'Unknown'
}

export const scheduleTypes = {
    geo: 'geo',
    normal: 'normal'
}

export const schedulePeriodTypes = {
    week: 'week',
    year: 'year'
}

export const driverTypes = {
    unknown: 'Unknown',
    dali: 'DALI',
    analog: 'Analog',
    pwm: 'PWM',
}

export default {
    mqtt:{
        host: 'mqtt.pelena.cloud',
        port:{
            ws: 8083,
            wss: 8084,
        }
    }
}

/*export const weekDays = {
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
    7: 'Вс',
}*/

export const weekDays = {
    'Monday': 'Пн',
    'Tuesday': 'Вт',
    'Wednesday': 'Ср',
    'Thursday': 'Чт',
    'Friday': 'Пт',
    'Saturday': 'Сб',
    'Sunday': 'Вс',
}

export const months = [
    {label: 'Январь', days: 31, month: 1},
    {label: 'Февраль', days: 28, month: 2},
    {label: 'Март', days: 31, month: 3},
    {label: 'Апрель', days: 30, month: 4},
    {label: 'Май', days: 31, month: 5},
    {label: 'Июнь', days: 30, month: 6},
    {label: 'Июль', days: 31, month: 7},
    {label: 'Август', days: 31, month: 8},
    {label: 'Сентябрь', days: 30, month: 9},
    {label: 'Октябрь', days: 31, month: 10},
    {label: 'Ноябрь', days: 30, month: 11},
    {label: 'Декабрь', days: 31, month: 12},
];

// Unknown, PLC, LC, DO16, DI16, AO16, AI16, RO16, Interfaces, DALI, NEMA
export const devTypesMetrics = {
    RO16: {
        devTypeTag: 'RO16',
        devName: 'DO 16(R)',
        metrics: [
            {metric: 'out/0', name: 'Выход 1', type: 'bool'},
            {metric: 'out/1', name: 'Выход 2', type: 'bool'},
            {metric: 'out/2', name: 'Выход 3', type: 'bool'},
            {metric: 'out/3', name: 'Выход 4', type: 'bool'},
            {metric: 'out/4', name: 'Выход 5', type: 'bool'},
            {metric: 'out/5', name: 'Выход 6', type: 'bool'},
            {metric: 'out/6', name: 'Выход 7', type: 'bool'},
            {metric: 'out/7', name: 'Выход 8', type: 'bool'},
            {metric: 'out/8', name: 'Выход 9', type: 'bool'},
            {metric: 'out/9', name: 'Выход 10', type: 'bool'},
            {metric: 'out/10', name: 'Выход 11', type: 'bool'},
            {metric: 'out/11', name: 'Выход 12', type: 'bool'},
            {metric: 'out/12', name: 'Выход 13', type: 'bool'},
            {metric: 'out/13', name: 'Выход 14', type: 'bool'},
            {metric: 'out/14', name: 'Выход 15', type: 'bool'},
            {metric: 'out/15', name: 'Выход 16', type: 'bool'},
        ]
    },
    DALI: {
        devTypeTag: 'DALI',
        devName: 'МУ DALI',
        metrics: [
            {metric: 'dali/0/dim', name: 'Диммирование', type: 'number', min: 0, max: 100, prefix: '%'},
            {metric: 'dali/0/fade', name: 'Скорость диммирования', type: 'number', min: 0, max: 16},
        ]
    },
    analog: {
        devTypeTag: 'analog',
        devName: 'МУ 0-10В',
        metrics: [
            {metric: 'analog/dim', name: 'Диммирование', type: 'number', min: 0, max: 100, prefix: '%'},
            {metric: 'load', name: 'Вкл/выкл', type: 'bool'},
        ]
    },
    pwm: {
        devTypeTag: 'pwm',
        devName: 'МУ PWM',
        metrics: [
            {metric: 'pwm/dim', name: 'Диммирование', type: 'number', min: 0, max: 100, prefix: '%'},
            {metric: 'pwm/load', name: 'Вкл/выкл', type: 'bool'},
        ]
    },
};

export const metricOptions = {
    'out/0': {devTypeTag: 'RO16', type: 'bool'},
    'out/1': {devTypeTag: 'RO16', type: 'bool'},
    'out/2': {devTypeTag: 'RO16', type: 'bool'},
    'out/3': {devTypeTag: 'RO16', type: 'bool'},
    'out/4': {devTypeTag: 'RO16', type: 'bool'},
    'out/5': {devTypeTag: 'RO16', type: 'bool'},
    'out/6': {devTypeTag: 'RO16', type: 'bool'},
    'out/7': {devTypeTag: 'RO16', type: 'bool'},
    'out/8': {devTypeTag: 'RO16', type: 'bool'},
    'out/9': {devTypeTag: 'RO16', type: 'bool'},
    'out/10': {devTypeTag: 'RO16', type: 'bool'},
    'out/11': {devTypeTag: 'RO16', type: 'bool'},
    'out/12': {devTypeTag: 'RO16', type: 'bool'},
    'out/13': {devTypeTag: 'RO16', type: 'bool'},
    'out/14': {devTypeTag: 'RO16', type: 'bool'},
    'out/15': {devTypeTag: 'RO16', type: 'bool'},

    'dali/0/dim': {devTypeTag: 'DALI', type: 'number', min: 0, max: 254},
    'dali/0/fade': {devTypeTag: 'DALI', type: 'number', min: 0, max: 16},

    'analog/dim': {devTypeTag: 'analog', type: 'number', min: 0, max: 100},
    'load': {devTypeTag: 'analog', type: 'bool'},

    'pwm/dim': {devTypeTag: 'pwm', type: 'number', min: 0, max: 100},
    'pwm/load': {devTypeTag: 'analog', type: 'bool'},
};

export const metricDataTypes = {
    'out/0': 11,
    'out/1': 11,
    'out/2': 11,
    'out/3': 11,
    'out/4': 11,
    'out/5': 11,
    'out/6': 11,
    'out/7': 11,
    'out/8': 11,
    'out/9': 11,
    'out/10': 11,
    'out/11': 11,
    'out/12': 11,
    'out/13': 11,
    'out/14': 11,
    'out/15': 11,

    'dali/0/dim': 5,
    'dali/0/fade': 5,

    'analog/dim': 6,
    'load': 11,

    'pwm/dim': 6,
    'pwm/load': 11,
};

export const AccessRoles = {
    view: 'View',
    edit: 'Edit'
}