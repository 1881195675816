export const USER_SET = 'USER_SET'
export const USER_LOGGED = 'USER_LOGGED'
export const DATA_LOADED = 'DATA_LOADED'

export const MAP_SET_MODE_ADD_GROUP = 'MAP_SET_MODE_ADD_GROUP'
export const PROJECT_MODE_SET = 'PROJECT_MODE_SET'

export const PROJECTS_SET = 'PROJECTS_SET'
export const TIMEZONES_SET = 'TIMEZONES_SET'
export const PROJECT_UPDATE_PROP = 'PROJECT_UPDATE_PROP'

export const GROUPS_SET = 'GROUPS_SET'
export const GROUP_UPDATE_PROP = 'GROUP_UPDATE_PROP'

export const DEVICES_SET = 'DEVICES_SET'
export const DEVICE_SET = 'DEVICE_SET'
export const DEVICE_UPDATE_PROP = 'DEVICE_UPDATE_PROP'
export const DEVICE_UPDATE_STATUS = 'DEVICE_UPDATE_STATUS'
export const DEVICE_SHUNO_RO_SET = 'DEVICE_SHUNO_RO_SET'
export const DEVICE_DIM_SET = 'DEVICE_DIM_SET'
export const DEVICE_LOAD_SET = 'DEVICE_LOAD_SET'
export const DEVICE_SCHEDULE_STATE_SET = 'DEVICE_SCHEDULE_STATE_SET'
export const DEVICE_CAREL_SET = 'DEVICE_CAREL_SET'

export const SCENARIOS_SET = 'SCENARIOS_SET'
export const SCENARIO_SET = 'SCENARIO_SET'
export const SCENARIO_UPDATE_PROP = 'SCENARIO_UPDATE_PROP'

export const SCHEDULES_SET = 'SCHEDULES_SET'
export const SCHEDULES_USE_FROM_CRON = 'SCHEDULES_USE_FROM_CRON'
export const SCHEDULES_SET_ORIGINAL = 'SCHEDULES_SET_ORIGINAL'
export const SCHEDULES_UPDATE_PROP = 'SCHEDULES_UPDATE_PROP'
