<template>
    <my-block
            :width="1100"
            :height="500"
            :top="70"
            :left="10"
            @close="$emit('close', true)"
            title="Логи системы">

        <template slot="default">

            <div id="logs-menu">
                <div class="rounded-tabs">
                    <div class="sw"
                         v-for="(t,k) in chartPeriods"
                         :class="{active: selectedPeriod === k}"
                         @click="selectedPeriod = k"
                    >{{ t }}
                    </div>

                    <div class="sw"
                         :class="{active: selectedPeriod === 'period'}"
                         @click="selectedPeriod = 'period'">
                        <div id="period-selector">
                            <pe-input type="date" v-model="period.start"></pe-input>
                            <div style="margin: 0 10px">&mdash;</div>
                            <pe-input type="date" v-model="period.stop"></pe-input>
                        </div>
                    </div>
                </div>

                <div style="display: flex; align-items: flex-start">
                    <div style="width: 150px; margin-right: 10px">
                        <v-select v-model="selectedNode"
                                  :options="nodes"
                                  :getOptionLabel="option => option.serial"
                                  :clearable="true"
                                  placeholder="Нода">
                        </v-select>
                    </div>

                    <div style="width: 150px">
                        <v-select v-model="selectedDevice"
                                  :options="devices"
                                  :disabled="!selectedNode"
                                  :getOptionLabel="option => option.serial"
                                  :clearable="true"
                                  placeholder="Устройство">
                        </v-select>
                    </div>

                    <a v-if="0" :href="reportUrl" style="margin-left: 15px">
                        <img src="/images/icons/reports.svg">
                    </a>
                </div>

            </div>

            <table class="table">
                <thead>
                <tr>
                    <td>Время события</td>
                    <td>nodeSerial</td>
                    <td>serial</td>
                    <td>messageType</td>
                    <td>topicType</td>
                    <td>Метрики</td>
                </tr>
                </thead>
                <tr v-for="log in logs">
                    <td>{{formatDateTime(log.eventTime)}}</td>
                    <td>{{log.nodeSerial}}</td>
                    <td>{{log.serial}}</td>
                    <td>{{log.messageType}}</td>
                    <td>{{log.topicType}}</td>
                    <td>
                        {{log.metrics}}
                    </td>
                </tr>
            </table>

        </template>

    </my-block>
</template>

<style lang="scss" scoped>
.table thead td {
  font-size: 14px;
}

#logs-menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  background: #262626;
  padding: 0 0 5px 0;
}
</style>



<script>
import myBlock from '../../../../components/responsiveBlock';
import peInput from '../../../../components/peInput';
import axios from "axios";
import moment from "moment";
import {filter} from "lodash";
import {devTypes, hwDevTypes} from "../../../../config";

const chartPeriods = {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'Неделя',
    month: 'Месяц',
};

export default {

    components:{
        myBlock,
        peInput,
    },

    props: {
        project: Object,
    },

    data(){
        return {
            selectedPeriod: 'today',
            selectedNode: null,
            selectedDevice: null,
            chartPeriods,
            period:{
                start: moment().add(-1, 'days').format('YYYY-MM-DD'),
                stop: moment().format('YYYY-MM-DD'),
            },
            logs: []
        }
    },

    mounted() {
        this.refreshLogs();

        /*for(let i=0; i<100; i++){
            this.logs.push({
                id: 0,
                messageType: "string",
                topicType: "string",
                serial: "string",
                nodeSerial: "string",
                eventTime: "2021-07-13T17:53:22.957Z",
                metrics: "string"
            });
        }*/
    },

    computed: {
        nodes() {
            // return filter(this.$store.state.devices.all, {})
            return filter(this.$store.state.devices.all, (d)=>{
                return d.nodeSerial === undefined;
            })
        },
        devices() {
            if(!this.selectedNode) return [];
            return filter(this.$store.state.devices.all, (d)=>{
                return d.nodeSerial === this.selectedNode.serial;
            })
        },
    },

    watch:{
        selectedShuno(v, old){
            this.refreshLogs();
        },
        selectedParameter(v, old){
            this.refreshLogs();
        },
        selectedPeriod(v, old){
            this.refreshLogs();
        },
        "period.start"(v, old){
            this.refreshLogs();
        },
        "period.stop"(v, old){
            this.refreshLogs();
        },
        selectedNode(v, old){
            this.refreshLogs();
        },
        selectedDevice(v, old){
            this.refreshLogs();
        },
    },

    methods:{
        async refreshLogs(){
            let prev, now;
            switch (this.selectedPeriod){
                case "today": prev = moment().add(-(moment().unix()%(24*3600)), 'seconds'); break;
                case "yesterday": prev = moment().add(-1, 'days'); break;
                case "week": prev = moment().add(-7, 'days'); break;
                case "month": prev = moment().add(-30, 'days'); break;
                case "period": prev = moment(this.period.start); break;
                default: prev = moment().add(-1, 'days');
            }
            now = this.selectedPeriod === 'period' ? moment(this.period.stop).add(23*60+59,'minutes') : moment();

            let params = {
                start: prev.toISOString(),
                end: now.toISOString(),
                pageSize: 1000,
                pageIndex: 0,
            };

            if(this.selectedNode){
                params.nodeSerial = this.selectedNode.serial;
            }
            if(this.selectedDevice){
                params.serial = this.selectedDevice.serial;
            }

            let response = await axios.get('/api/v1/logs/',{params});
            this.logs = response.data.data;
        },

        formatDateTime(v){
            return moment(v).format('DD.MM.YYYY HH:mm:ss');
        }
    }
}
</script>