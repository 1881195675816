import VueRouter from "vue-router";
import Vue from 'vue'
Vue.use(VueRouter)

import pageLogin from './pages/auth/login'
import pageRestore from './pages/auth/pwd-restore'
import pageRegDone from './pages/auth/reg-done'
import pageRegMailConfirmed from './pages/auth/mail-confirmed'
import pageReset from './pages/auth/pwd-reset'

import pageBegin from './pages/begin'

import pageAccessList from './pages/access/page'
import pageProjectsList from './pages/projects/list'
import pageProjectsListNo from './pages/projects/no'
import pageProjectsListAdd from './pages/projects/add'

import pageProject from './pages/projects/project'

export const router = new VueRouter({
    routes:[
        { path: '/', component: pageLogin, meta:{ requireGuest: true }},

        { path: '/login', name: 'login', component: pageLogin, meta: { requireGuest: true } },
        { path: '/login/restore', name: 'pwd-restore', component: pageRestore, meta: { requireGuest: true } },
        { path: '/login/registered', name: 'reg-done', component: pageRegDone, meta: { requireGuest: true } },
        { path: '/login/mail-confirmed', name: 'reg-main-confirmed', component: pageRegMailConfirmed, meta: { requireGuest: true } },
        { path: '/login/restore/:token(.+)', name: 'pwd-reset', component: pageReset, meta: { requireGuest: true } },

        { path: '/begin', name: 'begin', component: pageBegin, redirect: '/projects', meta: { requireAuth: true } },

        { path: '/access', name: 'access', component: pageAccessList, meta: { requireAuth: true }},
        { path: '/projects', name: 'projects', component: pageProjectsList, meta: { requireAuth: true }},
        { path: '/projects/:projectId', name: 'projects-view', component: pageProject, meta: { requireAuth: true }},
        { path: '/projects/list-no', name: 'projects-no', component: pageProjectsListNo, meta: { requireAuth: true } },
        { path: '/projects/add', name: 'projects-add', component: pageProjectsListAdd, meta: { requireAuth: true } },
        { path: '/projects/edit/:projectId', name: 'projects-edit', component: pageProjectsListAdd, meta: { requireAuth: true } },

    ]
})


export function redirect(route, loggedIn){
    if(route.meta.requireGuest && loggedIn){
        return { name: 'projects' }
    }
    if(route.meta.requireAuth && !loggedIn){
        return { name: 'login' }
    }
    return false
}
