<template>
    <my-block
            :width="1170"
            :height="400"
            :top="80"
            :left="10"
            @close="$emit('close', true)"
            title="Статусы модулей">

        <template slot="default">
            <table class="table viewDevsTbl" style="position: sticky; top: 0;">
                <thead>
                <tr>
                    <td :style="`width: ${tblWidth.id}px`">ID</td>
                    <td :style="`width: ${tblWidth.state}px`">Статус</td>
                    <td :style="`width: ${tblWidth.errors}px`">Аварии</td>
                    <td :style="`width: ${tblWidth.dim}px`">Диммирование</td>
<!--                    <td :style="`width: ${tblWidth.angle}px`">Угол мачты</td>-->
                    <td :style="`width: ${tblWidth.lux}px`">Lux</td>
                    <td :style="`width: ${tblWidth.temp}px`">Температура</td>
                    <td :style="`width: ${tblWidth.fw}px`">Прошивка</td>
                    <td :style="`width: ${tblWidth.type}px`">Тип модуля</td>
                    <td :style="`width: ${tblWidth.coordinates}px`">Координаты</td>
                    <td>Группы</td>
                </tr>
                </thead>
            </table>
            <table class="table viewDevsTbl">
                <tbody>
                <template v-for="dev in devicesNodes">
                    <list-item :tbl-width="tblWidth"
                               :dev="dev"
                               :key="dev.id"
                               :is-node="true"></list-item>

                    <list-item v-for="d in getParentDevs(dev.serial)"
                               :key="d.id"
                               :tbl-width="tblWidth"
                               :dev="d"
                               :is-node="false"
                    ></list-item>
                </template>

                </tbody>
            </table>
        </template>

    </my-block>

</template>


<script>
import {each, filter} from "lodash";
import {devTypes} from "../../../../config";
import myBlock from '../../../../components/responsiveBlock';
import listItem from './list-item'

export default {

    components: {
        myBlock,
        listItem,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            tblWidth: {
                id: 120,
                state: 40,
                errors: 50,
                dim: 100,
                angle: 40,
                lux: 60,
                temp: 80,
                fw: 120,
                type: 115,
                coordinates: 100,
            }
        }
    },

    computed: {
        devices() {
            return filter(this.$store.state.devices.all, {type: devTypes.module})
        },
        devicesNodes() {
            return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.module && !d.nodeSerial
            })
        },
    },

    methods: {
        getParentDevs(serial) {
            return filter(this.devices, {nodeSerial: serial});
        },
        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>