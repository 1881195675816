<template>
    <my-block
            :width="400"
            :height="400"
            :left="850"
            :top="65"
            :disable-drag="false"
            :title="`Права пользователя ${user.email}`"
            @close="$emit('close', true)">

        <template v-slot:default>
            <div style="border-radius: 5px;">
                <table class="table">
                    <thead>
                    <tr>
                        <td>Проект</td>
                        <td style="width: 90px">Права</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="p in projects">
                        <td>{{p.name}}</td>
                        <td style="text-align: center">
                            <div v-if="p.owner === user.email">
                                Владелец
                            </div>
                            <div v-else class="access-icons">
                                <img :class="{inactive: !userAccess[p.id] || userAccess[p.id] !== AccessRoles.edit}"
                                     @click="setAccess(p.id, AccessRoles.edit)"
                                     src="/images/icons/access/edit.svg" title="Редактирование">

                                <img :class="{inactive: !userAccess[p.id] || userAccess[p.id] !== AccessRoles.view}"
                                     @click="setAccess(p.id, AccessRoles.view)"
                                     src="/images/icons/access/view.svg" title="Просмотр">

                                <img :class="{inactive: userAccess[p.id]}"
                                     @click="setAccess(p.id, null)"
                                     style="margin: 0"
                                     src="/images/icons/access/no-active.svg" title="Без доступа">
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </template>

        <template v-slot:bottom-buttons>
            <div class="btn-pane">
                <button type="button" class="btn green" style="width: 180px"
                        @click="save()">Сохранить</button>
            </div>
        </template>

    </my-block>
</template>

<style lang="scss">
@import "src/scss/vars";
.access-icons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    //margin-right: 15px;
    height: 16px;
    cursor: pointer;
    filter: saturate(2.3) brightness(1);
    &:hover{
      opacity: 0.7;
    }
    &.inactive{
      filter: saturate(0) brightness(0.75);
    }
  }
}
</style>

<script>
    import myBlock from '../../../components/responsiveBlock';
    import axios from 'axios';
    import {sortBy, each} from "lodash";
    import {mapState} from "vuex";
    import {AccessRoles} from "../../../config";


    export default {
        components:{
            myBlock,
        },

        props:{
            user: Object,
        },

        data(){
            return {
                AccessRoles,
                userAccess: {},
            }
        },

        computed:{
            ...mapState({
                projects: state => state.projects.all
            })
        },

        methods:{

            async save(){
                let claims = [];
                each(this.userAccess, (v, k)=>{
                    if(v){
                        claims.push({
                            projectId: k,
                            claim: v
                        })
                    }
                });

                await axios.put('/api/Users/id:' + this.user.id, {
                    id: this.user.id,
                    claims,
                })

                this.$notify({
                    group: 'all',
                    type: 'success',
                    title: 'Сохранено',
                    text: 'Назначение ролей пользователя сохранено!'
                });

                this.$emit('close');
            },

            setAccess(projectId, role){
                this.$set(this.userAccess, projectId, role);
            },

            loadAccountAccess(){
                axios.get('/api/Users/id:' + this.user.id).then((res)=>{
                    let claims = res.data.claims;
                    claims.forEach(c => {
                        this.setAccess(c.projectId, c.claim);
                    })
                });
            }
        },

        mounted() {
            this.loadAccountAccess();
        }
    }
</script>