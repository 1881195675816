<template>
    <my-block
              @close="$emit('close', true)"
              :top="15"
              :right="20"
              :width="400"
              :height="400"
              :no-close="true"
              title="Управление группами">

        <template slot="default">
            <div class="group-row" v-for="g in groups">
                <div class="color" :style="`background: ${g.color}`"></div>
                <div class="title">{{ g.name }}</div>
                <div class="unsync" title="В группе различные значения управления у устройств">
                    <img src="/images/icons/unsync.svg" v-if="isUnsync(g)">
                </div>
                <div class="power-switch">
                    <div class="sw" :class="{active: !isUnsync(g) && getDim(g)}" @click="powerToggle(g, 1)">Вкл
                    </div>
                    <div class="sw" :class="{active: !isUnsync(g) && !getDim(g)}" @click="powerToggle(g, 0)">Выкл</div>
                </div>
<!--                <div style="min-width: 180px; margin-left: 30px">-->
<!--                    <pe-select :value="g.scheduleId" @input="setSchedule($event, g)" class="h32"-->
<!--                               :options="schedules"-->
<!--                               style="margin: 0"-->
<!--                               placeholder="Расписание"></pe-select>-->
<!--                </div>-->
            </div>
        </template>

    </my-block>
</template>

<style lang="scss" scoped>
.group-row {
  margin-right: 10px;
  .unsync {
    width: 16px;
  }
}
</style>

<script>
import {each, filter} from "lodash";
import {GROUP_UPDATE_PROP} from "../../../../store/mutations";
import groupsApi from "../../../../api/groups";
import devicesApi from "../../../../api/devices";
import myBlock from '../../../../components/responsiveBlock';
import {devTypes, scheduleTypes} from "../../../../config";
import peSelect from "../../../../components/peSelect";

export default {
    props: {
        project: Object,
    },

    components: {
        myBlock,
        peSelect,
    },

    data() {
        return {
            dims: {}
        }
    },

    computed: {
        groups() {
            return filter(this.$store.state.groups.all, {groupType: devTypes.shuno})
        },

        devices() {
            return filter(this.$store.state.devices.all, {type:  devTypes.shuno})
        },

        schedules() {
            let options = {
                0: 'Без расписания'
            };
            each(this.$store.state.schedules.all, (s) => {
                options[s.id] = s.title;
            })
            return options;
        },
    },

    watch: {
        groups(groups) {
            if (groups) {
                each(groups, (g) => {
                    this.$set(this.dims, g.id, this.getDim(g))
                })
            }
        }
    },

    mounted() {
        if (this.groups) {
            each(this.groups, (g) => {
                this.$set(this.dims, g.id, this.getDim(g))
            })
        }
    },

    methods: {
        _getReleState(dev, releNum) {
            if (dev && dev.data && dev.data.digitalEndpoints &&
                dev.data.digitalEndpoints[releNum] && dev.data.digitalEndpoints[releNum].state) {
                return dev.data.digitalEndpoints[releNum].state.value;
            }
            return false;
        },
        getDim(g) {
            let v = false;
            each(this.devices, (dev) => {
                if(dev.releGroupSettings === undefined) return;
                for(let i=0; i<16; i++){
                    if(dev.releGroupSettings['group_id_out_'+i] === g.id){
                        if(this._getReleState(dev, i)){
                            v = true;
                        }
                    }
                }
            })
            return v;
        },

        isUnsync(group){
            let v = false;
            let rv = null;
            each(this.devices, (dev) => {
                if(dev.releGroupSettings === undefined || v) return;
                for(let i=0; i<16; i++){
                    if(dev.releGroupSettings['group_id_out_'+i] === group.id){
                        let s = this._getReleState(dev, i);
                        if(rv === null) rv = s;
                        if(rv !== s){
                            v = true;
                        }
                    }
                }
            })
            return v;
        },

        powerToggle(group, onOff) {
            this.devices.forEach((dev) => {
                if(dev.releGroupSettings === undefined) return;
                let metrics = [];
                for(let i=0; i<16; i++){
                    if(dev.releGroupSettings['group_id_out_'+i] === group.id){
                        metrics.push({
                            metric: 'out/'+i,
                            val: !!onOff,
                        })
                    }
                }
                if(metrics.length) devicesApi.ctrlMulti(dev.id, metrics);
            })
        },

        setSchedule(scheduleId, group) {
            scheduleId = parseInt(scheduleId)
            this.$store.commit(GROUP_UPDATE_PROP, {
                id: group.id,
                prop: 'scheduleId',
                val: scheduleId
            })

            if(scheduleId === 0) scheduleId = null;
            groupsApi.update(group.id, {
                id: group.id,
                scheduleId: scheduleId,
            })
        },

        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>