<template>
    <div>
        <div class="common-info">
            <div class="info-row">
                <div class="label">Тип устройства:</div>
                <div class="val">{{dev.data.deviceType}}</div>
            </div>

            <div class="info-row">
                <div class="label">Серийный номер:</div>
                <div class="val">{{dev.data.serial}}</div>
            </div>

            <div class="info-row">
                <div class="label">Прошивка:</div>
                <div class="val">{{dev.data.firmwareVersion}} от {{fwDate}}</div>
            </div>
        </div>

        <div style="margin: 15px 0; display: flex; align-items: center;">
            <div style="width: 120px;">Расписание</div>
            <div style="width: 200px">
                <pe-select :value="dev.schedule_id"
                           @input="setSchedule" class="h32 no-border"
                           :options="schedules"
                           style="margin: 0"
                           placeholder="Выбор расписания"></pe-select>
            </div>
        </div>

        <div style="margin-right: 10px">
            <table class="table">
                <thead>
                <tr>
                    <td>Выход</td>
                    <td>Описание</td>
                    <td>Управление</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in 16">
                    <td>{{i}}</td>
                    <td>{{dev.data.digitalEndpoints[i-1].state.description || '-'}}</td>
                    <td>
                        <switcher v-model="ctrlVals[i-1]" :title="getUpdateTime(i-1)" @change="toggleOut(i-1)"></switcher>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<style lang="scss">

.ctrl-rows{
  margin-top: 15px;
  .row{
    display: flex;
    align-items: center;
    margin: 10px 0;
    .label{
      width: 120px;
    }
  }
}

.no-border select {
  border: none;
}
</style>

<script>
import dayjs from 'dayjs';
import switcher from '../../../../../../components/switch'
import devicesApi from "../../../../../../api/devices";
import {each} from "lodash";
import peSelect from "../../../../../../components/peSelect";

export default {
    props:{
        dev: Object,
    },

    components:{
        switcher,
        peSelect,
    },

    data(){
        return {
            ctrlVals:{},
        }
    },

    methods:{
        _rebindOutVals(){
            for(let i=0; i < 16; i++){
                this.$set(this.ctrlVals, i, this.dev.data.digitalEndpoints[i].state.value)
            }
        },

        toggleOut(outNum){
            devicesApi.ctrl(this.dev.id, 'out/' + outNum, this.ctrlVals[outNum]);
        },

        setSchedule(e){
            console.log(e)
        },

        getUpdateTime(i){
            if(this.dev.data && this.dev.data.digitalEndpoints){
                return 'Значение получено в ' + dayjs(this.dev.data.digitalEndpoints[i].state.timestamp).format('HH:mm:ss DD.MM.YYYY')
            }
            return '';
        },
    },

    watch:{
        dev(){
            this._rebindOutVals()
        }
    },

    beforeMount() {
        this._rebindOutVals()
    },

    computed:{
        fwDate(){
            if(this.dev.data && this.dev.data.firmwareDate){
                return dayjs(this.dev.data.firmwareDate).format('DD.MM.YYYY HH:mm:ss')
            }
            return '';
        },

        schedules() {
            let options = {
                0: 'Без расписания'
            };
            each(this.$store.state.schedules.all, (s) => {
                options[s.id] = s.title;
            })
            return options;
        },
    },
}
</script>