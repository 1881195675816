import Vue from 'vue'
import {
    GROUPS_SET,
    GROUP_UPDATE_PROP,
} from '../mutations'

import {forEach} from 'lodash'
import api from '../../api/groups'

const state = {
    all: {},
    timezones: {},
}

// getters
const getters = {

}

// actions
const actions = {
    async groupsLoad({commit, dispatch}, projectId){
        try {
            let data = await api.loadByProject(projectId);
            commit(GROUPS_SET, data);
        } catch (e) {
            if(e.response.status === 401){
                dispatch('logout')
            }
        }
    },
}

// mutations
const mutations = {
    [GROUPS_SET] (state, items) {
        state.all = {}
        forEach(items, d => {
            if(d.scheduleId === undefined) d.scheduleId = 0;
            Vue.set(state.all, d.id, d)
        })
    },

    [GROUP_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
