<template>
    <div @click="showMenu = false">
        <div id="map"></div>

        <header>
            <div class="head-left-block">&nbsp;</div>

            <router-link to="/" class="head-title">
<!--                <img src="/images/other/logo.svg" alt="logo">-->
            </router-link>

            <div class="head-right-block">
                <img class="ico-btn"
                     v-if="$store.state.user && $store.state.user.role === 'Administrator'"
                     @click="$router.push({name: 'access'})"
                     src="/images/icons/project/b-ico-rights.svg">

                <div v-if="loggedIn" class="clocks">
                    <img src="/images/icons/clock.svg">
                    <div class="clocks-text">
                        {{clocks.time}}<br>
                        {{clocks.date}}
                    </div>
                </div>

                <div class="dropdown-menu" v-if="loggedIn" @click.stop="showMenu = !showMenu">
                    <img class="ico-btn" style="height: 30px" src="/images/icons/profile.svg">
                    <div class="items" v-if="showMenu">
                        <div class="item" @click="logout()">Выйти из системы</div>
                    </div>
                </div>

<!--                <div class="lang-switch">
                    <div class="sw active">Рус</div>
                    <div class="sw">Eng</div>
                </div>-->
            </div>

        </header>

        <div id="cbody">
            <router-view></router-view>
        </div>

        <notifications group="all" position="top left" :width="350" :duration="3500"></notifications>
    </div>
</template>

<style lang="scss">
@import "./src/scss/vars";

.vue-notification {
  font-size: 14px;
  border-radius: 2px;
}

.vue-notification-group{
  margin: 10px;
}

#pashalka{
  height: 2px;
  width: 2px;
  background: #000000;
  position: absolute;
  top: 31px;
  right: 305px;
}

.dropdown-menu{
  position: relative;
  .items{
    position: absolute;
    z-index: 9999;
    bottom: -45px;
    right: 10px;
    width: 170px;
    text-align: left;
    .item{
      padding: 10px 15px;
      background: $colorBlack;
      font-size: 14px;
      border: 1px solid #677480;
      cursor: pointer;
      &:hover{
        color: $colorAcent;
      }
    }
  }
}
</style>

<script>
    import moment from 'moment'
    import {mapState} from 'vuex'
    import {SCHEDULES_USE_FROM_CRON} from "../store/mutations";

    export default {

        data(){
            return {
                clocks:{
                    time: '',
                    date: '',
                },
                showMenu: false,
                showAccessList: true,
            }
        },

        methods:{
            logout(){
                this.$store.dispatch('logout')
            },
        },

        created() {
            let formatTime = ()=>{
                let m = moment()
                this.clocks.time = m.format('HH:mm:ss')
                this.clocks.date = m.format('DD.MM.YYYY')
            }
            formatTime()
            setInterval(formatTime, 1000)
        },

        computed:{
            ...mapState([
                'loggedIn',
            ])
        }

    }
</script>