<template>
    <div>

        <div style="margin-top: 15px">
            <div class="month-day-selector">
                <div class="month-select">
                    <v-select v-model="selectedMonth" class="no-border"
                              :options="months"
                              :clearable="false"
                              :searchable="false"
                              placeholder="Выберите месяц"
                    ></v-select>
                </div>
                <div class="day-select">
                    <div class="month-day"
                         v-for="d in selectedMonth.days"
                         :class="{active: selectedDay === d}"
                         @click="selectedDay = d">{{d}}</div>
                </div>
            </div>
        </div>

        <table class="table periods">
            <thead>
            <tr>
                <td style="width: 16px"></td>
                <td style="width: 105px" @click="sortPeriods()">
                    <div class="resort">
                        Интервал
                        <img class="ico-btn-color" src="/images/icons/sort.svg">
                    </div>
                </td>
                <td>Сценарий</td>
                <td style="width: 16px"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(p, i) in schedule.periods[selectedMonth.month][selectedDay]">
                <td>
                    <img class="ico-btn-color" src="/images/icons/plus.svg" @click="addPeriod(i)">
                </td>

                <td>
                    <div v-if="i === 0" style="padding-left: 5px;">
                        00:00 - {{nextTime(p, i)}}
                    </div>
                    <div v-else style="display: flex">
                        <pe-input style="width: 18px" class="hour-input" :tab-index="2*i"
                                  @keydown="filterNum"
                                  @keyup="filterHour($event, p)"
                                  v-model="p.startTime.hour"></pe-input>
                        <div class="dots">:</div>
                        <pe-input style="width: 18px" :tab-index="2*i + 1"
                                  @keydown="filterNum"
                                  @keyup="filterMinute($event, p)"
                                  v-model="p.startTime.minute"></pe-input>
                        <div>&nbsp;- {{nextTime(p, i)}}</div>
                    </div>
                </td>

                <td>
                    <v-select class="no-border"
                              v-model="p.scenarioId"
                              :options="scenarios"
                              :reduce="option => option.id"
                              :clearable="false"
                              placeholder="Выберите сценарий"
                    ></v-select>
                </td>

                <td>
                    <img v-if="i > 0" @click="delPeriod(i)"
                         class="ico-btn-color" src="/images/icons/minus-r-g.svg">
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
    import peInput from "../../../../../components/peInput";
    import {each, sortBy} from 'lodash'
    import api from "../../../../../api/schedules";
    import {months, scheduleTypes} from "../../../../../config";
    import vueSlider from 'vue-slider-component'

    export default {

        props: {
            project: Object,
            title: String,
        },

        components:{
            peInput,
            vueSlider,
        },

        data(){
            return {
                months,
                selectedMonth: months[0],
                selectedDay: 1,
                sliderOptions:{
                    dotSize: 12,
                    height: 4,
                    min: 0,
                    max: 100,
                    tooltip: 'none',
                },

                schedule:{ periods: {} },

                errors:{
                    title: false,
                    days: false,
                }
            }
        },

        created() {
            months.forEach((m)=>{
                this.$set(this.schedule.periods, m.month, {});
                for(let d = 1; d <= m.days; d++){
                    this.$set(this.schedule.periods[m.month], d, []);
                    this.schedule.periods[m.month][d].push({
                        startTime: {
                            hour: 0,
                            minute: 0,
                        },
                        scenarioId: null,
                    })
                }
            })
        },

        methods:{
            /*createGeo(periods){
                months.forEach((m)=>{
                    this.$set(this.schedule.periods, m.month, {});
                    for(let d = 1; d <= m.days; d++){
                        this.$set(this.schedule.periods[m.month], d, []);
                        this.schedule.periods[m.month][d] = periods[m.month][d];
                    }
                })
            },*/

            async addItem(){
                let hasError = false;

                if(!this.title){
                    this.errors.title = 'Заполние название расписания';
                    hasError = true;
                } else {
                    this.errors.title = false;
                }

                if(hasError) return;

                /*{
                    "id": 0,
                    "title": "string",
                    "projectId": 0,
                     "yearSettings": [{
                        "day": {
                          "day": 0,
                          "month": 0
                        },
                        "startTime": {
                          "hour": 0,
                          "minute": 0
                        },
                        "scenarioId": 123
                      }
                    ],
                }*/
                let data = {
                    id: 0,
                    title: this.title,
                    projectId: this.project.id,
                    type: scheduleTypes.normal,
                    yearSettings: [ ],
                };

                each(this.schedule.periods, (monthData, month) => {
                    each(monthData, (dayData, day) =>{
                        each(dayData, (p) => {
                            data.yearSettings.push({
                                day: {
                                    month: +month,
                                    day: +day,
                                },
                                startTime: p.startTime,
                                scenarioId: p.scenarioId
                            })
                        })
                    })
                })

                await api.addToProject(this.project.id, data);
                this.$store.dispatch('schedulesLoad', this.project.id);
                this.$emit('close', true);
            },

            nextTime(period, index){
                let formatNum = n => n < 10 ? '0' + n.toString() : n.toString();
                let periods = this.schedule.periods[this.selectedMonth.month][this.selectedDay];
                if(index < periods.length - 1){
                    let nextP = periods[index + 1];
                    return formatNum(nextP.startTime.hour) + ':' + formatNum(nextP.startTime.minute);
                } else {
                    return '23:59';
                }
            },

            sortPeriods(){
                let getTime = p => p.startTime.hour*60 + p.startTime.minute;

                let periods = this.schedule.periods[this.selectedMonth.month][this.selectedDay];
                periods = sortBy(periods, getTime)

                for(let i=0; i < periods.length; i++){
                    for(let u = i+1; u < periods.length; u++){
                        if(getTime(periods[i]) === getTime(periods[u])){
                            periods.splice(u, 1);
                            --u;
                        }
                    }
                }
                // немного странно, но это заставляет vue перерисовать
                this.schedule.periods[this.selectedMonth.month][this.selectedDay] = periods;
            },

            addPeriod(i){
                let periods = this.schedule.periods[this.selectedMonth.month][this.selectedDay];
                let p = periods[i];
                periods.splice(i+1, 0, {
                    startTime: {
                        hour: p.startTime.hour,
                        minute: p.startTime.minute,
                    },
                })
            },

            delPeriod(i){
                let periods = this.schedule.periods[this.selectedMonth.month][this.selectedDay];
                periods.splice(i, 1)
            },

            filterNum(e){
                if(!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
                'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'F5', 'Backspace', 'Delete', 'Tab'].includes(e.key)) {
                    e.preventDefault();
                    return false;
                }
            },

            filterHour(e, p){
                let v = parseInt(e.target.value) || 0;
                if(e.key === 'ArrowUp'){
                    e.preventDefault();
                    ++v;
                } else if (e.key === 'ArrowDown'){
                    e.preventDefault();
                    --v;
                }
                if(v < 0) v = 0;
                if(v > 23) v = 23;
                p.startTime.hour = v;
            },

            filterMinute(e, p){
                let v = parseInt(e.target.value) || 0;
                if(e.key === 'ArrowUp'){
                    ++v;
                } else if (e.key === 'ArrowDown'){
                    --v;
                }
                if(v < 0) v = 0;
                if(v > 59) v = 59;
                p.startTime.minute = v;
            },

            toggleDay(d){
                if(this.days.includes(d)){
                    this.days = this.days.filter(v => v !== d)
                } else{
                    this.days.push(d)
                }
            },

            closeEvent(){
                this.$emit('close', true)
            },
        },

        computed:{
            scenarios(){
                let arr = [];
                each(this.$store.state.scenarios.all, (s) =>{
                    arr.push({
                        id: s.id,
                        label: s.name,
                    })
                })
                return sortBy(arr, 'label');
            },
        },
    }
</script>