<template>
    <my-block
              @close="$emit('close', true)"
              :top="15"
              :right="20"
              :width="960"
              :height="400"
              title="Управление группами">

        <template v-slot:default>
            <ctrl-item v-for="g in groups"
                       :project="project"
                       :devices="devices"
                       :schedules="schedules"
                       :group="g"
                       @ctrl-done="reloadGroups"
                       :key="g.id"></ctrl-item>
        </template>

    </my-block>
</template>

<style>
.h32 select {
    padding: 0;
    height: 32px;
}
</style>

<script>
import {each, filter, sortBy} from "lodash";
import myBlock from '../../../../components/responsiveBlock';
import ctrlItem from './list-ctrl-item'

const reloadDataDelay = 10000;

export default {

    props: {
        project: Object,
        groupType: String,
    },

    components: {
        ctrlItem,
        myBlock,
    },

    data() {
        return {
            groupsReloadTimeout: false,
        }
    },

    methods:{
        reloadGroups() {
            if (this.groupsReloadTimeout) clearTimeout(this.groupsReloadTimeout);
            this.groupsReloadTimeout = setTimeout(() => {
                // this.$store.dispatch('groupsLoad', this.project.id)
                this.$store.dispatch('devicesLoad', this.project.id)
            }, reloadDataDelay);
        },
    },

    computed: {
        /*...mapState({
            groups: state => state.groups.all,
            devices: state => state.devices.all,
        }),*/

        groups() {
            return filter(this.$store.state.groups.all, {groupType: this.groupType})
        },

        devices() {
            return filter(this.$store.state.devices.all, {type: this.groupType})
        },

        schedules() {
            let options = {
                0: 'Без расписания'
            };
            each(sortBy(this.$store.state.schedules.all, 'name'), (s) => {
                options[s.id] = s.title;
            })
            return options;
        }
    },

}
</script>