<template>
    <canvas height="50" style="pointer-events: none"></canvas>
</template>

<script>
    import ChartJS from 'chart.js'
    import {sortBy, each} from 'lodash'

    export default {

        props:{
            periods: Array,
        },

        data(){
            return {
                chart: null,
            }
        },

        watch:{
            periods(newVal){
                this.redraw();
            }
        },

        methods:{
            redraw(){
                let data = []
                /*{
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    settings: {
                        dim: 15,
                        fade: 120,
                    }
                },*/
                let periods = sortBy(this.periods, p => p.startTime.hour * 60 + p.startTime.minute)
                let c = periods.length - 1;
                periods.forEach((p, i)=>{
                    if(i < c){
                        let nextP = periods[i+1]
                        data.push({
                            x: p.startTime.hour*60 + p.startTime.minute + ( i > 0 ? p.settings.fade / 60 : 0),
                            y: p.settings.dim
                        })
                        data.push({
                            x: nextP.startTime.hour*60 + nextP.startTime.minute,
                            y: p.settings.dim
                        })
                    } else {
                        data.push({
                            x: p.startTime.hour*60 + p.startTime.minute - p.settings.fade / 60,
                            y: p.settings.dim
                        })
                        data.push({
                            x: 1440,
                            y: p.settings.dim
                        })
                    }
                })

                this.chart = new ChartJS(this.$el.getContext('2d'), {
                    type: 'line',
                    data: {
                        datasets: [{
                            fill: false,
                            lineTension: 0,
                            borderColor: 'rgba(178,201,227,0.7)',
                            borderWidth: 2,
                            label: 'DS',
                            data
                        }]
                    },
                    options: {
                        elements: {
                            point:{
                                radius: 0 // hide markers
                            }
                        },
                        animation: {
                            // duration: 0 // general animation time
                        },
                        legend:{
                            display: false,
                        },
                        layout:{
                            padding: 2
                        },
                        scales: {
                            xAxes: [{
                                type: 'linear',
                                display: false,
                            }],
                            yAxes: [{
                                type: 'linear',
                                display: false,
                            }]
                        }
                    }
                });
            }
        },

        mounted() {
            this.redraw();
        }
    }
</script>