import {each, filter, sortBy} from "lodash";
import mapboxgl from "mapbox-gl";
import devApi from './api/devices';
import {DEVICE_UPDATE_PROP} from './store/mutations'
import {devTypes, driverTypes} from "./config";
import devicesApi from "./api/devices";

export function setErrors(vueErrObj, errResponseData) {
    each(errResponseData, (v, k) => {
        each(vueErrObj, (v, k)=>{
            vueErrObj[k] = ''
        })
        each(errResponseData, (v, k) => {
            if(vueErrObj[k] !== undefined){
                if(Array.isArray(v)){
                    v.forEach((el)=>{
                        vueErrObj[k] += (el.description !== undefined ? el.description : el.toString()) + '\n'
                    })
                } else {
                    vueErrObj[k] = v.toString()
                }
            }
        })
    })
}

export function plural(n, one, two, five) {
    n = Math.abs(n);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

export function makeDraggable(el, onMoveStop) {
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let lastX, lastY;
    let drag = el.querySelector('.header')
    if(!drag) return;
    drag.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        lastX = el.offsetLeft - pos1;
        lastY = el.offsetTop - pos2;
        el.style.left = lastX + "px";
        el.style.top = lastY + "px";
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        if(onMoveStop){
            onMoveStop(lastX, lastY, el)
        }
    }
}


export function drawDevicesNoGroup($map, devs, fitMap = true, devType = devTypes.module){
    let features = [];
    if(devs.length){
        let mapBounds = new mapboxgl.LngLatBounds();
        each(devs, (dev)=>{
            if(dev.nodeSerial) return;
            if(dev.longitude === undefined || dev.latitude === undefined) return;
            mapBounds.extend([dev.longitude, dev.latitude]);

            let serial = '';
            if(dev.data && dev.data.nodeSerial !== undefined){
                serial = dev.data.nodeSerial + ' - ' + dev.data.serial;
            }
            else if (dev.data && dev.data.serial !== undefined){
                serial = dev.data.serial;
            }

            features.push({
                id: dev.id,
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [dev.longitude, dev.latitude]
                },
                properties:{
                    dev,
                    serial,
                }
            })
        })
        if(fitMap){
            let n = mapBounds.getNorth() + 0.001;
            let s = mapBounds.getSouth() - 0.002;
            let e = mapBounds.getEast() + 0.0005;
            let w = mapBounds.getWest() - 0.0005;
            mapBounds.extend([w, n]);
            mapBounds.extend([e, s]);
            $map.fitBounds(mapBounds)
        }
    }

    let layerId = 'points'; // layer and source id
    if ($map.getLayer(layerId)) $map.removeLayer(layerId);
    if ($map.getSource(layerId)) $map.removeSource(layerId);

    let sourceColoredId = 'points-colored';
    if ($map.getLayer(sourceColoredId)) $map.removeLayer(sourceColoredId);
    if ($map.getSource(sourceColoredId)) $map.removeSource(sourceColoredId);

    $map.addSource(layerId, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: features
        }
    })

    let paint = {
        'circle-radius': 12,
        'circle-color': '#f6f6f4',
        'circle-blur': 0.8,
    }
    if(devType === devTypes.shuno){
        paint['circle-stroke-width'] = 3;
        paint['circle-stroke-color'] = '#02a6ff';
        paint['circle-blur'] = 0.1;
        paint['circle-radius'] = 10;
        paint['circle-color'] = '#cdcdcd';
    }
    $map.addLayer({
        id: layerId,
        type: 'circle',
        source: layerId,
        paint
    })

    let draggingDevId = 0;

    $map.on('mouseenter', layerId, (e) => {
        $map.getCanvas().style.cursor = 'pointer';
        if(!draggingDevId){
            let d = document.getElementById('dev-serial-tt');
            if(d){
                d.style.display = 'block';
                d.style.top = (e.point.y + 10) + 'px';
                d.style.left = (e.point.x ) + 'px';
                // d.innerHTML = e.features[0].properties.serial;
                let dev = JSON.parse(e.features[0].properties.dev);
                let status = dev.state.toLowerCase() === 'online' ? `<div class='online i'></div>` : `<div class='offline i'></div>`;
                d.innerHTML = `${status} ${dev.serial}`;
            }
        }
    })

    window.mapBox_noGroupMovingListenersNeedAdd = true;

    if(window.mapBox_noGroupMovingListenersNeedAdd){
        $map.on('mousedown', layerId, (e) => {
            if($map.$vueApp.$store.state.projectMode === 'edit'){
                $map.getCanvas().style.cursor = 'move'
                e.preventDefault();
                draggingDevId = e.features[0].id
            }
            /*let dev = JSON.parse(e.features[0].properties.dev)
            let marker = new mapboxgl.Marker({
                    draggable: true
                })
                .setLngLat([dev.longitude, dev.latitude])
                .addTo($map);
            marker.on('dragend', () => {
                let lngLat = marker.getLngLat();
                console.log('Longitude: ' + lngLat.lng + ' Latitude: ' + lngLat.lat);
            });*/
        })

        $map.on('mousemove', (e)=>{
            if(draggingDevId){
                // console.log('moving-'+draggingDevId)
                e.preventDefault();
                for (let i = 0; i < features.length; i++) {
                    if (features[i].id === draggingDevId) features[i].geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
                }
                $map.getSource(layerId).setData({
                    type: 'FeatureCollection',
                    features: features
                })
            } else  {
                let d = document.getElementById('dev-serial-tt');
                if(d){
                    d.style.top = (e.point.y + 10) + 'px';
                    d.style.left = (e.point.x ) + 'px';
                }
            }
        })

        $map.on('mouseup', (e) => {
            if(draggingDevId) {
                e.preventDefault();
                $map.$vueApp.$store.commit(DEVICE_UPDATE_PROP, {
                    id: draggingDevId,
                    prop: 'latitude',
                    val: e.lngLat.lat,
                })
                $map.$vueApp.$store.commit(DEVICE_UPDATE_PROP, {
                    id: draggingDevId,
                    prop: 'longitude',
                    val: e.lngLat.lng,
                })
                devApi.updateCoords(draggingDevId, e.lngLat.lat, e.lngLat.lng);
                draggingDevId = 0;
            }
        })

        window.mapBox_noGroupMovingListenersNeedAdd = false;
    }


    $map.on('mouseleave', layerId, () => {
        $map.getCanvas().style.cursor = '';
        let d = document.getElementById('dev-serial-tt');
        if(d){
            d.style.display = 'none';
        }
    })
}

export function drawDevicesColorGroup($map, devs, color, devIDs, fitMap = false){
    let features = [];
    let featuresColored = [];
    let mapBounds = new mapboxgl.LngLatBounds();
    if(!Array.isArray(devIDs) || Array.isArray(devIDs) && !devIDs.length) fitMap = false;

    each(devs, (dev)=>{
        if(dev.nodeSerial) return;

        let serial = '';
        if(dev.data && dev.data.nodeSerial !== undefined){
            serial = dev.data.nodeSerial + ' - ' + dev.data.serial;
        }
        else if (dev.data && dev.data.serial !== undefined){
            serial = dev.data.serial;
        }

        let d = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [dev.longitude, dev.latitude]
            },
            properties:{
                dev,
                serial,
            }
        };
        if(Array.isArray(devIDs) && devIDs.includes(dev.id)){
            featuresColored.push(d)
            if(fitMap && dev.longitude !== undefined && dev.latitude !== undefined){
                mapBounds.extend([dev.longitude, dev.latitude]);
            }
        } else {
            features.push(d)
        }
    })

    let sourceBlankId = 'points';
    let sourceColoredId = 'points-colored';

    if ($map.getLayer(sourceBlankId)) $map.removeLayer(sourceBlankId);
    if ($map.getLayer(sourceColoredId)) $map.removeLayer(sourceColoredId);
    if ($map.getSource(sourceBlankId)) $map.removeSource(sourceBlankId);
    if ($map.getSource(sourceColoredId)) $map.removeSource(sourceColoredId);

    $map.addSource(sourceBlankId, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: features
        }
    })
    $map.addSource(sourceColoredId, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: featuresColored
        }
    })

    $map.addLayer({
        id: sourceBlankId,
        type: 'circle',
        source: sourceBlankId,
        paint: {
            'circle-radius': 12,
            'circle-color': '#f6f6f4',
            'circle-blur': 0.8,
        }
    })
    $map.addLayer({
        id: sourceColoredId,
        type: 'circle',
        source: sourceColoredId,
        paint: {
            'circle-radius': 12,
            'circle-color': color,
            'circle-blur': 0.8,
        }
    })

    if(fitMap){
        let n = mapBounds.getNorth() + 0.01;
        let s = mapBounds.getSouth() - 0.02;
        let e = mapBounds.getEast() + 0.0005;
        let w = mapBounds.getWest() - 0.0005;
        mapBounds.extend([w, n]);
        mapBounds.extend([e, s]);
        $map.fitBounds(mapBounds)
    }

    // перенести так, чтобы вызывалось только 1 раз !
    $map.on('mouseenter', sourceColoredId, (e) => {
        $map.getCanvas().style.cursor = 'pointer';
        let d = document.getElementById('dev-serial-tt');
        if(d){
            d.style.display = 'block';
            d.style.top = (e.point.y + 10) + 'px';
            d.style.left = (e.point.x ) + 'px';
            // d.innerHTML = e.features[0].properties.serial;
            let dev = JSON.parse(e.features[0].properties.dev);
            let status = dev.state.toLowerCase() === 'online' ? `<div class='online i'></div>` : `<div class='offline i'></div>`;
            d.innerHTML = `${status} ${dev.serial}`;
        }
    })
    $map.on('mousemove', sourceColoredId, (e) => {
        $map.getCanvas().style.cursor = 'pointer';
        let d = document.getElementById('dev-serial-tt');
        if(d){
            d.style.top = (e.point.y + 10) + 'px';
            d.style.left = (e.point.x ) + 'px';
        }
    })
    $map.on('mouseleave', sourceColoredId, (e) => {
        $map.getCanvas().style.cursor = 'pointer';
        let d = document.getElementById('dev-serial-tt');
        if(d){
            d.style.display = 'none';
        }
    })

}

export function processSchedule(store){
    // console.log(store.schedules.useFrontCron)
    if(!store.schedules.useFrontCron) return;

    const groupCtrl = {
        _getDevDimMetric(dev) {
            if (dev.data.driverType === driverTypes.dali) {
                return 'dali/0/dim';
            } else { // считаем, что все остальные 0-10
                return 'analog/dim';
            }
        },
        _getDimVal(settings){
            let v = 0;
            each(settings, (s) => {
                if(s.name === 'dim' && s.value > 0){
                    v = s.value;
                }
            })
            return Math.round(v * 2.54);
        },
        ctrl(group, settings) {
            let rawVal = this._getDimVal(settings);
            if(group.devices) group.devices.forEach((dev) => {
                if (dev.data.deviceType === 'NEMA') {
                    let metrics = [{
                        metric: this._getDevDimMetric(dev),
                        val: rawVal,
                    }];
                    if(dev.data.driverType === driverTypes.dali){
                        metrics.push({
                            metric: 'dali/0/fade/time',
                            val: 0,
                        })
                    }
                    // console.log(dev.id, JSON.stringify(metrics));
                    devicesApi.ctrlMulti(dev.id, metrics);
                }
            })
        }
    }

    const shunoCtrl = function (dev, outs, onOff) {
        let metrics = [];
        outs.forEach((out)=>{
            metrics.push({
                metric: 'out/'+out,
                val: !!onOff,
            })
        })
        if(metrics.length) devicesApi.ctrlMulti(dev.id, metrics);
    }

    each(store.schedules.originalFormated, (s) => {
        let groups = [];
        let groupIDs = [];
        each(store.groups.all, (g)=>{
            if(g.scheduleId === s.id){
                groups.push(g);
                groupIDs.push(g.id);
            }
        })

        let releDevs = [];
        // {dev: {...}, outs: []}
        each(store.devices.all, (dev) => {
            if(dev.releGroupSettings === undefined) return;
            let outs = [];
            for(let i=0; i<16; i++){
                if(groupIDs.includes(dev.releGroupSettings['group_id_out_'+i])){
                    outs.push(i)
                }
            }
            if(outs.length) releDevs.push({
                dev,
                outs,
            });
        })

        if(groups.length){
            let ts = new Date();
            let currentMinutes = ts.getHours() * 60 + ts.getMinutes();
            if(s.yearSettings !== undefined){
                let periods = filter(s.yearSettings, {
                    day: {
                        month: ts.getMonth()+1,
                        day: ts.getDate()
                    }
                })
                periods = sortBy(periods, p => p.startTime.hour*60 + p.startTime.minute);
                // console.log(JSON.stringify(periods, null, 4))

                let curSettings = periods[0].settings;
                for(let i = 0; i < periods.length; i++){
                    let minutes = periods[i].startTime.hour*60 + periods[i].startTime.minute;
                    if(minutes > currentMinutes) break;
                    curSettings = periods[i].settings;
                }

                // console.log(JSON.stringify(curSettings))
                groups.forEach((g)=>{
                    groupCtrl.ctrl(g, curSettings);
                })

                releDevs.forEach((item)=>{
                    shunoCtrl(item.dev, item.outs, groupCtrl._getDimVal(curSettings));
                })
            }
        }
    })
}