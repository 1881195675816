<template>
    <div class="switcher" :class="{'on': val}" @click="change">
        <div class="center-line"></div>
        <div class="switch-circle"></div>
        <div class="click-area"></div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'true-value', 'false-value'],
        data(){
            return {
                val: false
            }
        },
        methods:{
            change(){
                this.val = !this.val;
                let v;
                if(this.trueValue !== undefined && this.falseValue !== undefined){
                    v = this.val ? this.trueValue : this.falseValue;
                } else {
                    v = this.val;
                }
                this.$emit('input', v)
                this.$emit('change', v)
            }
        },
        watch:{
            value(newVal, prevVal){
                if(this.trueValue !== undefined && this.falseValue !== undefined){
                    this.val = newVal === this.trueValue;
                } else {
                    this.val = newVal;
                }
            }
        },
        created() {
            if(this.value === undefined){
                console.error('no value for material-switcher was set!')
                this.val = false;
            } else {
                if(this.trueValue !== undefined && this.falseValue !== undefined){
                    this.val = this.value === this.trueValue;
                } else {
                    this.val = !!this.value;
                }
            }
        }
    }
</script>
