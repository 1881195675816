import Vue from 'vue'
import Vuex from 'vuex'
import projects from './modules/projects'
import groups from './modules/groups'
import devices from './modules/devices'
import schedules from './modules/schedules'
import scenarios from './modules/scenarios'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

import {
    USER_LOGGED,
    DATA_LOADED,
    USER_SET,
    MAP_SET_MODE_ADD_GROUP,
    PROJECT_MODE_SET,
} from "./mutations";

import userApi from '../api/user'

export default new Vuex.Store({
    modules: {
        projects,
        groups,
        devices,
        schedules,
        scenarios,
    },
    state: {
        user: { },
        loggedIn: false,
        dataLoaded: false,
        mapAddGroupMode: false,
        projectMode: 'view',
    },

    mutations:{
        [USER_SET](state, payload){
            state.user = payload
        },
        [USER_LOGGED](state, payload){
            state.loggedIn = payload
        },
        [DATA_LOADED](state, payload){
            state.dataLoaded = payload
        },
        [MAP_SET_MODE_ADD_GROUP](state, payload){
            state.mapAddGroupMode = payload
        },
        [PROJECT_MODE_SET](state, payload){
            state.projectMode = payload
        },
    },

    getters:{

    },

    actions:{
        getAuthUser({commit, dispatch}){
            return userApi.getCurrentUser((userData)=>{
                commit(USER_LOGGED, true)
                commit(USER_SET, userData)
            }, ()=>{
                commit(USER_LOGGED, false)
            })
        },

        async logout({commit}){
            try{
                await userApi.logout()
                commit(USER_LOGGED, false)
                commit(USER_SET, {})
                window.location.reload()
            } catch (err) {
                console.error(err)
            }
        },

    },

    strict: debug,
})
