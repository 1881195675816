import Vue from 'vue';

import {settings} from './config'

import mapboxgl from 'mapbox-gl'
mapboxgl.accessToken = settings.mapboxApiKey

import store from './store/store'
import { router, redirect } from './routes'

import { mapState } from "vuex"

Vue.prototype.$to = function (routeName, params) {
    this.$router.push({ name: routeName, params: params})
}

import { MqttClient } from './mqttClient'
Vue.prototype.$mqtt = new MqttClient()

import {
    DATA_LOADED,
    DEVICE_SET, DEVICE_UPDATE_STATUS,
} from "./store/mutations";

const signalR = require("@microsoft/signalr");
Vue.prototype.$signalR = new signalR.HubConnectionBuilder()
    .withUrl("/hub/notificationhub")
    .configureLogging(signalR.LogLevel.Information)
    .build();

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import vNotifications from 'vue-notification'
Vue.use(vNotifications)

import {plural, processSchedule} from "./other";
Vue.prototype.$plural = plural;

import {DEVICE_CAREL_SET} from './store/mutations'

let vueApp;

import axios from "axios";
axios.interceptors.response.use(function (response) {
    return response; //пропустим все нормальные 2хх ответы
}, function (error) {
    if(error.response.status === 401){
        if(vueApp.$store.state.loggedIn){
            vueApp.$store.dispatch('logout')
        }
    }
    return Promise.reject(error);
});

import mainPage from './pages/main'
vueApp = new Vue({
    el: '#app',
    store,
    router,

    components:{
        mainPage
    },

    methods:{

    },

    computed:{
        ...mapState([
            'loggedIn',
        ])
    },

    watch:{
        async loggedIn(newVal, oldVal){
            let r = redirect(this.$route, newVal);
            if(r !== false) this.$router.push(r);
            if(newVal === true){
                try {
                    await this.$store.dispatch('projectsLoad')
                    await this.$store.dispatch('timezonesLoad')
                    this.$store.commit(DATA_LOADED, true)
                } catch (e) {
                    if(e.response.status === 401){
                        this.$store.dispatch('logout')
                        this.$store.commit(DATA_LOADED, false)
                    }
                }

                /*this.$store.dispatch('loadUserData').then(()=>{
                    let mu = this.$store.state.mqttUser;
                    this.$mqtt.connect(mu.username, mu.password_visible, (connected)=>{
                        this.$store.commit(MQTT_CONNECTED_SET, connected)
                    })
                })*/
            }
        }
    },

    async created(){
        // переход на нужные маршруты в зависимости от авторизованности
        this.$store.dispatch('getAuthUser').then(()=>{
            let r = redirect(this.$route, this.loggedIn)
            if(r !== false) this.$router.push(r)
        })

        this.$router.beforeEach((to, from, next) => {
            let p = redirect(to, this.loggedIn)
            if(p !== false){
                next(p)
            } else {
                next()
            }
        })

        this.$mqtt.connect('web', 'none', (connected)=>{
            //this.$store.commit(MQTT_CONNECTED_SET, connected)
        })

        await this.$signalR.start();

        this.$signalR.on("DeviceBirth", data => {
            this.$store.commit(DEVICE_UPDATE_STATUS, {serial: data.nodeId, status: 'Online'});
            this.$store.dispatch('needReloadProjectsStat');
        });

        this.$signalR.on("DeviceDeath", data => {
            this.$store.commit(DEVICE_UPDATE_STATUS, {serial: data.nodeId, status: 'Offline'});
            this.$store.dispatch('needReloadProjectsStat');
        });

        this.$signalR.on("DeviceData", (data) => {
            if(data.projectId === +this.$route.params.projectId){
                this.$store.commit(DEVICE_SET, data);
            }
        });

        this.$signalR.on("CarelData", data => {
            if(data.parameter === 'Temperature'){
                this.$store.commit(DEVICE_CAREL_SET, data.value);
            }
        });
    },

    beforeDestroy() {
        this.$signalR.stop();
    },

    mounted() {
        Vue.prototype.$map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [37.215518, 55.984677],
            zoom: 13,
        })
        Vue.prototype.$map.$vueApp = this;

        //// dev select rect drawing START ////////
        let forceMove = false;
        document.addEventListener('keydown', (e)=>{
            if(e.key === ' ') forceMove = true;
        });
        document.addEventListener('keyup', (e)=>{
            if(e.key === ' ') forceMove = false;
        });

        Vue.prototype.$afterSelectModules = {
            _cb: null,
            setCallback(cb){
                this._cb = cb;
            },
            callCallback(v){
                if(this._cb) this._cb(v)
            },
            clearCallback(){
                this._cb = null
            }
        };

        let coordsStart;
        let mouseDown = false;
        const headerHeight = 50;
        let div, x1 = 0, y1 = 0, x2 = 0, y2 = 0;
        function reDraw() {
            let x3 = Math.min(x1, x2);
            let x4 = Math.max(x1, x2);
            let y3 = Math.min(y1, y2);
            let y4 = Math.max(y1, y2);
            div.style.left = x3 + 'px';
            div.style.top = y3 + 'px';
            div.style.width = x4 - x3 + 'px';
            div.style.height = y4 - y3 + 'px';
        }

        this.$map.on('mousedown', (e)=>{
            if(this.$store.state.mapAddGroupMode && !forceMove){
                e.preventDefault();
                div = document.getElementById('selectModulesDiv')
                mouseDown = true;
                coordsStart = e.lngLat;
                div.style.display = 'block'
                x1 = e.originalEvent.clientX;
                y1 = e.originalEvent.clientY - headerHeight;
                x2 = x1;
                y2 = y1;
                reDraw();
            }
        })

        this.$map.on('mousemove', (e)=>{
            if(this.$store.state.mapAddGroupMode && !forceMove){
                if(mouseDown){
                    e.preventDefault();
                    x2 = e.originalEvent.clientX;
                    y2 = e.originalEvent.clientY - headerHeight;
                    reDraw();
                }
            }
        })

        this.$map.on('mouseup', (e)=>{
            if(this.$store.state.mapAddGroupMode && !forceMove){
                e.preventDefault();
                div.style.display = 'none'
                mouseDown = false
                let bounds = new mapboxgl.LngLatBounds();
                bounds.extend(coordsStart);
                bounds.extend(e.lngLat);
                this.$afterSelectModules.callCallback(bounds)
            }
        })

        setInterval(()=> processSchedule(this.$store.state), 5000);

        //// dev select rect drawing END /////////

        /*this.$map.on('load', () => {

            this.$map.loadImage('/images/png/re.png', (error, image) => {
                if (error) throw error

                let w = image.width;
                let h = image.height;
                let canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);
                let imgData = ctx.getImageData(0, 0, w, h);
                console.log(imgData)

                for (let x = 0, offset = 0; x < imgData.data.length / 4; x++) {
                    offset = x * 4
                    imgData.data[offset] = 255; // red
                    imgData.data[offset + 1] = 0; // green
                    imgData.data[offset + 2] = 0; // blue
                    // imgData[offset + 3] = 255; // alpha
                }

               let pulsingDot = {
                    width: image.width,
                    height: image.height,
                    data: new Uint8Array(image.width * image.height * 4),

                    onAdd: function() {
                        let canvas = document.createElement('canvas');
                        canvas.width = image.width
                        canvas.height = image.height
                        this.context = canvas.getContext('2d')
                        this.context.drawImage(image, 0, 0)
                        this.data = ctx.getImageData(0, 0, image.width, image.height).data
                        console.log('init')
                    },

                    render: function() {
                        let duration = 900;
                        let t = (performance.now() % duration) / duration;

                        for (let x = 0, offset = 0; x < this.data.length / 4; x++) {
                            offset = x * 4
                            this.data[offset] = Math.floor(t*255); // red
                            this.data[offset + 1] = Math.floor((1-t)*255); // green
                            this.data[offset + 2] = 0; // blue
                            // imgData[offset + 3] = 255; // alpha
                        }
                        console.log('render')

                        // map.triggerRepaint()

                        return true;
                    }
                }
                this.$map.addImage('pulsing-dot', pulsingDot, )

                this.$map.addImage('chat', imgData)

                this.$map.addSource('imgTest', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [37.6124, 55.670]
                                }
                            }
                        ]
                    }
                });

                this.$map.addLayer({
                    'id': 'imgTest',
                    'type': 'symbol',
                    'source': 'imgTest',
                    layout: {
                        'icon-image': 'pulsing-dot',
                        // 'icon-image': 'chat',
                        'icon-size': 1,
                        'icon-rotate': 30,
                    }
                })
            })

        })//*/

    }
})
