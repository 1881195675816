<template>
    <div class="block movable"
         style="display: flex; align-items: stretch; max-height: 330px">
        <div :style="`width: ${modulesTab ? 55 : 100}%`">
            <div class="header">
                <div class="block-title">Редактирование группы</div>
                <img @click="closeEvent" src="/images/icons/close-a.svg" class="ico-btn-color close">
            </div>

            <div style="display: flex">
                <pe-input v-model="title" :error="errors.title" placeholder="Введите название группы"></pe-input>
                <img src="/images/icons/modules.svg"
                     class="ico-btn-color"
                     style="padding-top: 25px;"
                     v-if="groupType === devTypes.module"
                     @click="modulesTab = !modulesTab"
                     :class="{active: modulesTab}">
            </div>

            <div>Выберете цвет для группы</div>
            <div class="col-selector" :class="`group-type-${groupType}`">
                <div class="color" v-for="col in colors"
                     @click="selectedColor = col" :class="{selected: selectedColor === col}">
                    <div class="inner" :class="{selected: selectedColor === col}"
                         :style="`background: ${col}; box-shadow: 0 0 10px ${col}`"></div>
                </div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 25px;">
                <button  @click="popupDel = true" class="btn red narrow" style=" width: 150px">Удалить</button>
                <button  @click="updateGroup" class="btn green narrow" style=" width: 150px">Сохранить</button>
            </div>
        </div>

        <div v-if="modulesTab" style="margin: -15px 15px; width: 2px; background: #444444"></div>

        <div v-if="modulesTab" style="width: calc(45% - 3px); display: flex; flex-direction: column; align-items: stretch">
            <div class="header" style="flex-grow: 0">
                <div class="block-title">Устройства</div>
                <img @click="modulesTab = false" src="/images/icons/close-a.svg" class="ico-btn-color close">
            </div>
            <div class="devs-block">
                <div class="dev-row" v-for="dev in devices" :key="dev.id" v-if="dev.nodeSerial === undefined">
                    <div class="name">ID {{dev.serial}}</div>
                    <img class="ico-btn-color" src="/images/icons/minus-r-g.svg" @click="removeDev(dev.id)">
                </div>
            </div>
        </div>

        <div class="popup-bg" v-if="popupDel" style="z-index: 9999">
            <div class="block" style="width: 345px">
                <div class="title">Вы точно хотите удалить эту группу?</div>
                <div class="btns">
                    <button class="btn red" type="button" @click="delGroup()">Удалить</button>
                    <button class="btn green" @click="popupDel = false">Отмена</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .pe-input{
        flex-grow: 1;
        margin-top: 10px;
        margin-right: 15px;
        input{
            padding-bottom: 5px;
        }
    }
</style>

<script>
    import peInput from "../../../../components/peInput";
    import groupsApi from "../../../../api/groups";
    import {drawDevicesColorGroup, drawDevicesNoGroup, makeDraggable} from "../../../../other";
    import {MAP_SET_MODE_ADD_GROUP} from "../../../../store/mutations";
    import {each, filter} from 'lodash'
    import {devTypes} from "../../../../config";

    export default {

        props: {
            project: Object,
            group: Object,
            groupType: String,
        },

        components:{
          peInput
        },

        data(){
            return {
                mode: 'add',
                devTypes,
                title:'',
                modulesTab: false,
                popupDel: false,
                colors: [
                    '#FFB904',
                    '#05d903',
                    '#7381FF',
                    '#e03b94',
                    '#2f9651',
                    '#D2FF73',
                    '#3d6eff',
                    '#ff1a51',
                ],
                selectedColor: '#FFB904',
                errors:{
                    title: false,
                },
                devices: {},
            }
        },

        watch:{
            modulesTab(v){
                this.$store.commit(MAP_SET_MODE_ADD_GROUP, v)
                this.$afterSelectModules.setCallback((bounds) => {
                    each(this.devicesAll, (dev)=>{
                        if(dev.type !== this.groupType || dev.nodeSerial !== undefined) return;

                        if(dev.longitude === undefined || dev.latitude === undefined) return;

                        if(bounds.contains([dev.longitude, dev.latitude])){
                            this.$set(this.devices, dev.id, dev)
                        }
                    })
                    this.redrawDevices();
                })
            },
            selectedColor(v){
                this.redrawDevices()
            }
        },

        beforeDestroy() {
            this.$store.commit(MAP_SET_MODE_ADD_GROUP, false);
            this.$afterSelectModules.clearCallback();
        },

        computed:{
            devicesAll(){
                return filter(this.$store.state.devices.all,{ type: this.groupType })
            },
        },

        mounted() {
            makeDraggable(this.$el);
            this.title = this.group.name;
            this.selectedColor = this.group.color;
            each(this.group.devices, (dev)=>{
                this.$set(this.devices, dev.id, dev)
            })
        },

        methods:{
            async updateGroup(){
                if(this.title){
                    this.errors.title = false;
                    await groupsApi.update(this.group.id, {
                        id: this.group.id,
                        name: this.title,
                        color: this.selectedColor
                    })
                    try{
                        let devs = [];
                        each(this.devices, (d) => {
                            devs.push(d.id)
                            if(d.childNodes) d.childNodes.forEach((subDev)=>{
                                devs.push(subDev.id)
                            })
                        })
                        await groupsApi.replaceDevices(this.group.id, devs)
                    } catch (e) {
                        console.error(e)
                    }
                    this.$store.dispatch('groupsLoad', this.project.id);
                    drawDevicesNoGroup(this.$map, this.devicesAll, false);
                    this.$emit('close', true);
                } else {
                    this.errors.title = 'Заполните название группы'
                }
            },

            async delGroup(){
                await groupsApi.delete(this.group.id)
                this.$emit('close', true);
                this.$store.dispatch('groupsLoad', this.project.id);
            },

            redrawDevices(){
                let ids = []
                each(this.devices, (d) => {
                    ids.push(d.id)
                })
                drawDevicesColorGroup(this.$map, this.devicesAll, this.selectedColor, ids)
            },

            removeDev(devId){
                this.$delete(this.devices, devId)
                let dev = this.$store.state.devices.all[devId];
                if(dev) dev.childNodes.forEach((subDev)=>{
                    this.$delete(this.devices, subDev.id)
                })
                // groupsApi.removeDevices(this.group.id, [devId])
                this.redrawDevices()
            },

            closeEvent(){
                drawDevicesNoGroup(this.$map, this.devicesAll, false, this.groupType)
                this.$emit('close', true)
            },

        }
    }
</script>