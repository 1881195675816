<template>
    <div id="dev-data">
        <div class="close-dev-data" @click="$emit('close', true)">
            <img src="/images/icons/close-a.svg" class="ico-btn-color close">
        </div>

        <DevInfoDO16 v-if="dev.data.deviceType === 'DO16'" :dev="dev"></DevInfoDO16>
        <DevInfoRO16 v-if="dev.data.deviceType === 'RO16'" :dev="dev"></DevInfoRO16>
        <DevInfoDI16 v-if="dev.data.deviceType === 'DI16'" :dev="dev"></DevInfoDI16>
        <DevInfoAI16 v-if="dev.data.deviceType === 'AI16'" :dev="dev"></DevInfoAI16>
        <DevInfoInterfaces v-if="dev.data.deviceType === 'Interfaces'" :dev="dev"></DevInfoInterfaces>

    </div>
</template>


<script>
import DevInfoDO16 from './dev/do16.vue'
import DevInfoRO16 from './dev/ro16.vue'
import DevInfoDI16 from './dev/di16.vue'
import DevInfoAI16 from './dev/ai16.vue'
import DevInfoInterfaces from './dev/interfaces'

export default {
    props: {
        dev: Object,
    },

    components:{
        DevInfoDO16,
        DevInfoRO16,
        DevInfoDI16,
        DevInfoAI16,
        DevInfoInterfaces,
    },

    data() {
        return {}
    },

    methods: {}
}
</script>