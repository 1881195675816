<template>
    <my-block
            @close="$emit('close', true)"
            :top="70"
            :left="10"
            :width="900"
            :height="400"
            title="ШУНО">

        <table class="table viewDevsTbl fixed-head">
            <thead>
            <tr>
                <td :style="`width: ${tblWidth.id}px`">ID</td>
                <td :style="`width: ${tblWidth.state}px`">Статус</td>
                <td :style="`width: ${tblWidth.errors}px`">Аварии</td>
                <td :style="`width: ${tblWidth.type}px`">Тип</td>
                <td :style="`width: ${tblWidth.fw}px`">Прошивка</td>
                <td>Координаты</td>
                <td :style="`width: ${tblWidth.del}px`"></td>
            </tr>
            </thead>
        </table>
        <table class="table viewDevsTbl">
            <tbody>
            <template v-for="dev in devicesNodes">
                <tr>
                    <td :style="`width: ${tblWidth.id}px`">
                        {{ dev.serial }}

                        <img @click="devReboot(dev)" style="margin-left: 20px; filter: hue-rotate(220deg);"
                             title="Reboot"
                             src="/images/icons/resync.svg"
                             class="ico-btn">

                        <img @click="devRebirth(dev)" style="filter: hue-rotate(120deg);"
                             title="Rebirth"
                             src="/images/icons/resync.svg"
                             class="ico-btn">
                    </td>
                    <td :style="`width: ${tblWidth.state}px`">
                        <div :class="{online: dev.state.toLowerCase() === 'online', offline: dev.state.toLowerCase() !== 'online'}"></div>
                    </td>
                    <td :style="`width: ${tblWidth.errors}px`">Нет</td>
                    <td :style="`width: ${tblWidth.type}px`">Нода</td>
                    <td :style="`width: ${tblWidth.fw}px`">{{ dev.data && dev.data.firmwareVersion }}</td>
                    <td>{{ dev.latitude && dev.latitude.toFixed(5) }}, {{ dev.longitude && dev.longitude.toFixed(5) }}</td>
                    <td :style="`width: ${tblWidth.del}px`">
                        <img @click="delDialog(dev)" src="/images/icons/close-a.svg" class="ico-btn-color">
                    </td>
                </tr>

                <tr v-for="d in dev.childNodes">
                    <td :style="`width: ${tblWidth.id}px`" >--- {{ d.serial }}</td>
                    <td :style="`width: ${tblWidth.state}px`">
                        <div :class="{online: d.state.toLowerCase() === 'online', offline: d.state.toLowerCase() !== 'online'}"></div>
                    </td>
                    <td :style="`width: ${tblWidth.errors}px`">Нет</td>
                    <td :style="`width: ${tblWidth.type}px`">{{d.data && d.data.deviceType}}</td>
                    <td :style="`width: ${tblWidth.fw}px`">{{ d.data && d.data.firmwareVersion }}</td>
                    <td> </td>
                    <td :style="`width: ${tblWidth.del}px`">
                        <img @click="delDialog(d)"
                             v-if="dev.state.toLowerCase() === 'online' && d.state.toLowerCase() !== 'online'"
                             src="/images/icons/close-a.svg"
                             class="ico-btn-color">
                    </td>
                </tr>
            </template>
            </tbody>
        </table>

        <div class="popup-bg" v-if="popupDel">
            <div class="block" style="width: 345px">
                <div class="title">Вы точно хотите удалить ШУНО {{ delDev.serial }}?</div>
                <div class="btns">
                    <button class="btn red" type="button" @click="delDevice()">Удалить</button>
                    <button class="btn green" @click="popupDel = false">Отмена</button>
                </div>
            </div>
        </div>

    </my-block>
</template>


<script>
import devicesApi from '../../../../api/devices'
import {devTypes} from "../../../../config";
import {filter, each} from "lodash";
import myBlock from '../../../../components/responsiveBlock';
import devApi from "../../../../api/devices";


export default {

    components: {
        myBlock,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            tblWidth: {
                id: 210,
                state: 40,
                errors: 50,
                dim: 95,
                angle: 40,
                temp: 80,
                fw: 80,
                type: 100,
                del: 40,
            },
            delDev: null,
            popupDel: false,
        }
    },

    computed: {
        devices() {
            return filter(this.$store.state.devices.all, {type: devTypes.shuno})
        },
        devicesNodes(){
            return filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.shuno && !d.nodeSerial
            })
        }
    },

    methods: {
      /*  getParentDevs(serial){
            let devs = [];
            each(this.devices, (dev)=>{
                if(dev.nodeSerial === serial) devs.push(dev)
            })
            return devs;
        },*/

        closeEvent() {
            this.$emit('close', true)
        },

        delDialog(dev) {
            this.delDev = dev;
            this.popupDel = true;
        },

        devReboot(dev){
            devApi.ctrl(dev.id, 'control/reboot', true);
        },

        devRebirth(dev){
            devApi.ctrl(dev.id, 'control/rebirth', true);
        },

        async delDevice() {
            if (this.delDev) {
                let waiting = [];
                waiting.push(devicesApi.delete(this.delDev.id));
                if(this.delDev.childNodes) this.delDev.childNodes.forEach( (d)=>{
                    waiting.push(devicesApi.delete(d.id))
                })
                Promise.all(waiting).then(async ()=>{
                    await this.$store.dispatch('devicesLoad', this.project.id)
                    this.popupDel = false;
                })
            }
        }
    }
}
</script>