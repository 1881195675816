<template>
    <my-block
            :width="550"
            :height="400"
            :top="70"
            :left="10"
            @close="$emit('close', true)"
            title="Сценарии">

        <template slot="header-icons">
            <img @click="$emit('show-add')"
                 src="/images/icons/plus.svg"
                 style="height: 24px; margin-right: 15px"
                 class="ico-btn-color">
        </template>

        <template slot="default">

            <div v-if="0" style="text-align: right">
                <button class="btn" style="padding: 6px 20px" @click="$emit('show-add')">
                    Добавить сценарий
                </button>
            </div>

            <table class="table viewDevsTbl" style="position: sticky; top: 0;">
                <thead>
                <tr>
                    <td :style="`width: ${tblWidth.name}px`">Название</td>
                    <td>Описание</td>
                    <td :style="`width: ${tblWidth.delEdit}px`"> </td>
                </tr>
                </thead>
            </table>
            <table class="table viewDevsTbl">
                <tbody>
                <tr v-for="s in scenarios">
                    <td :style="`width: ${tblWidth.name}px`">{{s.name}}</td>
                    <td>{{s.description}}</td>
                    <td :style="`width: ${tblWidth.delEdit}px`">
                        <img @click="delDialog(s)"
                             src="/images/icons/close-a.svg"
                             class="ico-btn-color"
                             title="Удалить">

                        <img @click="$emit('show-edit', s)"
                             src="/images/icons/access/edit.svg"
                             class="ico-btn-color"
                             style="margin-left: 10px"
                             title="Редактирование">
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="popup-bg" v-if="popupDel">
                <div class="block" style="width: 345px">
                    <div class="title">Вы точно хотите удалить сценарий {{ delItem.name }}?</div>
                    <div class="btns">
                        <button class="btn red" type="button" @click="delScenario()">Удалить</button>
                        <button class="btn green" @click="popupDel = false">Отмена</button>
                    </div>
                </div>
            </div>
        </template>

    </my-block>

</template>

<style lang="scss">
.vs__selected{
  background: transparent;
  margin: 4px 10px 0 2px;
}
</style>

<script>
import {each, filter, sortBy} from "lodash";
import myBlock from '../../../../components/responsiveBlock';
import api from '../../../../api/scenarios';

export default {

    components: {
        myBlock,
    },

    props: {
        project: Object,
    },

    data() {
        return {
            tblWidth: {
                name: 200,
                groups: 220,
                delEdit: 50,
            },
            popupDel: false,
            delItem: null,
            /*scenarios: [{
                id: 123,
                name: 'Сценарий 1',
                description: 'Сценарий забитый для теста',
            }]*/
            scenarioGroups: {},
        }
    },

    computed:{
        scenarios(){
            return sortBy(this.$store.state.scenarios.all, 'name');
            // return this.$store.state.scenarios.all;
        },
    },

    watch: {
        scenarios(v, o){
            this._extractGroupsVal();
        }
    },

    mounted() {
        this._extractGroupsVal();
    },

    methods: {

        _extractGroupsVal(){
            each(this.$store.state.scenarios.all, s => {
                this.$set(this.scenarioGroups, s.id, s.groups)
            })
        },

        delDialog(s){
            this.delItem = s;
            this.popupDel = true;
        },

        async delScenario(){
            await api.delete(this.delItem.id);
            await this.$store.dispatch('scenariosLoad', this.project.id);
            this.popupDel = false;
        },

        closeEvent() {
            this.$emit('close', true)
        },
    }
}
</script>