<template>
    <tr>
        <td :style="`width: ${tblWidth.id}px`">
            <template v-if="parentNode">
                <span>----</span>
                <template v-if="selected">
                    <span>{{dev.serial}}</span>
                </template>
                <template v-else>
                    <span class="link-like" @click="$emit('select-dev', dev)">{{dev.serial}}</span>
                </template>
            </template>
            <template v-else>
                <div class="node-title">
                    <div v-if="selected">
                        {{dev.serial}}
                    </div>
                    <div v-else class="link-like" @click="$emit('select-dev', dev)">
                        {{dev.serial}}
                    </div>

                    <img @click.prevent="expandToggle()"
                         class="expanded-ico"
                         :class="{expanded: expanded}"
                         src="/images/icons/arrow-down.svg" alt="">
                </div>
            </template>
        </td>
        <td :style="`width: ${tblWidth.state}px`">
            <div :class="onlineClass"></div>
        </td>
        <td :style="`width: ${tblWidth.errors}px`">Нет</td>
        <td :style="`width: ${tblWidth.type}px`">
            <span v-if="!parentNode">Нода</span>
            <span v-else>
                {{dev.data && dev.data.deviceType}}
                <template v-if="dev.data && dev.data.driverType && dev.data.driverType !== 'Unknown'">
                    - {{dev.data.driverType}}
                </template>
            </span>
        </td>
        <td>
            <template v-if="!parentNode">
                {{ dev.latitude && dev.latitude.toFixed(5) }},
                {{ dev.longitude && dev.longitude.toFixed(5) }}
                <img @click="resetCoords()"
                     title="Сбросить координаты"
                     src="/images/icons/resync.svg"
                     class="ico-btn">
            </template>


            <template v-if="!parentNode">
                <img @click="devReboot()" style="margin-left: 20px; filter: hue-rotate(220deg);"
                     title="Reboot"
                     src="/images/icons/resync.svg"
                     class="ico-btn">

                <img @click="devRebirth()" style="filter: hue-rotate(120deg);"
                     title="Rebirth"
                     src="/images/icons/resync.svg"
                     class="ico-btn">
            </template>
        </td>
        <td :style="`width: ${tblWidth.del}px`">
            <img @click="$emit('del-dialog', dev)"
                 v-if="deletable"
                 src="/images/icons/close-a.svg" class="ico-btn-color">
        </td>
    </tr>
</template>

<style scoped>
td .ico-btn{
    vertical-align: middle;
}
</style>

<script>
import devApi from "../../../../../api/devices";
import {DEVICE_UPDATE_PROP} from "../../../../../store/mutations";
import {drawDevicesNoGroup} from "../../../../../other";
import {devTypes} from "../../../../../config";
import {filter} from "lodash";

export default {
    props:{
        tblWidth: Object,
        dev: Object,
        parentNode: {
            type: Object,
            optional: true,
        },
        expanded: {
            type: Boolean,
            default: true,
        },
        selected: Boolean,
    },

    methods:{
        async resetCoords(){
            let loc = this.dev.data.location;
            let resp = await devApi.updateCoords(this.dev.id, loc.latitude.value, loc.longitude.value);

            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: 'latitude',
                val: resp.data.latitude,
            })
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: 'longitude',
                val: resp.data.longitude,
            })
            let devs = filter(this.$store.state.devices.all, (d) => {
                return d.type === devTypes.module && !d.nodeSerial
            });
            drawDevicesNoGroup(this.$map, devs, false, devTypes.module)
        },

        expandToggle(){
            this.$emit('expand', !this.expanded);
        },


        devReboot(){
            devApi.ctrl(this.dev.id, 'control/reboot', true);
        },

        devRebirth(){
            devApi.ctrl(this.dev.id, 'control/rebirth', true);
        },
    },

    computed: {
        onlineClass(){
            return this.dev.state.toLowerCase() === 'online' ? 'online' : 'offline';
        },

        deletable(){
            return !this.parentNode ||
                this.parentNode &&
                this.parentNode.state.toLowerCase() === 'online' &&
                this.dev.state.toLowerCase() !== 'online';
        },
    }
}
</script>