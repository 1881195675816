<template>
    <my-block
              @close="closeEvent"
              :top="15"
              :right="20"
              :width="500"
              :height="250"
              title="Создание и редактирование групп">

        <template slot="header-icons">
            <img @click="addGroupEvent" src="/images/icons/plus.svg" style="height: 24px; margin-right: 15px" class="ico-btn-color">
        </template>

        <template slot="default">
            <div style="margin-right: 10px">
                <div class="group-row" v-for="g in groups" :class="`group-type-${groupType}`">
                    <div class="color" :style="`background: ${g.color}`"></div>
                    <div class="title" @click="showDevs(g)">{{g.name}}</div>
                    <img @click="editGroupEvent(g)" src="/images/icons/edit-a.svg" class="ico-btn-color">
                </div>
            </div>
        </template>
    </my-block>
</template>

<script>
    import {drawDevicesColorGroup, drawDevicesNoGroup} from "../../../../other";
    import {each, filter} from "lodash";
    import myBlock from '../../../../components/responsiveBlock';

    export default {

        props: {
            project: Object,
            groupType: String,
        },

        components:{
            myBlock,
        },

        data(){
            return {

            }
        },

        computed: {
            groups(){
                return filter(this.$store.state.groups.all,{ groupType: this.groupType })
            },
            devices(){
                return filter(this.$store.state.devices.all,{ type: this.groupType })
            },
        },

        methods:{
            showDevs(group){
                let ids = []
                each(group.devices, (d) => {
                    ids.push(d.id)
                })
                drawDevicesColorGroup(this.$map, this.devices, group.color, ids, true)
            },

            closeEvent(){
                drawDevicesNoGroup(this.$map, this.devices, false, this.groupType)
                this.$emit('close', true)
            },

            editGroupEvent(g){
                drawDevicesNoGroup(this.$map, this.devices, false)
                this.$emit('show-edit', g)
            },

            addGroupEvent(){
                drawDevicesNoGroup(this.$map, this.devices, false)
                this.$emit('show-add')
            },
        }
    }
</script>