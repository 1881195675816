<template>
    <my-block
            :width="1200"
            :height="500"
            :left="10"
            :top="70"
            title="Данные со счетчика"
            @close="closeEvent">

        <template v-slot:default>
            <div style="display: flex; flex-direction: column; height: 100%">

                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="rounded-tabs">
                        <div class="sw"
                             v-for="(t,k) in chartPeriods"
                             :class="{active: selectedPeriod === k}"
                             @click="selectedPeriod = k"
                        >{{ t }}
                        </div>

                        <div class="sw"
                             :class="{active: selectedPeriod === 'period'}"
                             @click="selectedPeriod = 'period'">
                            <div id="period-selector">
                                <pe-input type="date" v-model="period.start"></pe-input>
                                <div style="margin: 0 10px">&mdash;</div>
                                <pe-input type="date" v-model="period.stop"></pe-input>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex; align-items: flex-start">
                        <div style="width: 210px">
                            <v-select v-model="selectedShuno"
                                      :options="devices"
                                      :getOptionLabel="option => option.nodeSerial + ' - ' + option.serial"
                                      :clearable="false"
                                      placeholder="Выберите ШУНО">
                            </v-select>
                        </div>

                        <a :href="reportUrl" style="margin-left: 15px">
                            <img src="/images/icons/reports.svg">
                        </a>
                    </div>

                </div>

                <div style="text-align: center; font-size: 16px; margin: 7px 0 5px; color: #fff;">{{chartTitles[selectedParameter]}}</div>

                <div style="flex-grow: 1">
                    <div style="position: absolute; width: calc(100% - 30px); height: calc(100% - 193px);">
                        <canvas id="meter-chart"></canvas>
                    </div>
                </div>

                <div class="rounded-tabs">
                    <div class="sw"
                         v-for="(t,k) in chartParameters"
                         :class="{active: selectedParameter === k, disabled: !selectedShuno}"
                         @click="selectedParameter = k"
                    >{{ t }}
                    </div>
                </div>
            </div>
        </template>

    </my-block>
</template>

<style lang="scss">
@import "src/scss/vars";

#period-selector{
  display: flex;
  .pe-input{
    margin: 0;
    input {
      border: none;
      border-bottom: 1px solid #353535;
      font-size: 14px;
      line-height: 16px;
      padding: 0;

      &::-webkit-calendar-picker-indicator {
        background: url("/images/icons/calendar.svg") no-repeat;
        opacity: 1;
        display: block;
        width: 16px;
        height: 16px;
        top: 8px;
      }
    }
  }
}

.rounded-tabs {
  display: flex;

  .sw {
    $r: 6px;
    border: 2px solid #444444;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 6px 12px;
    cursor: pointer;

    &.disabled{
      opacity: 0.6;
      cursor: default;
    }

    margin-left: -2px;

    &.active {
      border-color: $colorAcent;
      color: $colorMain;
      z-index: 2;
    }

    &:first-of-type {
      border-bottom-left-radius: $r;
      border-top-left-radius: $r;
      margin-left: 0;
    }

    &:last-of-type {
      border-bottom-right-radius: $r;
      border-top-right-radius: $r;
    }
  }
}
</style>

<script>
import {each, filter, sortBy} from 'lodash'
import myBlock from '../../../../../components/responsiveBlock';
import peSelect from '../../../../../components/peSelect';
import peInput from '../../../../../components/peInput';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import {devTypes, hwDevTypes} from "../../../../../config";

const defaultDevId = 513;
let meterChart;

const chartPeriods = {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'Неделя',
    month: 'Месяц',
};

const chartParameters = {
    u: 'Uф',
    i: 'I',
    p: 'P',
    q: 'Q',
    cos: 'Cos φ',
    angle: '∠',
    freq: 'F',
};

export default {

    props: {
        shuno: Object,
    },

    components: {
        myBlock,
        peSelect,
        peInput,
    },

    data() {
        return {
            selectedShuno: null,
            selectedParameter: 'p', // i, angle, p, q, cos
            selectedPeriod: 'today',
            chartPeriods,
            chartParameters,
            chartTitles: {
                u: 'Напряжение (В)',
                i: 'Сила тока (А)',
                p: 'Активная мощность (Вт)',
                q: 'Реактивная мощность (Вт)',
                cos: 'Косинус угла',
                angle: 'Межфазный угол',
                freq: 'Частота сети (Гц)',
            },
            period:{
                start: moment().add(-1, 'days').format('YYYY-MM-DD'),
                stop: moment().format('YYYY-MM-DD'),
            },
        }
    },

    computed:{
        devices() {
            return filter(this.$store.state.devices.all, {
                type: devTypes.shuno,
                data:{
                    deviceType: hwDevTypes.Interfaces,
                }
            })
        },

        reportUrl(){
            if(!this.selectedShuno){
                return '';
            }

            let prev, now;
            switch (this.selectedPeriod){
                case "today": prev = moment().add(-(moment().unix()%(24*3600)), 'seconds'); break;
                case "yesterday": prev = moment().add(-1, 'days'); break;
                case "week": prev = moment().add(-7, 'days'); break;
                case "month": prev = moment().add(-30, 'days'); break;
                case "period": prev = moment(this.period.start); break;
                default: prev = moment().add(-1, 'days');
            }
            now = this.selectedPeriod === 'period' ? moment(this.period.stop) : moment();
            let qParams = queryString.stringify({
                start: prev.toISOString(),
                end: now.toISOString(),
                parameters: this.selectedParameter,
                id: this.selectedShuno.id,
            })

            return '/api/v1/mercury/report?' + qParams;
        },
    },

    watch:{
        selectedShuno(v, old){
            this.redrawChart();
        },
        selectedParameter(v, old){
            if(!this.selectedShuno){
                return;
            }
            this.redrawChart();
        },
        selectedPeriod(v, old){
            this.redrawChart();
        },
        "period.start"(v, old){
            this.redrawChart();
        },
        "period.stop"(v, old){
            this.redrawChart();
        },
    },

    methods: {
        closeEvent() {
            this.$emit('close', true)
        },

        _makeDatasetsPhases(a, b, c){
            return [
                {
                    label: 'Фаза A',
                    backgroundColor: '#debf00',
                    borderColor: '#debf00',
                    data: a,
                    fill: false,
                    lineTension: 0,
                },
                {
                    label: 'Фаза B',
                    backgroundColor: '#2bbf00',
                    borderColor: '#2bbf00',
                    data: b,
                    fill: false,
                    lineTension: 0,
                },
                {
                    label: 'Фаза C',
                    backgroundColor: '#b41103',
                    borderColor: '#b41103',
                    data: c,
                    fill: false,
                    lineTension: 0,
                },
            ];
        },

        _makeDatasetFreq(a){
            return [
                {
                    label: 'Частота сети',
                    backgroundColor: '#debf00',
                    borderColor: '#debf00',
                    data: a,
                    fill: false,
                    lineTension: 0,
                },
            ];
        },

        drawChart(datasets){
            let ctx = document.getElementById('meter-chart').getContext('2d');
            if(meterChart) meterChart.destroy();
            meterChart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets,
                },
                options: {
                    elements: {
                        point:{
                            radius: 0 // hide markers
                        }
                    },
                    legend: {
                        labels: {
                            fontColor: "#e3e3e3",
                            fontSize: 14
                        }
                    },
                    scales: {
                        xAxes: [{
                            type: 'time',
                            ticks: {
                                fontColor: "#e3e3e3",
                            },
                            scaleLabel: {
                                display: true,
                            },
                            time: {
                                // unit: 'day',
                                displayFormats: {
                                    quarter: 'MMM YYYY',
                                    second: 'HH:mm:ss',
                                    minute: 'HH:mm',
                                    hour: 'DD.MM HH:mm',
                                    day: 'DD.MM.YYYY',
                                }
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                fontColor: "#e3e3e3",
                            },
                            scaleLabel: {
                                display: true,
                            }
                        }]
                    },
                    maintainAspectRatio: 0,
                }
            });
        },

        redrawChart(){
            if(!this.selectedShuno) return;
            let prev, now;
            switch (this.selectedPeriod){
                case "today": prev = moment().add(-(moment().unix()%(24*3600)), 'seconds'); break;
                case "yesterday": prev = moment().add(-1, 'days'); break;
                case "week": prev = moment().add(-7, 'days'); break;
                case "month": prev = moment().add(-30, 'days'); break;
                case "period": prev = moment(this.period.start); break;
                default: prev = moment().add(-1, 'days');
            }
            now = this.selectedPeriod === 'period' ? moment(this.period.stop) : moment();
            axios.get('/api/v1/mercury/',{
                params:{
                    start: prev.toISOString(),
                    end: now.toISOString(),
                    parameters: this.selectedParameter,
                    id: this.selectedShuno.id,
                }
            }).then((resp)=>{
                let data = resp.data[0];
                let datasets;

                if(this.selectedParameter !== 'freq'){
                    //  { t: new Date(), y: v  }
                    let Avals = [];
                    data.a.forEach(row => {
                        Avals.push({ t: new Date(row.registered), y: row.value })
                    })

                    let Bvals = [];
                    data.b.forEach(row => {
                        Bvals.push({ t: new Date(row.registered), y: row.value })
                    })

                    let Cvals = [];
                    data.c.forEach(row => {
                        Cvals.push({ t: new Date(row.registered), y: row.value })
                    })

                    datasets = this._makeDatasetsPhases(Avals, Bvals, Cvals);
                }
                else {
                    let vals = [];
                    data.all.forEach(row => {
                        vals.push({ t: new Date(row.registered), y: row.value })
                    })
                    datasets = this._makeDatasetFreq(vals);
                }

                this.drawChart(datasets);
            })
        },


    },

    mounted() {
       this.redrawChart();
    },

    created() {
        this.selectedShuno = this.$store.state.devices.all[defaultDevId];
    }

}
</script>