<template>
    <tr>
        <td :style="`width: ${tblWidth.id}px`">
            <span v-if="!isNode">----</span>
            {{ dev.serial }}
        </td>
        <td :style="`width: ${tblWidth.state}px`">
            <div :class="onlineClass"></div>
        </td>
        <td :style="`width: ${tblWidth.errors}px`">Нет</td>
        <td :style="`width: ${tblWidth.type}px`">
            <span v-if="isNode">Нода</span>
            <span v-else>{{dev.data && dev.data.deviceType}}</span>
        </td>
        <td :style="`width: ${tblWidth.fw}px`">{{ dev.data && dev.data.firmwareVersion }}</td>
        <td :style="`width: ${tblWidth.coord}px`">
            <template v-if="isNode">
                {{ dev.latitude && dev.latitude.toFixed(5) }},
                {{ dev.longitude && dev.longitude.toFixed(5) }}
            </template>
        </td>
        <td>
            <div v-if="isNode" style="display: flex; align-items: center;">
                <pe-select :value="dev.scheduleId"
                           :disabled="onlineClass !== 'online'"
                           @input="setSchedule($event)"
                           :options="schedules"
                           class="h32"
                           style="margin: 0"
                           placeholder="Выбор расписания"></pe-select>
                <div class="power-switch"
                     v-if="dev.scheduleId"
                     :class="{disabled: !dev.scheduleId || onlineClass !== 'online'}"
                     style="margin-right: 0">
                    <div class="sw" :class="{active: scheduleState}" @click="toggleSchedule(true)">Вкл</div>
                    <div class="sw" :class="{active: !scheduleState}" @click="toggleSchedule(false)">Выкл</div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import {each, sortBy} from "lodash";
import {DEVICE_UPDATE_PROP} from "../../../../../store/mutations";
import devsApi from "../../../../../api/devices";
import peSelect from "../../../../../components/peSelect";
import {mapActions} from "vuex";

export default {
    props:{
        tblWidth: Object,
        dev: Object,
        isNode: Boolean,
    },

    components:{
      peSelect,
    },

    computed: {
        onlineClass(){
            return this.dev.state.toLowerCase() === 'online' ? 'online' : 'offline';
        },

        schedules() {
            let options = {
                0: 'Без расписания'
            };
            // let options = new Map();
            let sch = sortBy(this.$store.state.schedules.all, 'title');
            // console.log(sch);
            each(sch, (s) => {
                options[s.id] = s.title;
                // options.set(s.id, s.title);
            })
            // console.log(options)
            return options;
        },

        scheduleState() {
            return this.dev
                && this.dev.data
                && this.dev.data.scheduler
                && this.dev.data.scheduler.state
                && this.dev.data.scheduler.state.value === 'on';
        },
    },

    methods: {
        ...mapActions([
            'deviceNodeSetSchedulerState',
        ]),

        setSchedule(scheduleId) {
            scheduleId = parseInt(scheduleId)
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: 'scheduleId',
                val: scheduleId
            })
            devsApi.setSchedule(this.dev.id, scheduleId);
            this.$forceUpdate();
        },

        toggleSchedule(v){
            if(this.onlineClass !== 'online') return;
            devsApi.toggleSchedule(this.dev.id, v);
            this.deviceNodeSetSchedulerState({id: this.dev.id, val: v});
            this.$forceUpdate();
        },
    }
}
</script>