<template>
        <my-block
                :width="520"
                :height="400"
                :top="70"
                :right="40"
                @close="$emit('close', true)"
                :title="bs.serial">

            <template slot="default">
                <table class="table viewDevsTbl" style="position: sticky; top: 0;">
                    <thead>
                    <tr>
                        <td>Device</td>
                        <td :style="`width: ${tblWidth.rloc}px`">rLoc16</td>
                        <td :style="`width: ${tblWidth.role}px`">Role</td>
                        <td :style="`width: ${tblWidth.avgRSSI}px`">Avg RSSI</td>
                        <td :style="`width: ${tblWidth.lastRSSI}px`">Last RSSI</td>
                    </tr>
                    </thead>
                </table>
                <table class="table viewDevsTbl">
                    <tbody>
                    <tr v-for="dev in devices">
                        <td>{{getDevId(dev.rloc16)}}</td>
                        <td :style="`width: ${tblWidth.rloc}px`">{{dev.rloc16}}</td>
                        <td :style="`width: ${tblWidth.role}px`">{{dev.role}}</td>
                        <td :style="`width: ${tblWidth.avgRSSI}px`">{{dev.avgRSSI - 2**32}}</td>
                        <td :style="`width: ${tblWidth.lastRSSI}px`">{{dev.lastRSSI - 2**32}}</td>
                    </tr>
                    </tbody>
                </table>
            </template>

        </my-block>
</template>

<script>
import myBlock from '../../../../components/responsiveBlock';
import {each} from 'lodash';

export default {

    components: {
        myBlock,
    },

    props: {
        bs: Object,
        devices: Array,
    },

    data() {
        return {
            tblWidth: {
                rloc: 90,
                role: 90,
                avgRSSI: 60,
                lastRSSI: 60,
            },
        }
    },

    methods:{
        getDevId(rLoc16){
            let devRet = null;
            if(this.$store.state.devices.all){
                each(this.$store.state.devices.all, (dev) => {
                    if(dev.data && dev.data.connection
                        && dev.data.connection.thread
                        && dev.data.connection.thread.rLoc16.value === rLoc16){
                        devRet = dev.serial;
                        return false;
                    }
                })
            }
            return devRet;
        }
    }

}
</script>