<template>
    <div class="block project-item">
        <div class="header">

            <router-link class="title" :to="`/projects/${project.id}`">{{project.name}}</router-link>

            <div class="clocks">
                <img src="/images/icons/clock.svg">
                <div class="clocks-text">
                    {{clocks.time}}<br>
                    {{clocks.date}}
                </div>
            </div>

            <router-link v-if="showEdit"
                    :to="`/projects/edit/${project.id}`">
                <img class="ico-btn" src="/images/icons/edit.svg">
            </router-link>
        </div>

        <div class="items">

            <div class="item" v-for="(typeTitle, itemType) in devTitles">
                <div class="title">{{typeTitle}}</div>
                <div class="radial">
                    <radial-info :size="circleSize"
                                 :dev-data-online="devInfo[itemType].online"
                                 :dev-data-offline="devInfo[itemType].offline"
                                 :dev-data-error="devInfo[itemType].errors"
                    ></radial-info>
                </div>

                <div class="dev-status">
                    <div class="s-ico">
                        <div class="online"></div>
                    </div>
                    <div class="s-title">Онлайн</div>
                    <div class="count">{{devInfo[itemType].online}}</div>
                </div>
                <div class="dev-status">
                    <div class="s-ico">
                        <div class="offline"></div>
                    </div>
                    <div class="s-title">Офлайн</div>
                    <div class="count">{{devInfo[itemType].offline}}</div>
                </div>
                <div class="dev-status">
                    <div class="s-ico">
                        <img src="/images/icons/error.svg">
                    </div>
                    <div class="s-title">Аварии</div>
                    <div class="count">{{devInfo[itemType].errors}}</div>
                </div>
            </div>

        </div>

        <div class="address">
            {{project.country}}, {{project.city}}, {{project.address}}
        </div>

        <div class="address owner">
            Владелец: {{project.owner}}
        </div>

    </div>
</template>

<script>
    import radialInfo from '../../../components/special/project-radial'
    import moment from 'moment-timezone'
    import apiDevs from '../../../api/devices'
    import {AccessRoles, devStates, devTypes} from "../../../config";
    import {PROJECT_UPDATE_PROP} from "../../../store/mutations";

    export default {

        props: {
            project: Object,
        },

        components:{
            radialInfo
        },

        data(){
            return {
                circleSize: 96,
                devInfo:{
                    modules: {
                        online: 1,
                        offline: 1,
                        errors: 1,
                    },
                    shuno: {
                        online: 3,
                        offline: 8,
                        errors: 1,
                    },
                    plc: {
                        online: 5,
                        offline: 2,
                        errors: 3,
                    },
                },
// для редактирования - начало 
                devTitles: {
                    modules: 'МУ',
                    shuno: 'ШУНО',
                    plc: 'ПЛК',
                },
// для редактирования - конец
                clocks:{
                    time:'20:23',
                    date:'15.05.2020',
                },
                timer: false,

            }
        },

        watch:{
            'project.needReload'(val, old){
                if(val){
                    this.loadDevsStat();
                }
            }
        },

        computed:{
            showEdit(){
                if(this.showAdmin){
                    return true;
                }

                let user = this.$store.state.user;
                if(!user || !this.project){
                    return false;
                }

                if(user.email === this.project.owner){
                    return true;
                }

                if(user.projects) for(let el of user.projects){
                    if(el.projectId == this.project.id && el.access === AccessRoles.edit){
                        return true;
                    }
                }

                return false;
            },

            showAdmin(){
                let user = this.$store.state.user;
                if(user && user.role === 'Administrator'){
                    return true;
                }
            },
        },

        methods:{
            refreshTime(){
                let m = moment();
                this.clocks.time = m.tz(this.project.timeZoneId).format('HH:mm')
                this.clocks.date = m.tz(this.project.timeZoneId).format('DD.MM.YYYY')
            },

            async loadDevsStat(){
                let devs = await apiDevs.loadByProject(this.project.id);
                this.$store.commit(PROJECT_UPDATE_PROP, {id: this.project.id, prop: 'needReload', val: false});

                this.devInfo.modules.online = 0;
                this.devInfo.modules.offline = 0;
                this.devInfo.modules.errors = 0;
                this.devInfo.shuno.online = 0;
                this.devInfo.shuno.offline = 0;
                this.devInfo.shuno.errors = 0;
                this.devInfo.plc.online = 0;
                this.devInfo.plc.offline = 0;
                this.devInfo.plc.errors = 0;

                if(devs && devs.length){
                    devs.forEach((d) => {
                        if(d.nodeSerial === undefined){
                            if (d.type === devTypes.module) {
                                if(d.state === devStates.online){
                                    ++this.devInfo.modules.online
                                } else {
                                    ++this.devInfo.modules.offline
                                }
                            }
                            else if (d.type === devTypes.shuno) {
                                if (d.state === devStates.online) {
                                    ++this.devInfo.shuno.online
                                } else {
                                    ++this.devInfo.shuno.offline
                                }
                            }
                            else if (d.type === devTypes.PLC) {
                                if (d.state === devStates.online) {
                                    ++this.devInfo.plc.online
                                } else {
                                    ++this.devInfo.plc.offline
                                }
                            }
                        }
                    })
                }
            }
        },

        async mounted() {
            this.refreshTime()
            this.timer = setInterval(()=>{
                this.refreshTime()
            }, 10000)

            this.loadDevsStat();
        },

        beforeDestroy() {
            clearInterval(this.timer)
        }
    }

</script>
