<template>
    <div class="center-content">
        <div class="block alert-block hide" :class="{show: alertShow}">{{alertText}}</div>

        <div class="block">

            <div class="face-title">Выберите  интерфейс для работы</div>

            <div class="face-select">

                <div class="face-item" @click="$to('projects')">
                    <img src="/images/icons/face-geo.svg">
                    <div class="sub-title">Гео</div>
                    <div class="descr">
                        Основной рабочей областью является карта
                    </div>
                </div>

                <div class="face-item" @click="showAlert('План')">
                    <img src="/images/icons/face-plan.svg">
                    <div class="sub-title">План</div>
                    <div class="descr">
                        Основной рабочей областью является план помещения
                    </div>
                </div>

                <div class="face-item" @click="showAlert('Комбо')">
                    <img class="face-ico" src="/images/icons/face-combo.svg">
                    <div class="sub-title">Комбо</div>
                    <div class="descr">
                        Данный тип сочетает в себе две рабочие области: карта и план
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>

<style lang="scss" scoped>
@import "src/scss/vars";

    $w: 800px;
    .block{
        width: $w;
        font-weight: 600;
        box-sizing: border-box;
    }

    .alert-block{
        position: absolute;
        z-index: 999;
        top: 0;
        left: calc(50% - #{$w} / 2);
        padding: 30px 50px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        color: #B2C9E3;
        transition: opacity 300ms;
        &.hide{
            opacity: 0;
        }
        &.show{
            opacity: 1;
        }
    }

    .face-title{
        margin: 10px 0 70px 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        opacity: 0.9;
        color: $colorMain;
    }

    .face-select{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .face-item{
        text-align: center;
        width: 160px;
        cursor: pointer;
        color: $colorMain;

        $trTime: 200ms;

        img{
            height: 45px;
            opacity: 0.75;
            filter: saturate(0);
            transition: $trTime;
        }

        .sub-title{
            opacity: 0.5;
            font-size: 18px;
            line-height: 25px;
            margin: 10px 0 15px 0;
            transition: opacity $trTime;
        }

        .descr{
            opacity: 0.4;
            font-size: 15px;
            line-height: 20px;
            transition: opacity $trTime;
        }

        &:hover{
            .sub-title{
                opacity: 0.9;
            }
            .descr{
                opacity: 0.7;
            }
            img{
                opacity: 1;
                filter: saturate(1);
            }
        }
    }
</style>

<script>
    export default {
        data(){
            return {
                alertShow: false,
                alertText: '',
                timers: {
                    alert: false
                }
            }
        },

        methods:{
            showAlert(v){
                if(this.timers.alert) clearTimeout(this.timers.alert)
                this.alertText = `На данный момент интерфейс “${v}” находится в разработке , выберете другой тип.`
                this.alertShow = true;
                this.timers.alert = setTimeout(()=>{
                    this.alertShow = false
                }, 5000)
            }
        }
    }
</script>