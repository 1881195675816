<template>
    <div>
        <my-block
                :width="800"
                :height="400"
                :left="10"
                :top="70"
                title="Права пользователей"
                @close="$emit('close', true)">

            <template v-slot:default>
                <table class="table">
                    <thead>
                    <tr>
                        <td style="white-space: pre">Учетная запись (e-mail)</td>
                        <td style="white-space: pre">Верифицировать e-mail</td>
                        <td style="min-width: 200px">Проекты</td>
                        <td style="background: transparent">&nbsp;</td>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="user in users">

                        <template v-if="user.role === 'User'">
                            <td :class="{cross: forDelete.includes(user.id)}">{{user.email}}</td>

                            <td>
                                <template v-if="forDelete.includes(user.id)">
                                    <div class="error-msg">Будет удален</div>
                                </template>
                                <template v-else>
                                    <div v-if="user.isApproved" style="color: #1cb842">Подтвержден</div>
                                    <div v-else>
                                        <img v-if="verify.includes(user.id)"
                                             @click="removeFromVerify(user.id)"
                                             class="like-checkbox"
                                             src="/images/icons/form/checkbox-checked.svg">

                                        <img v-else
                                             @click="addToVerify(user.id)"
                                             class="like-checkbox"
                                             src="/images/icons/form/checkbox.svg">
                                    </div>
                                </template>
                            </td>

                            <td>
                                <div class="link-like" @click="showAccessEditor(user)">
                                    {{getUserProjectsCount(user)}}
                                    {{$plural(getUserProjectsCount(user), 'проект', 'проекта', 'проектов')}}
                                </div>
                            </td>

                            <td style="background: transparent">
                                <img v-if="forDelete.includes(user.id)"
                                     style="cursor: pointer"
                                     src="/images/icons/minus-r-g.svg"
                                     @click="removeFromDel(user.id)">
                                <img v-else
                                     style="cursor: pointer"
                                     src="/images/icons/minus-r-g.svg"
                                     @click="addToDel(user.id)">
                            </td>
                        </template>

                        <template v-if="user.role === 'Administrator'">
                            <td>{{user.email}}</td>
                            <td colspan="3" style="text-align: center">Администратор</td>
                        </template>

                    </tr>
                    </tbody>
                </table>
            </template>

            <template v-slot:bottom-buttons>
                <div class="btn-pane">
                    <button type="button" class="btn green" style="width: 180px"
                            @click="confirmVerify()">Сохранить</button>
                </div>
            </template>

        </my-block>

        <user-access v-if="showAccessView" :user="selectedUser" @close="loadAccounts"> </user-access>

    </div>

</template>

<style lang="scss">
@import "src/scss/vars";
    .like-checkbox{
        cursor: pointer;
    }

    .btn-pane{
        .btn{
            padding: 10px 30px;
        }
    }

    .cross{
        text-decoration: line-through;
    }
</style>

<script>
    import myBlock from '../../../components/responsiveBlock';
    import axios from 'axios';
    import {sortBy, each} from "lodash";
    import userAccess from './user-access'
    import {AccessRoles} from "../../../config";

    export default {
        components:{
            myBlock,
            userAccess,
        },

        data(){
            return {
                showAccessView: false,
                selectedUser: null,
                users: [
                    {
                        id: 1,
                        email: 'aa@ddd.fff',
                        isApproved: false,
                        claims: [
                            {
                                projectId: 123,
                                claim: AccessRoles.view,
                            }
                        ],
                    },
                ],
                verify:[],
                forDelete: [],
            }
        },

        computed:{

        },

        methods:{

            getUserProjectsCount(user){
                let c = user.claims.length;
                each(this.$store.state.projects.all, p => {
                    if(p.owner === user.email){
                        ++c;
                    }
                })
                return c;
            },

            async showAccessEditor(u){
                await this.$store.dispatch('projectsLoad');
                this.selectedUser = u;
                this.showAccessView = true;
            },

            addToDel(id){
                this.forDelete.push(id)
            },
            removeFromDel(id){
                let i = this.forDelete.indexOf(id);
                if(i !== -1){
                    this.forDelete.splice(i, 1);
                }
            },

            addToVerify(id){
                this.verify.push(id)
            },
            removeFromVerify(id){
                let i = this.verify.indexOf(id);
                if(i !== -1){
                    this.verify.splice(i, 1);
                }
            },

            confirmVerify(){
                let reqs = [];

                if(this.verify.length) reqs.push(axios.post('/api/Account/verify', this.verify).then((res)=>{
                    // console.log(res)
                }));

                if(this.forDelete.length){
                    this.forDelete.forEach(u => {
                        reqs.push(axios.delete('/api/Users/id:' + u).then((res)=>{
                            // console.log(res)
                        }));
                    })
                }

                /*each(this.users, (u)=>{
                    reqs.push(axios.post('/api/Account/role', {
                        id: u.id,
                        role: u.role,
                    }).then((res)=>{
                        console.log(res)
                    }));
                })*/

                Promise.all(reqs).then(()=>{
                    this.$notify({
                        group: 'all',
                        type: 'success',
                        title: 'Сохранено',
                        text: 'Параметры пользователей сохранены'
                    });
                    this.loadAccounts();
                    this.forDelete = [];
                    this.verify = [];
                })
            },

            loadAccounts(){
                axios.get('/api/Users').then((res)=>{
                    this.users = sortBy(res.data, 'email');
                    // this.$forceUpdate();
                });
                this.showAccessView = false;
            }
        },

        mounted() {
            this.loadAccounts();
        }
    }
</script>